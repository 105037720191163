<template>
  <div class="hot-sale-style">
    <div class="header">
      <item-tab title="全部系列" :itemTabData="tabList" v-model="activeIndex" />
    </div>
    <div class="series-container" >
      <div class="brand-lists" :style="`width: ${stepWidth}px`">
        <wzj-swiper v-model="brandListIndex" :stepWidth="stepWidth" :arrow="true" :num="swiperItemNum">
          <wzj-swiper-item :width="stepWidth" v-for="index in swiperItemNum" :key="index">
            <brand-item v-for="brand in seriesList.slice((index - 1) * group, index * group)" :key="brand.id" :item="brand"/>
          </wzj-swiper-item>
        </wzj-swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ItemTab from './ItemTab'
import BrandItem from './BrandItem'

const GROUP = 8 // 多少一组
const MIN_WIDTH = 990
const MAX_WIDTH = 1190

export default {
  components: {
    ItemTab,
    BrandItem,
  },
  props: {
    tabList: Array,
    defaultList: Array,
  },
  data() {
    return {
      seriesList: this.defaultList,
      group: GROUP,
      brandListIndex: 0,
      stepWidth: 1190,
      activeIndex: 0,
    }
  },
  computed: {
    ...mapState({
      screenWidth: state => state.common.screenWidth,
    }),
    swiperItemNum() {
      const { seriesList, group } = this
      return Math.ceil(seriesList.length / group)
    },
  },
  watch: {
    activeIndex() {
      this.brandListIndex = 0
      this.setSeriesList()
    },
    screenWidth(val) {
      if (val >= MIN_WIDTH && val <= MAX_WIDTH) {
        this.stepWidth = val
      } else if (val < MIN_WIDTH) {
        this.stepWidth = MIN_WIDTH
      } else if (val > MAX_WIDTH) {
        this.stepWidth = MAX_WIDTH
      }
    },
  },
  methods: {
    async setSeriesList() {
      const { activeIndex, tabList } = this
      const { id } = tabList[activeIndex]
      const res = await this.$http.get(`/proxy/series-catalog/${id}/series?limit=99&offset=0`)
      if (res) {
        this.seriesList = res.results
      }
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.hot-sale-style{
  position: relative;
  background: $_bg_f9;
  padding: 60px 0;
  .header{
    margin-bottom: 20px;
  }
}
.series-container {
  position: relative;
}
</style>
