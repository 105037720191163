<template>
  <div class="swiper-item" :style="`width: ${width}px`">
    <slot />
  </div>
</template>
<script>
  export default {
    name: 'WzjSwiperItem',
    props: {
      width: {
        type: Number,
        default: 1190,
      },
    },
  }
</script>
