<template>
  <div class="delete-btn" @click.stop.prevent="handleDeleteClick" :style="{ top: `${top}px`, right: `${right}px` }">
    <i class="wzj-pc-close-s"></i>
  </div>
</template>
<script>
export default {
  name: 'CollectDeleteBtn',
  props: {
    right: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleDeleteClick() {
      this.$emit('handleDeleteClick')
    },
  },
}
</script>
<style lang="scss" scoped>
.delete-btn{
  width: 30px;
  height: 30px;
  background: rgba($color: #000, $alpha: .5);
  position: absolute;
  // right: 0;
  // top: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
    background: $c_highlight;
  }
  i{
    font-size: 12px;
    color: #fff
  }
}
</style>


