<template>
  <div class="classify-list-wrapper">
    <span class="title">{{title}}</span>
    <div class="lists">
      <span class="list" v-for="item in lists" :key="item.id" @click.stop="handleToSearch(item)">
        {{item.name}}
        <div class="space-detail"  v-if="item.categories">
          <div class="bridge"></div>
          <div class="space-detail-lists">
            <span class="list" v-for="cate in item.categories" :key="cate.id" @click.stop="handleSpaceToSearch(cate, item.id)">{{cate.name}}</span>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ClassifyList',
    props: {
      title: String,
      lists: Array,
      type: String,
    },
    methods: {
      handleToSearch(item) {
        if (this.type === 'spaces') {
          return
        }
        const params = {
          keyword: item.name,
          referrer_page: 'webHome',
        }
        switch (this.type) {
          case 'categories':
            params.category_id = item.id
            params.type = 'category'
            params.secondary_category_id = item.secondary_category_id
            break
          case 'styles':
            params.main_style_id = item.id
            params.style_id = item.id
            params.type = 'style'
            break
          default:
            break
        }
        this.$router.push({
          path: '/search',
          query: params,
        })
      },
      handleSpaceToSearch(item, spaceId) {
        const params = {
          keyword: item.name,
          referrer_page: 'webHome',
          category_id: item.id,
          space_id: spaceId,
          type: 'space',
        }
        this.$router.push({
          path: '/search',
          query: params,
        })
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

  div {
    margin: 0;
    padding: 0;
    font-size: 0;
  }

  .classify-list-wrapper {
    position: relative;
    width: 100%;
    white-space: wrap;
    text-align: left;
    margin-bottom: 12px;
  }
  span {
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 300;
  }
  .title {
    display: inline-block;
    width: 42px;
    vertical-align: top;
  }
  .lists {
    display: inline-block;
    width: 85%;
  }
  .list {
    padding: 0 10px;
    border-right: 1px solid #fff;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &:hover {
      color: $c_highlight;

      .space-detail {
        display: inline-block;
      }
    }
  }
  .space-detail {
    display: none;
    padding-left: 64px;
    padding-bottom: 20px;
    position: absolute;
    left: -20px;
    right: 0;
    top: 32px;
    background: rgba(0, 0, 0, .5);
  }

  .space-detail-lists {
    width: 80%;
    background: rgba(0, 0, 0, .5);
    padding: 10px;
    border-radius: 4px;

    >.list {
      color: $c_dark;

      &:hover {
        color: $c_highlight;
      }
    } 
  }

  .bridge {
    position: absolute;
    top: -14px;
    left: 62px;
    right: 0;
    height: 18px;
    background: transparent;
  }


  .hover {
    color: $c_highlight;
  }

  .triangle {
    position: absolute;
    top: -6px;
    display: inline-block;
    height: 0;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid rgba(0, 0, 0, .5);
  }

</style>
