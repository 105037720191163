<template>
<div class="app-header-container">
  <div class="app-header">
    <div class="header-main">
      <nuxt-link class='logo' to="/">
        <img height="24" src="//img.wzj.com/upload/web/images/logo-desc.png" alt="">
        <div class="split-line"></div>
        <div class="desc">一站式高品质新零售家居品牌</div>
      </nuxt-link>
      <div class="user-info">
        <div class="login-info">
          <template v-if="!username">
            <div class="login" @click="handleLogin">你好，请登录</div>
          </template>
          <template v-else>
            <div class="username">
              {{username}}<i class="wzj-pc-header-arrow-down"></i>
              <div class="username-pop" @click="handleLogOut">
                <div class="log-out">退出登录</div>
              </div>
            </div>
          </template>
        </div>
         <div class="split-line"></div>
        <div class="cart">
          购物车<span class="car-num">{{cartNum || 0}}</span>
          <div class="cart-pop">
            <img :src="qrCodeBg | imageFormat(400)" alt="我在家"/>
            <vue-qr class="qrcode" :options="{ width: 140, margin: 0 }" :value="mUrl" v-if="showUrl"></vue-qr>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="collect">
          我的收藏<i class="wzj-pc-header-arrow-down"></i>
          <div class="collect-pop">
            <div
              class="collect-item"
              v-for="(item, index) in collectTypeList"
              :key="index"
              @click="handleToCollect(index)"
            >
              {{item}}
            </div>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="download-app">
          <i class="wzj-pc-app"></i>
          我在家APP
          <div class="download-pop">
            <vue-qr class="qrcode" :options="{ width: 140, margin: 0 }" :value="appUrl" v-if="showUrl"></vue-qr>
            <div class="text">
              下载App领新人礼包
            </div>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="business">
          <i class="wzj-pc-business"></i>
          招商加盟
          <div class="business-pop">
            <img width="140" v-if="showUrl" :src="checkInMp" alt="招商加盟">
            <div class="text">
              微信扫码了解招商信息
            </div>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="search">
          <search-bar></search-bar>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { mapState } from 'vuex'
import qrCodeBg from '@/assets/image/qrCodeBg.png'
import SearchBar from '@/components/Common/SearchBar'
import Cookie from 'js-cookie'
export default {
  name: 'AppHeader',
  components: {
    SearchBar,
  },
  data() {
    return {
      showDownload: false,
      appUrl: 'https://m.wzj.com/app',
      checkInMp: 'https://img.wzj.com/upload/web/images/check-in.jpeg',
      mUrl: 'https://m.wzj.com/home',
      showUrl: false,
      qrCodeBg,
      collectTypeList: ['商品', '系列', '生活家'],
    }
  },
  computed: {
    ...mapState({
      username: state => state.common.username,
      cartNum: state => state.common.cartNum,
      token: state => state.common.token,
    }),
  },
  mounted() {
    this.showUrl = true
  },
  methods: {
    handleLogin() {
      this.$store.commit(`common/LOGIN_DIALOG_VISIBLE`, true)
    },
    async handleLogOut() {
      const res = await this.$http.post('/proxy/logout')
      if (res) {
        Cookie.remove('token', { path: '/', domain: '' })
        this.$store.commit(`common/TOKEN`, '')
        this.$store.commit(`common/USERNAME`, '')
        this.$store.commit(`common/CART_NUM`, 0)
        if (/collect/.test(location.href)) {
          location.href = `${location.protocol}//${location.host}`
          return
        }
        location.reload()
      }
    },
    async handleToCollect(index) {
      const url = `/collect?tabIndex=${index}`
      await this.checkLogin()
      this.$router.push(url)
    },
  },
}

</script>
<style lang='scss' scoped>
.app-header-container {
  width: 100%;
  height: 64px;
}
 .app-header{
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
  z-index: 19;
  background: #1A1A1A;
  font-size: 12px;
  color: #fff;
  .header-main{
    height: 100%;
    display: flex;
    margin: 0 auto;
    min-width: 1030px;
    max-width: 1190px;
    justify-content: space-between;
    align-items: center;

    >.logo, .user-info {
      display: flex;
      align-items: center;
    }
  }
}
.logo .desc {
  font-size: 12px;
  color: #fff;
}
.login, .register, .download-app, .cart, .collect, .username, .business{
  display: flex;
  align-items: center;
  height: 64px;
  cursor: pointer;
}
.download-app, .cart, .collect, .username, .business, .search{
  position: relative;
  padding: 0 10px;
}
.download-pop, .collect-pop, .username-pop, .business-pop{
  display: none;
  position: absolute;
  left: 0;
  top: 48px;
  z-index: 20;
  background:#fff;
  box-shadow:0px 2px 6px 0px rgba(26,26,26,0.1);
}
.split-line{
  height: 12px;
  width: 1px;
  background: #fff;
  opacity: .2;
  margin: 0 10px;
}
.login-info{
  line-height: 1;
  display: flex;
  align-items: center;
  .login{
    color: $c_highlight;
  }
}
.cart{
  .car-num{
    color: $c_highlight;
    margin-left: 4px;
  }
}
.download-pop, .business-pop{
  width: 160px;
  // height: 184px;
  padding: 16px 10px 10px 10px;
  background: #fff;
  .text{
    color: $c_black;
    margin-top: 10px;
    font-weight: 500;
    text-align: center;
  }
}
.cart-pop{
  display: none;
  position: absolute;
  z-index: 20;
  width: 380px;
  height: 422px;
  top: 48px;
  right: 0;
  box-shadow:0px 2px 6px 0px rgba(26,26,26,0.1);
  img{
    width: 100%;
  }
  .qrcode{
    position: absolute;
    left: 54px;
    top: 243px;
    z-index: 1;
  }
}
.collect-pop{
  right: 0;
  text-align: left;
  .collect-item{
    width: 100%;
    height: 38px;
    line-height: 38px;
    color: $c_black;
    padding-left: 10px;
    font-size: 14px;
    &:hover{
      color: $c_highlight;
      background: $_bg_f6;
    }
  }
}
.download-app, .business{
  &:hover{
    color: $c_highlight;
    background: #fff;
    .download-pop, .business-pop{
      display: inline-block;
    }
  }
  i{
    font-size: 16px;
    margin-right: 4px;
  }
}
.cart{
  &:hover{
    color: $c_black;
    background: #fff;
    .cart-pop{
      display: inline-block;
    }
  }
}
.collect{
  i{
    font-size: 12px;
    transform: scale(.3)
  }
  &:hover{
    color: $c_black;
    background: #fff;
    .collect-pop{
      display: inline-block;
    }
    i{
      color: $c_highlight;
      transform: rotate(0.5turn) scale(.3) translateY(4px);
    }
  }
}
.search {
  width: 220px;
}
.username-pop{
  right: 0;
  font-size: 14px;
  height: 48px;
  text-align: left;
  margin-bottom: 10px;
  .log-out{
    padding-left: 10px;
    line-height: 38px;
      &:hover{
      color: $c_highlight;
      background: $_bg_f6;
    }
  } 
  
}
.username{
  i{
    font-size: 12px;
    transform: scale(.3)
  }
  &:hover{
    color: $c_black;
    background: #fff;
    .username-pop{
      display: inline-block;
    }
    i{
      color: $c_highlight;
      transform: rotate(0.5turn) scale(.3) translateY(4px);
    }
  }
}
</style>