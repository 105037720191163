<template>
  <div class='about-us'>
    <img class="lazyload" :data-src="'https://img.wzj.com/upload/wzj/web/about_us_header_1.jpg'" alt="关于我们">
    <section class='content'>
      <div class="desc mt-60">我在家，一站式高品质新零售家居品牌。通过专业运营团队甄选全球爆款，集结顶尖设计，运用互联网创新优化供应链和流通效率，为全球家庭提供一站式家居解决方案，让每一位我在家的用户，享受高品质的家居生活。家为“我”用才有意义：用家居构建“以人为中心”的家庭空间，努力照顾到每个家庭成员“我”的感受与需求，提供更高品质的一站式产品与服务，创造更加时尚、舒适、便捷的生活体验。  全风格全品类布局：整合北欧、意大利、东南亚、中国等全球一线制造商，打造极简、轻奢、中古、欧美等全风格全品类的产品布局，精工每一种材质工艺，雕琢每一个实用细节，给家以最好的品质感与安全感。成就小家引领大家：我在家始终坚持对家居产业链的深度探索与研究，用科技创新的方式提高家居行业各环节效率，打造真正可持续的家居行业生态系统，实现消费者、合作伙伴和全社会的共赢。</div>
      <div class='mt-60'>
        <h4>品牌文化</h4>
        <div class='mt-20'>定位：</div>
        <p>一站式高品质新零售家居品牌</p>
        <div class='mt-40'>使命：</div>
        <p>让家的生活更美好</p>
        <div class='mt-40'>愿景：</div>
        <p>成为全球领先的综合家居品牌</p>
        <div class='mt-40'>价值观：</div>
        <p>1.与合作伙伴相互成就</p>
        <p>2.以开放创造连接，用数据创造价值</p>
        <p>3.坚持做长远来看正确的事</p>
        <p>4.我们只和诚实善良的成年人合作</p>
        <p>5.每个人都应该知道做事情的目的和原因</p>
        <p>6.已有的解决方案不一定是最好的，追求最有性价比</p>
        <p>7.工作上有话直说，能当面不电话，随时随地解决问题</p>
        <p>8.获取可积累的进步</p>
        <p>9.不进化就死</p>
        <p>10.为热爱努力</p>
      </div>
      <div class='mt-60'>
        <h4>爆款系列</h4>
        <img class="lazyload mt-20" :data-src="'https://img.wzj.com/upload/wzj/web/about_us_1.jpg'" alt="关于我们">
      </div>
      <div class='mt-40'>
        <h4>意式极简 — GUSTO LIFE</h4>
        <div class='mt-10'>低调、雅致、经典的GUSTO系列将极简主义完美应用于家居，黑白灰棕等中性色搭配考究的金属或石材，给人以浑然天成的高级感，为时尚又果敢的都市精英们创造惬意的家居空间。</div>
      </div>
      <div class='mt-40'>
        <h4>意式极简 — RICCO</h4>
        <div class='mt-10'>时尚、质感又具备高性价比的RICCO系列以几何线条为重要元素贯穿整个空间，没有繁杂的结构，没有纷乱的色彩，干净利落、自然纯粹，正好符合年轻一代对简单、自由的追寻。</div>
      </div>
      <div class='mt-40'>
        <h4>现代简约 — COMO CASA</h4>
        <div class='mt-10'>COMO系列很擅长在空间中做减法，在细节中做加法，全皮软体的时尚质感满足对生活的高品质需求，人性化的功能设计加上艺术加工赋予的感性魅力，完美实现了在精简之后功能与美感的平衡，满足舒适功能与美学心理的双向需求。</div>
      </div>
      <div class='mt-40'>
        <h4>轻奢 — 拉斐尔</h4>
        <div class='mt-10'>质感的金、复古的铜，辅以皮质、丝绒、大理石等点缀，华丽而不张扬，典雅而不浮夸。拉斐尔系列摒弃传统贵族的浮华与繁琐，每一处设计都源自生活的艺术重生，不经意间的高雅与情调，和谐舒适又不乏品质格调，彰显流光溢彩的精致感。</div>
      </div>
      <div class='mt-40'>
        <h4>北欧中古 — 奥格斯堡</h4>
        <div class='mt-10'>20世纪中期的北欧，设计大师辈出，奥格斯堡系列将中古与北欧理性的浪漫结合，艺术感兼具舒适度和支撑力，强烈的时代通感让居室克制而优雅，适合对小众艺术有独特见解的品位玩家。</div>
      </div>
      <div class='mt-40'>
        <h4>新中式 — 写意东方</h4>
        <div class='mt-10'>传承中国传统美学，与现代审美、制造工艺巧妙结合，东方的含蓄内敛遇上现代的利落简约，凝结成一股至静、至雅、至纯的空间力量。考究的材质与配色，雕琢的工艺与线条，尽显内敛的大将之风。</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.about-us {
  padding-bottom: 30px;
  font-size: 0;
  color: #222121;
  font-family: PingFangSC-Regular, PingFang SC;
  div, p, li {
    font-size: 12px;
    line-height: 18px;
  }
  img {
    width: 100%;
  }
  h4 {
    line-height: 18px;
    font-size: 15px;
    font-weight: bold;
  }
  section {
    width: 595px;
    margin: 0 auto;
  }
  .desc {
    text-indent: 24px;
  }
  .mt-10 {
    margin-top: 5px;
  }
  .mt-20 {
    margin-top: 10px;
  }
  .mt-40 {
    margin-top: 20px;
  }
  .mt-60 {
    margin-top: 30px;
  }
  
}

</style>