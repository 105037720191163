<template>
  <div id="playerBox" class="player_box" @click.stop="updateControl">
    <div :class="{'player_container': true}" >
      <div :class="`video_state ${icon} ${!controls && !firstPlay ? 'fade_out' : ''}`" @click.stop="updatePlayState"></div>
      <video :id="`videoPlayer${id}`"
             loop="loop"
             preload="auto"
             :class="{hide: firstPlay}"
             webkit-playsinline="true"
             playsinline="true">
      </video>
      <div :class="`${!controls ? 'fade_out ' : ''}player_controls`" ref="content" @click="$event.stopPropagation()">
        <span class="time_start">{{currentTime}}</span>
        <div class="progress_box" ref="progress">
          <div class="progress_total"></div>
          <div class="progress_icon" :style="{left: `${progress}%`}" ref="thumb"><span></span></div>
          <div class="progress_done" :style="{width: `${progress}%`}"></div>
        </div>
        <span class="time_total">{{duration}}</span>
        <div class="voice_box" @click.stop="playerMuted">
          <img :src="`//img.wzj.com/h5-static/img/h5/${muted ? 'muted' : 'ring'}.png` | imageFormat(100)" alt="我在家">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HLS from 'hls.js'
import { domSeek } from '~/assets/lib/tool'
import draggable from '~/assets/lib/draggable'

let controlsTimer = null
let video = null
export default {
  name: 'PlayInline',
  data() {
    return {
      currentTime: '00.00', // 当前时间
      duration: this.timeFormat(this.durationTotal), // 总时长
      progress: 0, // 播放进度
      videoInfo: {}, // 视频元信息
      icon: 'video_play', // icon状态
      controls: true, // 控制栏是否展示
      muted: this.mutedState, // 是否静音
      errorCount: 0, // 统计视频播放错误次数，为0时提供一次默认刷新视频的操作，若仍操作失败，则停留当前页面给出提示
      agreeAutoPlay: false, // 用户是否同意自动播放
      portraint: true, // 默认竖屏
      firstPlay: true, // 是否是初始播放
    }
  },
  props: {
    id: {
      type: Number,
    },
    durationTotal: { // 总时长
      type: String,
      default: '00.00',
    },
    mutedState: {
      type: Boolean,
    },
    autoPlay: {
      type: Boolean,
    },
    playState: {
      type: Boolean,
    },
    poster: {
      type: String,
    },
    size: {
      type: Number,
    },
  },
  mounted() {
    this.drag()
    this.init()
  },
  beforeDestroy() {
    this.playerLifeCycleEnd()
  },
  watch: {
    playState: {
      handler(value) {
        this.setPlayState(value)
      },
    },
  },
  methods: {
    timeFormat(s) {
      let t = ''
      if (s > -1) {
        const min = Math.floor(s / 60) % 60
        const sec = s % 60

        if (parseInt(min, 10) < 10) {
          t = `${t}0`
        }
        t = `${t}${min}:`
        if (parseInt(sec, 10) < 10) {
          t = `${t}0`
        }
        t = `${t}${parseInt(sec, 10)}`
      }
      return t
    },
    init() {
      if (this.id) {
        domSeek(`#videoPlayer${this.id}`).then((dom) => {
          video = dom
          this.playerLifeCycleStart()
          this.refreshToken()
        })
      }
    },
    drag() {
      const thumb = this.$refs.thumb
      const content = this.$refs.content
      const progress = this.$refs.progress

      draggable(thumb, {
        start: () => {
          this.setPlayState(false)
          this.autoControls()
        },
        drag: (event) => {
          this.controls = true
          const duration = video.duration || this.durationTotal
          const contentBox = content.getBoundingClientRect()
          const progressBox = progress.getBoundingClientRect()
          const deltaX = event.pageX - contentBox.left - (progressBox.left - contentBox.left)
          let newProgress = deltaX / progressBox.width
          if (newProgress < 0) {
            newProgress = 0
          } else if (newProgress > 1) {
            newProgress = 1
          }

          this.progress = (newProgress * 100).toFixed(2)
          video.currentTime = duration * newProgress
        },
        end: () => {
          this.setPlayState(true)
        },
      })
    },
    async refreshToken() {
      const res = await this.$http.get('/proxy/video/refresh-token', {
        params: {
          video_id: this.id,
          network_type: 'wifi',
        },
      })
      const results = res.results && res.results.length ? res.results[0] : {}
      this.videoInfo = results
      this.playerInit(results.url)
    },
    // 播放器初始化
    playerInit(source) {
      if (!source) {
        this.$toast('缺少视频播放链接地址')
        return
      }
      this.playerResourceInit(source)
    },
    playerResourceInit(source) {
      if (HLS.isSupported() && this.videoInfo.format === 'm3u8') {
        const hls = new HLS()
        hls.loadSource(source)
        hls.attachMedia(video)
      } else {
        video.src = source
        video.setAttribute('src', source)
      }
      if (this.autoPlay) {
        video.setAttribute('autoplay', 'autoplay')
        this.setPlayState(true)
      }
    },
    loadstart() {
      this.icon = this.autoPlay ? 'video_play' : 'video_loading'
    },
    canplay() {
      if (this.autoPlay) {
        this.setPlayState(true)
      } else {
        this.icon = 'video_play'
      }
    },
    play() {
      this.icon = 'video_pause'
    },
    volumechange() {
      this.muted = video.muted
    },
    pause() {
      this.icon = 'video_play'
    },
    error() {
      this.icon = ''
      if (!this.errorCount) {
        this.errorCount = this.errorCount + 1
        this.refreshToken()
      } else {
        this.$toast('视频播放失败，请检查网络情况并重试')
      }
    },
    timeupdate() {
      this.icon = 'video_pause'
      if (video.duration && video.currentTime) {
        this.currentTime = this.timeFormat(video.currentTime)
        this.duration = this.timeFormat(video.duration)
        this.progress = ((video.currentTime / video.duration) * 100).toFixed(2)
        if (this.firstPlay) {
          this.clearPoster()
        }
      }
    },
    // 开启video监听
    playerLifeCycleStart() {
      video.addEventListener('loadstart', this.loadstart)
      video.addEventListener('canplay', this.canplay)
      video.addEventListener('pause', this.pause)
      video.addEventListener('play', this.play)
      video.addEventListener('timeupdate', this.timeupdate)
      video.addEventListener('volumechange', this.volumechange)
      video.addEventListener('error', this.error)
    },
    // 关闭video监听
    playerLifeCycleEnd() {
      this.setPlayState(false)
      this.videoInfo = {}
      this.muted = true
      this.icon = 'video_loading'
      this.errorCount = 0
      video.src = ''
      video.removeAttribute('src')
      video.removeAttribute('autoplay')
      video.removeEventListener('loadstart', this.loadstart)
      video.removeEventListener('canplay', this.canplay)
      video.removeEventListener('pause', this.pause)
      video.removeEventListener('play', this.play)
      video.removeEventListener('timeupdate', this.timeupdate)
      video.removeEventListener('volumechange', this.volumechange)
      video.removeEventListener('error', this.error)
    },
    // 控制栏自动隐藏
    autoControls() {
      if (controlsTimer) {
        clearTimeout(controlsTimer)
        controlsTimer = null
      } else {
        controlsTimer = setTimeout(() => {
          this.controls = false
          clearTimeout(controlsTimer)
          controlsTimer = null
        }, 3000)
      }
    },
    // 点击其它区域控制控件线上隐藏
    updateControl() {
      this.autoControls()
      this.controls = !this.controls
    },
    // 设置播放或暂停
    setPlayState(flag) {
      if (flag && video) {
        if (!video.getAttribute('src')) {
          this.playerInit(this.videoInfo.url)
        } else {
          this.playHandle()
        }
      }
      if (video && !flag) {
        this.autoControls()
        video.pause()
      }
    },
    // 去除poster背景图片
    clearPoster() {
      this.firstPlay = false
      domSeek('#playerBox').then((box) => {
        const boxChild = box.children[0]
        boxChild.style.backgroundImage = ''
      })
    },
    updatePlayState() {
      if (this.icon === 'video_pause' && this.controls) {
        this.autoControls()
        this.$emit('playHandler', false)
        video.pause()
        return
      }
      if (this.icon === 'video_play' && (this.controls || this.firstPlay)) {
        this.$emit('playHandler', true)
        this.playHandle()
      }
    },
    // playHandle
    playHandle() {
      this.autoControls()
      video.muted = this.muted
      video.play()
    },
    // 音频关闭开启
    playerMuted() {
      this.muted = !video.muted
      video.muted = !video.muted
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .hide {
    display: none !important;
  }

  .player_box {
    width: 100%;
    height: 100%;
  }

  .player_container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    // background-position: center center;
    // background-repeat: no-repeat;
    // background-size: contain;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      font-size: 12px;
      margin: 0 auto;
      object-fit: contain;
      display: block;
    }

    .video_state{
      display: none;
      position: absolute;
      content: ' ';
      width: 75px;
      height: 75px;
      top: 50%;;
      left: 50%;
      margin-top: -37.5px;
      margin-left: -37.5px;
      z-index: 9;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transform: translate3d(0,0,0);
    }

    .video_play{
      display: block;
      background-image: url(//img.wzj.com/h5-static/img/h5/play.png?x-oss-process=style/w800);
    }

    .video_pause{
      display: block;
      background-image: url(//img.wzj.com/h5-static/img/h5/pause.png?x-oss-process=style/w800);
    }

    .video_loading{
      display: block;
      background-image: url(//img.wzj.com/h5-static/img/h5/loading.png?x-oss-process=style/w800);
      animation: rotate 2.0s infinite linear;
    }
  }
  .flow_container{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
  }

  .flow_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > p{
      color: rgba(255,255,255,0.9);
      font-size: 14px;
    }
    .active{
      color: $c_highlight;
    }
    & > div{
      margin-top: 20px;
      width: 130px;
      @include line-height(40px);
      border: 1px solid rgba(255,255,255,0.5);
      box-sizing: border-box;
      text-align: center;
      color: #fff;
      font-size: 14px;
      border-radius: 22px;
      background-color: rgba(0,0,0,0.5);
      & > .icon{
        margin-right: 5px;
        font-size: 14px;
      }
    }
    & > img{
      width: 100%;
    }
  }

  .player_controls {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #000;
    padding-left: 20px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > span{
      font-size: 12px;
      color: #FFFFFF;
    }
  }

  .back_screen{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.5) 7%, rgba(0,0,0,0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    & > i{
      @include line-height(44px);
      padding: 0 15px;
      color: #fff;
      font-size: 18px;
    }
  }

  .progress_box {
    min-width: 100px;
    flex-grow: 2;
    padding: 19px 0;
    margin: 0 10px;
    position: relative;

    & > .progress_icon{
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -10px;
      margin-left: -5px;
      z-index: 3;
      & > span{
        display: block;
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        margin: 5px auto 0;
      }
    }

    & > .progress_done{
      width: 0;
      height: 2px;
      background-color: #FF5541;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -1px;
      z-index: 2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    & > .progress_total{
      background: rgba(255,255,255,0.30);
      background-color: #fff;
      height: 2px;
      border-radius: 4px;
    }
  }

  .voice_box{
    margin: -4px 20px 0;
    & > img{
      width: 18px;
    }
  }

  .full_screen{
    padding: 12px 8px;
    & > img{
      width: 16px;
    }
  }

  .fade_out{
    animation: fadeOut .3s 1 linear forwards;
  }

  // 全屏
  .fixed_video{
    background-color: #fff;
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  // 横屏
  .landScape_video{
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    animation: landScape .3s 1 linear forwards;
    & > video{
      width: auto;
      height: 100%;
    }
  }


  .rotate_video {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    animation: upsideDown .3s 1 linear forwards;
  }

  video::-webkit-media-controls-enclosure, video::media-controls-enclosure {
    display: none;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes landScape {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  @keyframes portraint {
    0% {
      transform: rotate(90deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  @keyframes upsideDown {
    0% {
      transform: translate(-50%, -50%) rotate(90deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
</style>
