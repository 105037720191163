<template>
  <div class="quick-filter">
    <div class="filter-header">
      <item-tab title="快速筛选" :itemTabData="tabList" v-model="activeFilterIndex" />
    </div>
    <div ref="filter">
      <div class="filter-main" v-show="activeFilterIndex === 0">
        <nuxt-link :to="`/categories/${normalizeObjectEnNameForUrl(item.en_name)}`" class="category-item" v-for="item in categoryList" :key="`${item.id}_${activeFilterIndex}`">
          <div class="img-ctn">
            <img class="lazyload" :data-src="item.image_url | imageFormat" :alt="item.name">
          </div>
          <div class="text">{{ item.name }}</div>
        </nuxt-link>
      </div>
      <div class="filter-main" v-show="activeFilterIndex === 1">
        <nuxt-link :to="`/styles/${item.id}`" class="style-item" v-for="(item, index) in styleList" :key="index">
          <div class="img-ctn">
            <img class="lazyload" :data-src="item.image_url | imageFormat" :alt="`${item.name}风格家具`">
          </div>
        </nuxt-link>
      </div>
      <div class="filter-main" v-show="activeFilterIndex > 1">
        <div class="space-item" v-for="item in spaceList" :key="`${item.id}_${activeFilterIndex}`">
          <div class="img-ctn">
            <img class="lazyload" :data-src="item.image_url | imageFormat" :alt="`${item.name}家具`">
          </div>
          <div class="hover-ctn">
            <div class="name-ctn">
              <div class="cn-name">{{ item.name }}</div>
              <div class="en-name">{{ item.en_name }}</div>
            </div>
            <div class="hover-item-ct">
              <nuxt-link
                :to="`/spaces/${normalizeObjectEnNameForUrl(item.en_name)}/${normalizeObjectEnNameForUrl(category.en_name)}`"
                class="hover-item"
                v-for="(category, i) in item.categories"
                :key="`${category.id}_${i}`"
              >
                {{ category.name }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTab from './ItemTab'
import { normalizeObjectEnNameForUrl } from '~/assets/lib/tool'

export default {
  components: {
    ItemTab,
  },
  props: {
    categoryList: {
      type: Array,
    },
    styleList: {
      type: Array,
    },
    spaceList: {
      type: Array,
    },
  },
  data() {
    return {
      tabList: [
        {
          text: '品类',
        },
        {
          text: '风格',
        },
        {
          text: '空间',
        },
      ],
      activeFilterIndex: 0,
      urlPrefix: '/search?referrer_page=webHome&',
      urlSuffix: '&search_page_type=classification',
    }
  },
  beforeUpdate() {
    [...this.$refs.filter.getElementsByClassName('lazyloaded')].forEach((image) => {
      image.url = ''
      image.className = image.className.replace('lazyloaded', 'lazyload')
    })
  },
  methods: {
    normalizeObjectEnNameForUrl,
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.quick-filter{
  margin-top: 60px;
  margin-bottom: 35px;
  background: #fff;
}
.filter-header{
  margin-bottom: 20px;
  .title{
    font-size: 28px;
  }
}

.filter-main{
  display: flex;
  flex-wrap: wrap;
}
.style-item, .category-item{
  .img-ctn{
    &:hover{
      opacity: .7;
    }
  }
}
.category-item{
  width: calc(10% - 18px);
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  &:nth-child(10n) {
    margin-right: 0;
  }
  &:hover {
    .text{
      color: $c_highlight;
    }
  }
  .img-ctn{
    padding-top: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background: #f6f6f6;
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
  .text{
    text-align: center;
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.style-item, .space-item{
  width: calc(24% - 8px);
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.img-ctn{
  padding-top: 29%;
  position: relative;
  overflow: hidden;
  img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1920px) {
  .style-item, .space-item{
    width: calc(20% - 8px);
    &:nth-child(4n) {
      margin-right: 10px;
    }
    &:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.space-item{
  background: #f6f6f6;
  position: relative;

  &:hover{
    img{
      visibility: hidden;
    }
  }
  .name-ctn{
    padding: 25px 20px 20px 20px;
    .cn-name{
      color: $_text_dark;
      font-size: 19px;
      margin-bottom: 4px;
      font-weight:600;
    }
    .en-name{
      font-size: 14px;
      color: #92969C;
    }
  }

  .hover-ctn{
    position: absolute;
    top: 0;
    left: -1px;
    right: -2px;
    z-index: 99;
    display: none;
    flex-direction: column;
    background: #f6f6f6;
    padding: 0 10px 5px;
    border-top: 0;
  }

  .hover-item-ct {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .hover-item{
    width: 50%;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    cursor: pointer;
    &:hover{
      color: $c_highlight;
    }
  }

  &:hover{
    .hover-ctn{
      display: flex;
    }
  }
}
</style>
