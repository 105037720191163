<template>
  <div class="image-search-wrapper" v-if="imgInputer">
    <web-header v-model="searchImg"></web-header>
    <div class="img-wrapper" v-if="imgInputer.imgBase64">
      <img v-if="tempCanvasImg" :src="tempCanvasImg | imageFormat" class="canvas_img" :width="imgWidth" :height="imgHeight" alt="">
      <canvas id="myCanvas" class="canvas" :width="canvasWidth" :height="canvasHeight">
        您的浏览器版本过低，请升级...。
      </canvas>
    </div>
    <div class="container">
      <div class="canvas-box">
        <div class="title">共{{imgInputer.coords && imgInputer.coords.length || 0}}件商品</div>
        <p class="sub">点击图片查看相似商品</p>
        <div class="canvas-list"  v-for="(img, index) in canvasImgs" :key="index">
          <div class="canvas-img" v-if="imgInputer.coords" @click="handleToSearch(img, index)">
            <div :style="{backgroundImage:`url(${img.url})`}"></div>
            <span>{{img.name}}</span>
          </div>
        </div>
      </div>
      <div v-if="imgInputer.coords && imgInputer.coords.length === 0" class="no-img-coords" @click="handleImgInputer">
        <i class="icon wzj-pc-upload"></i>
        <span>重新上传</span>
      </div>
    </div>
  </div>
</template>

<script>
  import { setLocalStorage, getLocalStorage } from '@/assets/lib/tool'
  import imgLoader from '~/assets/lib/imageFormat'

  export default {
    data() {
      return {
        imgInputer: {},
        canvasImgs: [],
        searchImg: false,
        canvasHeight: 0,
        canvasWidth: 0,
        imgHeight: 0,
        imgWidth: 0,
        maxWidth: 1200,
        maxHeight: 600,
        random: Math.random(),
        tempCanvasImg: '',
      }
    },
    computed: {
      artworkImage() {
        const url = imgLoader(this.imgInputer.imgBase64.url)
        return url + (/\?x-oss-process/i.test(url) ? '&' : '?') + `v=${this.random}`
      },
    },
    mounted() {
      this.maxWidth = document.body.clientWidth
      this.imgInputer = JSON.parse(getLocalStorage('wzjImgInputer'))
      this.handleComputedCanvas()
      this.$nextTick(() => {
        this.handleCanvas()
      })
    },
    methods: {
      handleCanvas() {
        const width = this.canvasWidth
        const height = this.canvasHeight
        const canvas = document.getElementById('myCanvas')
        const artwork = new Image()
        artwork.crossOrigin = ''
        artwork.src = this.artworkImage
        if (canvas === null) {
          setTimeout(() => {
            this.handleCanvas()
          }, 500)
          return
        }
        const ctx = canvas.getContext('2d')
        const canvasImgs = []

        artwork.onload = () => {
          ctx.drawImage(artwork, 0, 0, width, height)
          this.imgInputer.coords.forEach((coord, index) => {
            const w = coord.w * width
            const h = coord.h * height
            const x = coord.x * width
            const y = coord.y * height
            // 绘制矩形
            ctx.beginPath()
            ctx.rect(x, y, w, h)
            ctx.strokeStyle = '#ff5411'
            ctx.stroke()

            // 切图
            const img = this.cropImage(ctx, x, y, w, h, coord.keyword, coord.ids)
            canvasImgs.push(img)
          })
          this.canvasImgs = canvasImgs
          const tempCanvas = this.cropImage(ctx, 0, 0, width, height)
          this.tempCanvasImg = tempCanvas.url
        }
      },
      cropImage(targetCanvas, x, y, width, height, name, ids) {
        const targetctxImageData = targetCanvas.getImageData(x, y, width, height) // sx, sy, sWidth, sHeight

        const c = document.createElement('canvas')
        const ctx = c.getContext('2d')

        c.width = width
        c.height = height

        ctx.rect(0, 0, width, height)
        ctx.fillStyle = 'white'
        ctx.fill()
        ctx.putImageData(targetctxImageData, 0, 0) // imageData, dx, dy
        return { url: c.toDataURL('image/jpeg'), name, ids }
      },
      handleToSearch(img, index) {
        setLocalStorage('wzjImageSearchData', this.canvasImgs)
        this.$router.push({
          path: '/search',
          query: {
            keyword: img.name,
            referrer_page: 'imageSearch',
            indexImg: index,
            ids: img.ids,
          },
        })
      },
      handleImgInputer() {
        this.searchImg = true
      },
      handleComputedCanvas() {
        const width = this.imgInputer.imgBase64.width
        const height = this.imgInputer.imgBase64.height
        this.canvasWidth = width
        this.canvasHeight = height

        if (width > this.maxWidth) {
          this.imgHeight = (this.maxWidth * height) / width
          this.imgWidth = this.maxWidth

          if (this.imgHeight > this.maxHeight) {
            this.imgHeight = this.maxHeight
            this.imgWidth = (this.imgHeight * width) / height
          }
        } else if (height > this.maxHeight) {
          this.imgHeight = this.maxHeight
          this.imgWidth = (this.maxHeight * width) / height
        } else {
          this.imgHeight = height
          this.imgWidth = width
        }
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .img-wrapper {
    position: relative;
    height: 600px;
    width: 100%;
    overflow: hidden;
    background: $c_black;
    text-align: center;
  }
  .canvas, .canvas_img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .canvas_img {
    z-index: 9;
  }
  .canvas {
    z-index: 1;
    visibility: hidden;
  }
  .canvas-box {
    position: relative;
    margin-top: 20px;

    .title {
      font-size: 20px;
      color: $c_black;
    }
    .sub {
      margin: 6px 0 20px 0;
      font-size: 14px;
      color: $c_dark;
    }
  }
  .canvas-list {
    display: inline-block;
  }
  .full-width {
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);

  }
  .full-height {
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
  .canvas-img {
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    background: #000;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;

    >span {
      position: absolute;
      left: 0;
      top: 0;
      height: 20px;
      line-height: 20px;
      padding: 0 4px;
      border-bottom-right-radius: 10px;
      background: $c_highlight;
      color: #fff;
      font-size: 12px;
    }
    > div {
      height: 100%;
      width: 100%;
      background-color: #000;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .no-img-coords {
    height: 40px;
    width: 118px;
    line-height: 40px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    font-size: 14px;
    background: $c_highlight;
    border-radius: 4px;
    cursor: pointer;

    span {
      margin-left: 6px;
    }
  }
</style>
