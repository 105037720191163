<template>
  <input class="input" :placeholder="placeholder" :value="currentCount" :min="min" :max="max" type="number" @blur="handleChangeNum" ref="input" :disabled='disabled'/>
</template>

<script type="text/babel">
/**
 * input-number
 * @description 数字输入框
 *
 * @param {number} v-model="value" - 数字值
 *
 * @example
 * <input-number :min=1 :max=5 v-model="number" />
 */
export default {
  props: {
    value: [String, Number],
    max: Number,
    min: {
      type: Number,
      default: 1,
    },
    canEmptyString: {
      type: Boolean,
      default: false,
    },
    onChange: Function,
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
  },
  data() {
    return {
      currentCount: this.value,
    }
  },
  watch: {
    value(val) {
      this.currentCount = val
    },
  },
  methods: {
    handleChangeNum() {
      const { min, max, canEmptyString } = this
      const { input } = this.$refs
      if (this.disabled || !input) return
      let value = parseInt(input.value)
      if (typeof this.onChange === 'function') this.onChange(value)
      if (canEmptyString && Number.isNaN(value)) {
        value = ''
      } else {
        if (value <= min) {
          value = min
        } else if (value > max) {
          value = max
        }
      }
      this.$refs.input.value = value
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.input {
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 13px;
  color: $_text_dark;
  border: 0;
}
</style>
