<template>
  <div class="selected-city-wrapper" v-if="show">
    <div class="selected-city-tab">
      <span :class="{'active-tab': currentTab === index}" v-for="(city, index) in currentList" @click="handleChangeTab(index)"  :key="index">{{city}}</span>
    </div>
    <div class="selected-city-list">
      <div :class="{'active-list': currentListIndex[currentTab] === index}" v-for="(list, index) in currentListsData"  @click="handleChangeCity(list.name, index, list.id)" :key="index">
        <span>{{list.name}}</span>
      </div>
    </div>
    <div class="selected-city-button">
      <span @click="handleRest" class="reset">重置</span>
      <span @click="handleSubmit" class="submit">确定</span>
    </div>
  </div>
</template>

<script>
  /**
   * selected-city
   * @description 单选下拉列表
   *
   * @param {Array} slotKeys - 关键字段
   * @param {Array} defaultValue - tab列表
   * @param {Array} addressValue - 选中的值
   * @param {String} path - 请求接口
   * @param {Boolean} value - 是否显示
   *
   * @example
   *
   * <selected-city
   *  v-model="isSelectedCity"
   *  :slotKeys="['cities', 'districts', 'estates']"
   *  :defaultValue="['不限城市', '不限地区', '不限楼盘']"
   *  :addressValue="['不限城市', '不限地区', '不限楼盘']"
   *  path="/proxy/estates-14"
   *  :value="false"
   *  @handleChangeFiltrateArea="handleChangeFiltrateArea"/>
   *
   *  备注： 暂不支持通过外传addressValue 选中列表中的值
   */
  import { arrayCopy } from '@/assets/lib/tool'

  export default {
    name: 'SelectedCity',
    props: {
      slotKeys: {
        type: Array,
        default() {
          return ['cities', 'districts', 'estates']
        },
      },
      defaultValue: {
        type: Array,
        default() {
          return ['不限城市', '不限地区', '不限楼盘']
        },
      },
      addressValue: {
        type: Array,
        default() {
          return ['不限城市', '不限地区', '不限楼盘']
        },
      },
      path: {
        type: String,
        default: '/proxy/estates-14',
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      value(val) {
        this.show = val
      },
    },
    data() {
      return {
        currentTab: 0,
        currentListIndex: [undefined, undefined, undefined],
        currentListsData: [],
        currentList: this.addressValue,
        filterData: [],
        allData: [],
        show: this.value,
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.$http.get(this.path).then((res) => {
          this.allData = res.results
          this.currentListsData = this.allData
        })
      },
      handleChangeCity(name, index, id) { // 选择地址
        // 手动切换tab时 判断tab是否改变，若改变则重置此tab后的所有tab
        if (this.currentListIndex[this.currentTab] !== undefined && this.currentList[this.currentTab] !== name) {
          this.currentListIndex.map((item, i) => {
            if (i > this.currentTab) {
              this.currentListIndex[i] = undefined
              this.currentList[i] = this.defaultValue[i]
            }
          })
        }
        this.$set(this.currentList, this.currentTab, name)
        this.$set(this.currentListIndex, this.currentTab, index)
        // 处理回传值filterData 的属性值
        const selected = { name: name, id: id }
        this.$set(this.filterData, this.currentTab, selected)
        if (this.currentTab < 2) {
          this.currentListsData = this.currentListsData[index][this.slotKeys[this.currentTab + 1]]
        }
        // 自动切换tab 到最后一个时不再切换
        this.currentTab = this.currentTab === 2 ? 2 : this.currentTab + 1
      },
      handleChangeTab(index) { // 手动切换tab
        if (this.currentListIndex[index] === undefined) {
          return
        }
        this.currentTab = index
        switch (this.currentTab) {
          case 0:
            this.currentListsData = this.allData
            break
          case 1:
            this.currentListsData = this.allData[this.currentListIndex[0]][this.slotKeys[index]]
            break
          case 2:
            this.currentListsData = this.allData[this.currentListIndex[0]][this.slotKeys[1]][this.currentListIndex[1]][this.slotKeys[index]]
            break
          default:
            break
        }
      },
      handleRest() {
        this.currentList = arrayCopy(this.defaultValue)
        this.currentListsData = this.allData
        this.currentTab = 0
        this.currentListIndex = [undefined, undefined, undefined]
        this.filterData = []
      },
      handleSubmit() {
        this.$emit('input', false)
        this.$emit('handleChangeFiltrateArea', { filterData: this.filterData, currentListIndex: this.currentListIndex })
      },
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .selected-city-wrapper {
    position: relative;
    width: 100%;
    min-height: 300px;
    max-height: 414px;
    background: #fff;
    overflow-y: hidden;
  }
  .selected-city-tab {
    background: #F6F6F6;
    text-align: center;
    height: 50px;
    line-height: 50px;

    >span {
      display: inline-block;
      margin: 0 60px;
      height: 100%;
      box-sizing: border-box;
      font-size: $f_16;
      color: $c_black;
      border-bottom: 2px solid transparent;
    }
    .active-tab {
      border-bottom-color: $c_highlight;
      color: $c_highlight;
    }
  }
  .selected-city-list {
    min-height: 250px;
    max-height: 300px;
    background: #fff;
    font-size: $f_16;
    color: $c_black;
    overflow-y: scroll;

    >div {
      height: 50px;
      line-height: 50px;
      text-align: center;

      &:hover,&.active-list{
        background: #FAFAFA;
        color: $c_highlight;
      }
    }
  }
  .selected-city-button {
    text-align: center;

    >span {
      display: inline-block;
      height: 40px;
      width: 120px;
      margin: 0 10px;
      line-height: 40px;
      border-radius: 40px;
      font-size: $f_14;
    }
    .reset {
      color: $c_dark;
      border: 1px solid $c_gray;
    }
    .submit {
      color: #fff;
      background: $c_highlight;
    }
  }
  span {
    cursor: pointer;
  }
</style>
