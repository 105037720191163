<template>
  <div class="search-bar clearfix">
    <i class="icon wzj-pc-search" @click="handleSearch"></i>
    <input class="input"
     type="text" 
     :placeholder="placeholder" 
     v-model="keyword"
     @keyup="handleKeyDown">
  </div>
</template>

<script>
  import { setLocalStorage, getLocalStorage } from '@/assets/lib/tool'
  import { mapState } from 'vuex'

  export default {
    name: 'SearchBar',
    data() {
      return {
        keyword: undefined,
      }
    },
    computed: {
      ...mapState({
        placeholder: state => state.common.appConfig.search_hint,
      }),
    },
    methods: {
      handleSearch() {
        if (!this.keyword || !this.keyword.length) {
          return
        }
        this.searchHistory(this.keyword)
        const params = {
          referrer_page: this.$route.path === '/' ? 'webHome' : '/ProductSearch',
          keyword: this.keyword,
        }
        this.$http.get('/proxy/search/routing', {
          params: {
            ...params,
          },
        }).then((res) => {
          this.searchRoute = res.results[0].params
          const fromPage = this.searchRoute.from_page

          if (fromPage === 'category' || fromPage === 'style') {
            params.type = fromPage
            params.keyword = this.searchRoute.keyword
            params.secondary_category_id = this.searchRoute.secondary_category_id

            if (fromPage === 'category') {
              params.category_id = this.searchRoute.id
            } else {
              params.style_id = this.searchRoute.id
            }
            params.search_page_type = 'classification'
          } else {
            params.search_page_type = 'keyword'
          }

          this.$router.push({
            path: '/search',
            query: {
              ...params,
            },
          })
        })
      },
      handleKeyDown(event) {
        if (!this.keyword || !this.keyword.length) {
          return
        }
        if (event.which === 13) {
          this.handleSearch(this.keyword)
        }
      },
      searchHistory(keyword) {
        const searchHistory = getLocalStorage('wzjSearchHistory') || []
        if (keyword !== undefined) {
          if (searchHistory.length > 0) {
            searchHistory.forEach((item, index) => {
              if (keyword === item) {
                searchHistory.splice(index, 1)
              }
            })
            if (searchHistory.length === 5) {
              searchHistory.splice(4, 1)
            }
          }
          searchHistory.unshift(keyword)
        }
        setLocalStorage('wzjSearchHistory', searchHistory)
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

  .search-bar {
    width: 100%;
    height: 36px;
    line-height: 36px;
    color: #fff;
    border-bottom: 1px solid #fff
  }
  .icon {
    font-size: $f_14;
    float: left;
  }
  .wzj-pc-search {
    margin-right: 10px;
    cursor: pointer;
  }
  .input {
    float: left;
    width: 75%;
    margin: 12px 0;
    font-size: $f_12;
    background: transparent;
    color: #fff;
    &::-webkit-input-placeholder {
      color: #fff;
    }
  }
</style>

