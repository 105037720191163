<template>
    <div class="unfound-page-wrapper">
        <div class="unfound-attention">
            <img src="//img.wzj.com/upload/web/images/web-404.png" alt="我在家">
            <div class="sorry">很抱歉</div>
            <p v-if="error.statusCode === 404">您访问的页面找不到了~</p>
            <p v-else>您访问的页面出错啦~</p>
            <nuxt-link to="/" replace class="home-button">返回首页</nuxt-link>
        </div>
    </div>
</template>

<script>
  export default {
    props: ['error'],
    mounted() {
      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .unfound-page-wrapper {
        height: 100%;
        width: 100%;
        text-align: center;
    }
    img {
        height: 300px;
        width: 300px;
        margin-top: 140px;
    }
    .sorry {
        margin-top: 40px;
        font-size: 40px;
        color: #6C707C;
    }
    p {
        font-size: 18px;
        color: #6C707C;
    }
    .home-button {
        display: inline-block;
        height: 60px;
        width: 264px;
        line-height: 60px;
        margin-top: 50px;
        text-align: center;
        background: #FF7C66;
        color: #fff;
        font-size: 22px;
        border-radius: 5px;
    }
</style>