<template>
  <div class="tab-header" :class="{'tab-header-single': !title}">
    <div class="title" v-if="title">{{ title }}</div>
    <div class="tab-list" :class="{'tab-single-infos': title}">
      <div
        v-for="(item, index) in itemTabData"
        :key="index"
        class="tab-item"
        :class="{ 'active': index === value && title, 'active-single': index === value && !title }"
        @click="handleTabChange(index)"
      >
        {{ item.text || item.name  }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemTab',
  props: {
    title: {
      type: String,
      default: '',
    },
    itemTabData: {
      type: Array,
      default: [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleTabChange(index) {
      this.$emit('input', index)
    },
  },
}
</script>i

<style lang="scss" rel="stylesheet/scss" scoped>
.tab-header{
  color: $_text_dark;
  font-weight: 500;
  position: relative;

  .title{
    display: inline-block;
    margin-right: 30px;
    font-size: 28px;
    height: 34px;
    line-height: 34px;
  }
  .tab-list{
    font-size: 16px;
  }

  .tab-item{
    display: inline-block;
    margin: 0 15px;
    cursor: pointer;
    &.active{
      color: $c_highlight;
      border-bottom: 2px solid $c_highlight;
    }
    &.active-single {
      color: $c_highlight;
      padding-bottom: 20px;
      border-bottom: 2px solid $c_highlight;
    }
    &:hover{
      color: $c_highlight;
    }
  }

  .tab-single-infos {
    position: absolute;
    left: 50%;
    top: 4px;
    transform: translateX(-50%);
    z-index: 1;
    white-space: nowrap;
  }
}

.tab-header-single {
  text-align: center;
}
</style>
