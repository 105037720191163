<template>
  <div class='comment-item'>
    <span class='comment-num'>{{commentNum}}条评论</span>
    <div class='comment-star'>
      <i class="wzj-pc-start" :class='{active : start.isLight}' v-for='start in startList' :key='start.index'></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentStar',
  components: {},
  props: {
    commentNum: Number,
    score: Number,
  },
  data() {
    return {
      star: this.score,
      startList: [],
    }
  },
  computed: {},
  mounted() {
    this.initStart()
  },
  methods: {
    initStart() {
      for (let i = 0; i < 5; i++) {
        const star = { isLight: false }
        star.index = i
        if (i <= Math.ceil(this.star) - 1) {
          star.isLight = true
        }
        this.startList.push(star)
      }
    },
  },
}

</script>
<style lang='scss' scoped>

  .comment-item {
    font-family:PingFangSC-Medium;
    font-size:12px;
    color:#92969c;
    line-height:12px;
    text-align:left;
  }
  .comment-star {
    margin-left: 5px;
    display: inline-block;
  }
  .wzj-pc-start {
    margin-right: 3px;
    font-size: 10px;
    color: #e6e6e6;
    &.active {
      color: #ffb200;
    }
  }
</style>