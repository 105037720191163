<template>
  <div class="selected-tab" :class="{active: selected}">
    <span class="title" :class="{selected: value !== undefined}">{{value || title}}</span>
    <i class="icon wzj-pc-arrow-left"></i>
  </div>
</template>

<script>
  /**
   * selected-tab
   * @description 支持下拉 选取的tab  配合组件SelectedSingle/SelectedCity 使用
   *
   * @param {Booles} selected - 是否选中该tab
   * @param {String} title - tab 标题 默认取title,如果有value,则取value
   * @param {String} value - 选中的值
   *
   * @example
   *
   * <selected-tab
   * :selected="false"
   * :value="现代"
   * title="不限风格" />
   */
  export default {
    name: 'SelectedTab',
    props: {
      selected: { // 是否为选中状态
        type: Boolean,
        default: false,
      },
      title: String, // 默认标题
      value: { // 选中后的标题展示
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .selected-tab {
    height: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  .title {
    margin-right: 6px;
    font-size: $f_16;
    color: $c_pitch_dark;
    vertical-align: middle;
  }
  .title.selected {
    color: $c_highlight;
  }
  .icon.wzj-pc-arrow-left {
    display: inline-block;
    font-size: $f_12;
    color: $c_dark;
    font-weight: 300;
    transform: rotate(-90deg);
    vertical-align: middle;
  }
  .active.selected-tab {
    .title {
      color: $c_highlight;
    }

    .icon {
      color: $c_highlight;
      transform: rotate(90deg);
    }
  }
</style>