<template>
  <div class="introduce">
    <div v-for="(item, index) in images"  :style="{ 'background-image': `url(${item.url})`,'min-height': `${item.height}px`, height: `${item.height / 1920 * 100}vw` }" :key="index">
    </div>
  </div>
</template>

<script>
export default {
  name: 'introduce',
  data() {
    return {
      images: [
        {
          url: '//img.wzj.com/upload/web/images/intro_1_2.jpg',
          height: 1080,
        },
        {
          url: '//img.wzj.com/upload/web/images/intro_2_2.jpg',
          height: 1080,
        },
        {
          url: '//img.wzj.com/upload/web/images/intro_3_2.jpg',
          height: 1080,
        },
        {
          url: '//img.wzj.com/upload/web/images/intro_4_2.jpg',
          height: 1080,
        },
        {
          url: '//img.wzj.com/upload/web/images/intro_5_2.jpg',
          height: 1098,
        },
      ],
    }
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.introduce {
  text-align: center;
  font-size: 0;

  div {
    width: 100%;
    background-repeat: no-repeat;;
    background-size: cover;
    background-position: 50%;
  }
}
</style>