<template>
 <div class="pageContainer" :style="{minHeight: `${screenHeight}px`}">
    <h1 class="none">{{keyword || ''}}</h1>
    <div class='header'><web-header v-model="searchImg"></web-header></div>
    <product-sort-item  :sortInitDatas='sortInitDatas' :initMaterial="initMaterial"></product-sort-item>
    <div class="container productsContainer" v-if="searchProductList.length">
      <div v-for="product in searchProductList" :key="product.id">
        <product-common :productData="product" :relatedKeyword="keyword" />
      </div>
   </div>
   <div v-if="searchProductList.length === 0 && !isLoading" class='no-product-container'>
     <img :src="noProductBox | imageFormat" alt="我在家"/>
     <h3 >找不到对应的商品</h3>
     <p>要不换个搜索条件试一下吧～</p>
   </div>
 </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLocalStorage, dataURItoBlob } from '@/assets/lib/tool'
import productCommon from '@/components/Common/ProductCommon'
import productSortItem from '@/components/Common/ProductSortItem'
import noProductBox from '@/assets/image/no_product_box.png'
import { fromRobot, buildSubpageSearchQuery, hotToSubpage } from '~/assets/lib/tool'

const SEARCH_SUB_PAGE = /^categories|styles|spaces$/

export default {
  components: {
    productCommon,
    productSortItem,
  },
  head() {
    Object.assign(this.$route.query, this.query, { keyword: this.keyword })
    const { keyword } = this.$route.query
    const keywordContent = `家具,家居,${keyword},北欧风格家具,现代风格家具,欧美风格家具,新中式风格家具,实木家具,黑胡桃家具,客厅家具,餐厅家具,书房家具,厨房家具,儿童房家具`
    const description = `我在家-高性价比的家具购买平台，为您提供与“${keyword}”相关的家具和家居产品，用一半的价格就能买到商场同品质家具。`
    return {
      title: keyword,
      titleTemplate: '%s 「我在家」一站式高品质新零售家居品牌',
      meta: [
        { hid: 'keywords', name: 'keywords', content: keywordContent },
        { hid: 'description', name: 'description', content: description },
      ],
    }
  },
  async asyncData({ query, axios, userAgent, route, error, redirect }) {
    let searchQuery = query
    if (route.name.match(SEARCH_SUB_PAGE)) {
      const classification = await axios.get('/proxy/classification', { cache: true })
      if (classification) {
        searchQuery = buildSubpageSearchQuery(classification, query, route)
        if (!searchQuery) {
          return error({ statusCode: 404, message: 'Not found' })
        }
      }
    } else if (route.name === 'hot') {
      const classification = await axios.get('/proxy/classification', { cache: true })
      if (classification) {
        const subPageLink = hotToSubpage(classification, route.params.keyword)
        if (subPageLink) {
          redirect(301, subPageLink)
          return
        }
        searchQuery = { keyword: route.params.keyword, search_page_type: 'keyword' }
      }
    }
    const limit = fromRobot(userAgent) ? 60 : 20
    let sortInitDatas = {}
    let isLoading = true
    // 非以图搜图 && 非以关键词搜图
    const temporary = Object.assign({}, searchQuery)
    if (searchQuery.referrer_page !== 'imageSearch' && searchQuery.search_page_type !== 'keyword') {
      delete temporary.keyword
      const classificationRes = await axios.get('/proxy/classification/list', {
        params: {
          ...temporary,
        },
      })
      if (classificationRes) {
        sortInitDatas = classificationRes.results[0]
      }
    }

    if (searchQuery.search_page_type === 'classification' || searchQuery.type === 'category') {
      temporary.main_style_id = temporary.style_id
    } else {
      temporary.main_style_id = 0
    }

    let initMaterial = []
    let searchProductList = []
    let hasMoreProducts = false
    let offset = 0
    if (!temporary.referrer_page || temporary.referrer_page.indexOf('imageSearch') === -1) {
      const [materialRes, productRes] = await Promise.all([
        // 加载材质
        await axios.get(`/proxy/categories/${temporary.category_id || 0}/materials`, {
          params: {
            style_id: searchQuery.style_id || 0,
            keyword: searchQuery.keyword || '',
            category_id: searchQuery.category_id || 0,
          },
        }),
        // 加载商品数据
        await axios.get(`/proxy/search/products`, { params: {
          limit,
          offset: 0,
          ...temporary,
        }}),
      ])
      if (materialRes) {
        const materialAll = [{
          id: '',
          name: '全部',
        }]
        initMaterial = materialAll.concat(materialRes.results)
      }

      if (productRes) {
        searchProductList = productRes.results
          .map(({ id, name, materials, description, min_price, max_price, promotion_tag, image_url, has_video, tags, review_count, review_content, sku_info }) =>
            ({ id, name, materials, description, min_price, max_price, promotion_tag, image_url, has_video, tags, review_count, review_content, sku_info }))
        hasMoreProducts = productRes.has_more
        offset = searchProductList.length
      }
    }
    isLoading = false
    return { sortInitDatas, query: temporary, searchProductList, hasMoreProducts, offset, initMaterial, isLoading, keyword: searchQuery.keyword }
  },
  data() {
    return {
      searchImg: false,
      screenHeight: '',
      initMaterial: [],
      noProductBox,
      referrer_page: this.$route.query.referrer_page || '',
      isGoTopShow: false,
    }
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
    }),
  },
  watch: {
    $route() {
      if (this.$route.query.referrer_page && this.$route.query.referrer_page.indexOf('imageSearch') !== -1) {
        this.doImgSearch()
      } else {
        if (this.$route.query.canReload !== 2) { // 顶部搜索组件输入，页面重新刷新
          window.location.reload()
        } else {
          this.query = Object.assign(this.query, this.$route.query)
          this.offset = 0
          this.searchProductList = []
          this.initMaterials()
          this.searchProducts(this.query)
        }
      }
    },
    scrollTop(val) {
      const isEndReached = window.innerHeight + val + 40 >= document.body.offsetHeight
      if (val > 1000) {
        this.isGoTopShow = true
      } else {
        this.isGoTopShow = false
      }
      // 距底部40px 时加载更多
      if (isEndReached && this.hasMoreProducts && this.referrer_page !== 'imageSearch') {
        this.searchProducts(this.query)
      }
    },
  },
  mounted() {
    this.screenHeight = window.innerHeight
    if (this.referrer_page.indexOf('imageSearch') !== -1) {
      this.doImgSearch()
    }
  },
  methods: {
    async doImgSearch() { // 以图搜图
      this.isLoading = true
      const wzjImageSearchData = getLocalStorage('wzjImageSearchData')[this.$route.query.indexImg]
      const blob = dataURItoBlob(wzjImageSearchData.url)
      const formData = new FormData()
      formData.append('image', blob)
      formData.append('keyword', wzjImageSearchData.name)
      formData.append('ids', wzjImageSearchData.ids)
      const res = await this.$http.post('/proxy/image-search', formData, { isFormData: true })
      if (res) {
        this.isLoading = false
        this.searchProductList = res.results[0].products
      }
    },
    async searchProducts(query) {
      if (this.isLoading) return
      this.isLoading = true
      if (this.$route.query.search_page_type !== 'keyword') {
        delete query.keyword
      }
      if (this.$route.query.search_page_type === 'classification' || this.$route.query.type === 'category') {
        query.main_style_id = query.style_id
      } else {
        query.main_style_id = 0
      }
      const productRes = await this.$http.get(`/proxy/search/products`, { params: {
        limit: 10,
        offset: this.offset,
        ...query,
      }})
      if (productRes) {
        this.isLoading = false
        this.hasMoreProducts = productRes.has_more
        this.searchProductList = this.searchProductList.concat(productRes.results)
        this.offset = this.searchProductList.length
      }
    },
    // 获取材质分类
    async initMaterials() {
      const materialRes = await this.$http.get(`/proxy/categories/${this.$route.query.category_id || 0}/materials`, {
        params: {
          style_id: this.$route.query.style_id || 0,
          keyword: this.$route.query.keyword || '',
          category_id: this.$route.query.category_id || 0,
        },
      })
      if (materialRes) {
        const materialAll = [{
          id: '',
          name: '全部',
        }]
        this.initMaterial = materialAll.concat(materialRes.results)
      }
    },
  },
}

</script>
<style lang='scss' scoped>
.header {
  width: 100%;
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
}
.pageContainer {
  background:#f8f8f8;
  overflow-y: auto;
  padding-top: 40px;
}
.productsContainer {
  margin-bottom: 20px!important;
  padding: 20px 0;
  border: 1px solid #e6e6e6;
  background: #fff;
  border-radius: 6px;
  overflow: hidden;
}
 .no-product-container {
   margin-top: 140px;
   text-align: center;
   h3 {
     margin: 15px 0 5px;
     font-size: 16px;
     font-family: PingFangSC-Medium;
     color: #2C3038;
   }
   p {
     font-size: 14px;
     font-family: PingFangSC-Regular;
     color: #92969C;
   }
 }
</style>
