<template>
  <div class="consult-ctn">
    <div v-if="initIframe" v-show="showChatBox" class="chat-box-ctn">
      <div class="loading" :style="{ visibility: loading ? 'visible' : 'hidden' }"></div>
      <div class="chat-box" :style="{ visibility: loading ? 'hidden' : 'visible' }">
        <div class="close-btn" @click="handleCloseClick"><i class="wzj-pc-close-model"></i></div>
        <iframe
          ref="frame"
          :src="frameSrc"
          @load="addIframeOnload"
        />
      </div>
    </div>
    <div v-show="!showChatBox" class="consult-btn-ctn" @click="handleBtnClick">
      <div class="consult-btn">
        <i class="wzj-pc-consult"></i>
        <span>联系客服，在线咨询</span>
      </div>
      <div class="pop-over" v-if="popMsg.avatar">
        <i class="close-icon wzj-pc-close-model" @click.stop="handleClearPop"></i>
        <div class="pop-header">
          <img class="avatar" :src="popMsg.avatar | imageFormat(200)" />
          <div class="name">{{popMsg.name}}</div>
        </div>
        <div class="pop-body">
          {{popMsg.msg}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import { getLocalStorage, getDeviceId, getLocationOrigin } from '~/assets/lib/tool'
const H5_MALL_HOST = 'https://test-m.wzj.com'
// const H5_MALL_HOST = 'http://127.0.0.1:14440'
let targetOrigin = ''
export default {
  name: 'consult',
  data() {
    return {
      showChatBox: false,
      popMsg: {},
      msgNum: 0,
      loading: true,
      frameSrc: '',
      shouldSendToken: true,
      initIframe: false,
    }
  },
  computed: mapState({
    token: state => state.common.token,
  }),
  watch: {
    token(val) {
      this.sendToken(val)
    },
  },
  mounted() {
    const origin = getLocationOrigin()
    let query = '?channel=PC_MALL'
    const history = getLocalStorage('browseHistory')
    if (history && history.length > 0) {
      query = `${query}&viewRecord=${history.join(',')}`
    }
    targetOrigin = origin.indexOf('wzj') > -1 ? origin.replace(/www/, 'm') : H5_MALL_HOST
    this.frameSrc = `${targetOrigin}/template/im.html${query}`
    // this.frameSrc = targetOrigin + query
    this.addMsgListener()
  },
  methods: {
    handleBtnClick() {
      if (!this.initIframe) {
        this.initIframe = true
      }
      this.showChatBox = true
      this.handleClearPop()
    },
    addIframeOnload() {
      this.loading = false
      if (this.shouldSendToken) {
        this.sendToken()
        this.shouldSendToken = false
      }
    },
    handleClearPop() {
      this.popMsg = {}
    },
    addMsgListener() {
      window.addEventListener('message', (event) => {
        const { data, origin } = event
        if (origin !== targetOrigin) return
        switch (data.msg_type) {
          case 'TEXT': this.handleTextMsg(data); break
          case 'LOGIN': this.handleLoginMsg(); break
          case 'URL': this.handleUrlMsg(data); break
          default: break
        }
      })
    },
    handleTextMsg(data) {
      const { content, ext, origin_type } = data
      if (this.showChatBox || origin_type !== 'wzj_cs') return
      const { msg } = content
      const { cs_icon: avatar, cs_nick: name } = ext
      this.popMsg = {
        avatar,
        name,
        msg,
      }
    },
    handleLoginMsg() {
      this.checkLogin()
    },
    handleUrlMsg(data) {
      this.$router.push(data.url)
    },
    handleCloseClick() {
      this.showChatBox = false
    },
    sendToken(token = Cookies.get('token')) {
      const param = {
        token,
        deviceId: getDeviceId(),
        msg_type: 'TOKEN',
      }
      this.loading = true
      this.sendMessage(param)
    },
    sendMessage(param) {
      if (!this.$refs.frame) return
      const { contentWindow } = this.$refs.frame
      contentWindow.postMessage(param, targetOrigin)
    },
  },
}
</script>

<style lang="scss" scoped>
.consult-ctn{
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  color: #fff;
  font-size: 14px;
}
.consult-btn{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: $c_highlight;
  width: 186px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 24px;
    padding-left: 8px;
    padding-right: 17px;
  }
}
.pop-over{
  position: absolute;
  right: 0;
  bottom: 60px;
  z-index: 1;
  width: 250px;
  height: 90px;
  padding: 10px;
  background: #fff;
  box-shadow:0px 4px 10px 0px rgba(0,0,0,0.1);
  border:1px solid $c_gray;
  cursor: pointer;
  @include equilateral_triangle(12px, #fff, 82px, 119px);
  .close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    color: $c_dark;
  }
  .pop-header{
    display: flex;
    align-items: center;
    height: 20px;
    color: $_gray;
    font-size: 13px;
    margin-bottom: 10px;
    .avatar{
      margin-right: 4px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
  .pop-body{
    font-size: 14px;
    color: $_text_dark;
    height: 40px;
    @include multiline-omission();
  }
}
.chat-box-ctn{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 400px;
  height: 600px;
  .close-btn{
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    color: $_text_dark;
    cursor: pointer;
    i{
      font-size: 14px;
    }
  }
  iframe {
    width: 100%;
    height: 600px;
    border: 1px solid $_text_e6;
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
  }
}
.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%,-50%);
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: $c_highlight;
  border-left-color: $c_highlight;
  border-bottom-color:$c_highlight;
  animation: loading-rotate .8s infinite linear;
}
@keyframes loading-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

