<template>
  <div class="homer-list clearfix" :class="{'waterfall-list': waterfall}" v-if="list.length">
    <homer-item class="homer-item" v-for="(item, index) in list"
                :key="item.id"
                :index="index"
                :id="item.id.toString()"
                :cover="getImgUrl(item)"
                :images="item.images"
                :description="item.description"
                :icon="item.icon || item.icon_url"
                :nickname="item.nickname || item.name"
                :newest="item.is_new === 'true'"
                :waterfall="waterfall"
                :distanceTips="getDistanceTips(item)"
                :hitsTips="item.hits_tips"
                :preview="preview"
                :homerStyle="item.style"
                :homerCity="item.city"
            ></homer-item>
  </div>
</template>

<script>
  import HomerItem from '@/components/Common/HomerItem'

  export default {
    name: 'HomerItemWrap',
    components: {
      HomerItem,
    },
    props: {
      list: { // 生活家列表数据
        type: Array,
        default: () => [],
      },
      waterfall: { // 是否瀑布流展示
        type: Boolean,
        default: false,
      },
      preview: { // 是否显示预览
        type: Boolean,
        default: true,
      },
    },
    methods: {
      getImgUrl(item) {
        if (item.cover_url) return item.cover_url
        if (item.cover) return item.cover[0].url
        if (item.images) return item.images[0].url
      },
      getDistanceTips(item) {
        const { city, district, estate } = item
        if (item.distance_tips) return item.distance_tips
        return `${city}·${district}${estate}`
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .homer-list{
    margin-left: -20px;
    margin-top: -20px;

    .homer-item {
      width: calc(33% - 20px);
      margin-top: 20px;
      margin-left: 20px;
    }

    // 瀑布流布局 此方法兼容到ie10及其以上
    .waterfall-list{
      column-count: 3;
      column-gap: 0;
    }
  }
</style>
