<template>
  <div class="info-img">
    <div class="main-img-ctn">
      <img v-if="mainImg" :src="mainImg | imageFormat" :alt="galleryAlt">
      <template v-if="video">
        <video-player
          class="position-video"
          v-show="videoVisible"
          :id="~~video.id"
          :durationTotal="video.duration"
          :mutedState="!!video.quiet_mode"
          :autoPlay="!!video.auto_play"
          :size="~~video.size"
          :poster="video.cover_image.url"
          :playState="playState"
          @playHandler="playHandler"
          ref="video">
        </video-player>
        <div :class="[videoVisible ? 'pause-btn' : 'play-btn']" @click="handleVideoStatus">
          <i class="wzj-pc-close-s" v-show="videoVisible"></i>
        </div>
      </template>
    </div>
    <div class="img-list" ref="imgList">
      <div :class="['control','up-arrow', !canUp ? 'inactive' : '']" @click="handleArrowClick('up')"><i class="wzj-pc-arrow-up"></i></div>
      <div :class="['control','down-arrow', !canDown ? 'inactive' : '']" @click="handleArrowClick('down')"><i class="wzj-pc-arrow-down"></i></div>
      <div class="img-list-center">
        <div
          class="img-ctn"
          ref="imgCtn"
          :style="{
            msTransform: `translateY(${translateY}px)`,
            webkitTransform: `translateY(${translateY}px)`,
            transform: `translateY(${translateY}px)`
          }"
        >
          <img
            v-for="(item, index) in imgList"
            class="lazyload"
            :class="{ active: item.url === mainImg }"
            :data-src="item.url | imageFormat"
            :key="index"
            @click="handleImgHover(item)"
            :alt="`${galleryAlt}-${index + 1}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VideoPlayer from '@/components/Common/VideoPlayer'
export default {
  name: 'ProductGallery',
  components: {
    VideoPlayer,
  },
  props: {
    mainImg: {
      type: String,
      default: '',
    },
    imgList: {
      type: Array,
      default() {
        return []
      },
    },
    video: Object,
    galleryAlt: {
      type: String,
      default: '我在家',
    },
  },
  computed: {
    maxTranslateY() {
      const { imgList } = this
      const LENGTH = 100
      return imgList.length * LENGTH
    },
    canUp() {
      return this.translateY < 0
    },
    canDown() {
      const { maxTranslateY, translateY, length } = this
      return Math.abs(translateY) < maxTranslateY - length
    },
  },
  data() {
    return {
      translateY: 0,
      offset: 10, // 偏移修正
      length: 0, // 滚动区域高度
      playState: false,
      videoState: false,
      videoVisible: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getLength()
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleArrowClick(type) {
      const { canUp, canDown, offset, length } = this
      if (type === 'up' && canUp) {
        this.translateY += length - offset
        return
      }
      if (type === 'down' && canDown) {
        this.translateY -= length - offset
      }
    },
    handleImgHover(item) {
      this.$emit('changeMainImg', item.url)
      this.videoVisible = false
      if (this.$refs.video) {
        this.$refs.video.setPlayState(false)
      }
    },
    handleResize() {
      this.translateY = 0
      this.$nextTick(() => {
        this.getLength()
      })
    },
    getLength() {
      const height = this.$refs.imgList.offsetHeight
      // control高度
      const controlHeight = 40 * 2
      this.length = height - controlHeight
    },
    // 视频播放状态
    playHandler(flag) {
      this.videoState = flag
      this.playState = flag
    },
    handleVideoStatus() {
      this.videoVisible = !this.videoVisible
      this.$refs.video.updatePlayState()
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.info-img{
  padding-top: calc(100% - 100px);
  position: relative;
  .main-img-ctn{
    position: absolute;
    left: 0;
    top: 0;
    right: 100px;
    z-index: 1;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.img-list{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  width: 90px;
  height: 100%;
  overflow: hidden;
  .control{
    width: 100%;
    height: 30px;
    position: absolute;
    left: 0;
    z-index: 1;
    background: $_bg_f6;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
      color: $c_highlight;
    }
    &.inactive{
      color: $_text_e6;
    }
    i{
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      transform: scale(.61);
    }
  }
  .up-arrow{
    top: 0;
  }
  .down-arrow{
    bottom: 0;
  }
}
.img-list-center{
  height: calc(100% - 80px);
  margin: 40px 0;
  overflow: hidden;
}
.img-ctn{
  display: flex;
  flex-direction: column;
  transition: transform .4s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  img{
    height: 90px;
    width: 90px;
    margin-bottom: 10px;
    &:hover{
      border: 2px solid $c_highlight;
    }
    &.active{
      border: 2px solid $c_highlight;
    }
    &:last-of-type{
      margin-right: 0;
    }
  }
}
.position-video{
  position: absolute;
  left: 0;
  top: 0;
}
.play-btn{
  width: 75px;
  height: 75px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(//img.wzj.com/h5-static/img/h5/play.png?x-oss-process=style/w800);
  position: absolute;
  z-index: 99;
  left: 20px;
  bottom: 20px;
  cursor: pointer;
}
.pause-btn{
  width: 20px;
  height: 20px;
  background: rgb(128, 128, 128);
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  i{
    font-size: 12px;
    color: #fff;
    transform: scale(.83)
  }
}
</style>
