<template>
  <wzj-dialog :showHeader="false" :visible="visible" v-if="visible" :width="400" class="dialog-ctn">
    <div class="login-ctn">
      <div class="close-btn wzj-pc-close-model" @click="handleCloseDialog"></div>
      <div class="title">欢迎登录</div>
      <div class="subtitle">未注册的手机号将自动创建账号</div>
      <div :class="{'input-ctn': true, error: errorMobile }">
        <label class="label" for="mobile">
          <i class="wzj-pc-mobile"></i>
          <div class="split-line"></div>
        </label>
        <input class="input" type="text" v-model="mobile" placeholder="请填写手机号码" id="mobile">
      </div>
      <div class="captcha-ctn">
        <captcha captchaId="2df7055b682045c78b508be7c839e50c" @getData="handleCaptchaData" @getError="handleCaptchaError" @getOnloadError="handleCaptchaOnLoadError" ref="captcha"/>
      </div>
      <div :class="{'input-ctn': true, error: errorCode }">
        <label class="label" for="code">
          <i class="wzj-pc-captcha"></i>
          <div class="split-line"></div>
        </label>
        <input class="input" type="text" v-model="code" placeholder="请填写验证码" id="code">
        <div :class="{'send-code': true, disabled: countDown > 0 || !mobile}" @click="getMobileCode">
          {{ countDown > 0 ? `${countDown}s` : '获取验证码' }}
        </div>
      </div>
      <div class="error-text-ctn" v-show="errorText">
        <div class="error-text"><i class="wzj-pc-tishi"></i>{{errorText}}</div>
      </div>
      <div class="voice-code-ctn" v-show="showVoiceCode">
        <div class="voice-code">
          收不到短信？
          <span @click="getVoiceCode" class="get-code" v-if="!countDown">获取语音验证码</span>
          <span v-else>重新获取{{countDown}}s</span>
        </div>
      </div>
      <div :class="{'login-btn': true, disabled: !mobile || !captcha || !code }" @click="handleLogin">登录</div>
      <div class="agreement-ctn">登录即表示同意<span class="agreement" @click="handleCheckagreement">《用户注册协议》</span><span class="agreement" @click="handleCheckPrivacy">《隐私协议》</span></div>
    </div>
    <agreement-dialog :visible.sync="agreementVisible" :url="agreementUrl" />
  </wzj-dialog>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
import axios from 'axios'
import AgreementDialog from '@/components/Common/AgreementDialog'
import { isMobile } from '~/assets/lib/tool'

let loginPromise = Promise.resolve()
export default {
  name: 'LoginDialog',
  components: {
    AgreementDialog,
  },
  data() {
    return {
      mobile: '',
      captcha: '',
      code: '',
      errorMobile: false,
      errorCode: false,
      errorText: '',
      countDown: 0,
      intervalTag: '',
      showVoiceCode: false,
      agreementVisible: false,
    }
  },
  computed: {
    ...mapState({
      visible: state => state.common.loginDialogVisible,
      token: state => state.common.token,
    }),
  },
  mounted() {
    this.initCheckLogin()
  },
  methods: {
    initCheckLogin() {
      const that = this
      Vue.use({
        install() {
          Vue.prototype.checkLogin = () => {
            return new Promise((resolve, reject) => {
              if (that.token || Cookie.get('token')) {
                resolve()
                return
              }
              loginPromise.resolve = resolve
              that.$store.commit('common/LOGIN_DIALOG_VISIBLE', true)
            })
          }
        },
      })
    },
    handleCaptchaData(data) {
      this.captcha = data.validate
    },
    handleCaptchaError(text) {
      this.$toast(text)
    },
    handleCaptchaOnLoadError({ error, text }) {
      this.$toast(text)
      const api = process.env.API_URL ? process.env.API_URL.replace(/api/, 'm') : 'https://m.wzj.com'
      axios.get(`${api}/js-error-analysis`, {
        params: {
          uin: 1,
          target: 'Captcha.vue',
          stack: '', // 异常堆栈信息，避免错误信息过长
          msg: `云盾onload error: ${error}`, // 异常信息
          ext: JSON.stringify({
            group: 'shop_web', // 钉钉群
          }),
          from: window.location.href,
        },
      }).catch(e => console.log(e))
    },
    // 验证码
    async getMobileCode() {
      const { mobile, captcha, countDown } = this
      if (!mobile) return
      const paramCorrect = this.checkParam(false)
      if (countDown > 0 || !paramCorrect) return
      const param = {
        mobile,
        image_captcha: captcha,
      }
      const res = await this.$http.post('/proxy/sms-captcha', param)
      if (res) {
        this.startTimer()
        this.$toast('发送验证码成功')
      }
    },
    async getVoiceCode() {
      const { mobile } = this
      const mobileRes = this.checkMobile()
      if (!mobileRes) return
      this.startTimer()
      const param = {
        mobile,
      }
      const res = await this.$http.post('/proxy/verification-code/audio', param)
      if (res) {
        this.$toast('我们将以电话形式告知您验证码，来电可能被误判为骚扰电话，请您放心接听')
      }
    },
    async handleLogin() {
      const { mobile, code } = this
      const paramCorrect = this.checkParam(true)
      if (!paramCorrect) return
      const param = {
        source: 'pc_mall',
        mobile,
        code,
      }
      const res = await this.$http.post('/proxy/signin', param)
      if (res) {
        if (res.error_code) {
          this.errorCode = true
          this.errorText = res.error_message
          return
        }
        this.$toast('登录成功')
        const token = res.results[0].token
        Cookie('token', token, {
          domain: '',
          expires: new Date(res.results[0].expiry_timestamp * 1000),
          path: '/',
        })
        this.$store.commit('common/TOKEN', token)
        this.$store.commit('common/LOGIN_DIALOG_VISIBLE', false)
        this.$store.dispatch('common/GET_USER_DATA')
        this.checkCache()
        // location.reload()
      }
    },
    async checkCache() {
      if (loginPromise.resolve) {
        loginPromise.resolve()
      }
    },
    startTimer() {
      this.countDown = 30
      this.intervalTag = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1
          return
        }
        this.showVoiceCode = true
        if (this.$refs.captcha) {
          this.$refs.captcha.refreshCaptcha()
        }
        clearInterval(this.intervalTag)
      }, 1000)
    },
    checkParam(isLogin) {
      const { captcha, code } = this
      this.errorMobile = false
      this.errorCode = false
      this.errorText = ''
      const mobileRes = this.checkMobile()
      if (!mobileRes) return false
      if (isLogin) {
        if (!code || code.length !== 6) {
          this.$toast('请输入六位数字验证码')
          this.errorCode = true
          this.errorText = '请输入六位数字验证码'
          return false
        }
        return true
      }
      if (!captcha) {
        this.$toast('请完成图形验证码认证')
        this.errorText = '请完成图形验证码认证'
        return false
      }
      return true
    },
    checkMobile() {
      const { mobile } = this
      this.errorMobile = false
      if (!mobile || !isMobile(mobile)) {
        this.$toast('请输入正确格式手机号')
        this.errorMobile = true
        this.errorText = '请输入正确格式手机号'
        return false
      }
      return true
    },
    handleCloseDialog() {
      this.$store.commit('common/LOGIN_DIALOG_VISIBLE', false)
      Object.assign(this.$data, this.$options.data())
      // 清空原有回调函数
      loginPromise = Promise.resolve()
    },
    handleCheckagreement() {
      this.agreementVisible = true
      this.agreementUrl = 'https://m.wzj.com/m_static/terms'
    },
    handleCheckPrivacy() {
      this.agreementVisible = true
      this.agreementUrl = 'https://m.wzj.com/m_static/privacy-agreement'
    },
  },
}

</script>
<style lang='scss' scoped>
.dialog-ctn{
  overflow: visible;
}
.login-ctn{
  position: relative;
  padding: 30px 40px;
  height: 429px;
  .close-btn{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;
    color: $c_dark;
  }
  .title{
    color: $c_black;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .subtitle{
    font-size: 13px;
    color: $c_dark;
    margin-bottom: 30px;
  }
}
.input-ctn{
  display: flex;
  height: 38px;
  border-radius: 4px;
  border: 1px solid $c_gray;
  overflow: hidden;
  &.error{
    border-color: $c_error;
  }
  .label{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 38px;
    min-width: 38px;
    color: $c_dark;
    .split-line{
      position: absolute;
      top: 6px;
      bottom: 6px;
      right: 0;
      z-index: 1;
      width: 1px;
      background: $c_gray;
    }
  }
  .input{
    font-size: 13px;
    flex-grow: 1;
    padding-left: 10px;
  }
  .send-code{
    width: 85px;
    min-width: 85px;
    font-size: 13px;
    border-left: 1px solid $c_gray;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $c_black;
    background: $_bg_f6;
    cursor: pointer;
    &.disabled{
      cursor: default;
      color: $c_dark;
    }
  }
}
.captcha-ctn{
  margin: 20px 0;
  height: 38px;
}
.login-btn{
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  background: $c_highlight;
  height: 48px;
  line-height: 48px;
  color: #fff;
  font-size: 16px;
  border-radius:4px;
  &.disabled {
    color: rgba($color: #fff, $alpha: 0.7);
    cursor: default;
  }
}
.error-text-ctn{
  position: relative;
  font-size: 12px;
  .error-text{
    position: absolute;
    left: 0;
    top: 12px;
    z-index: 1;
    color: $_gray;
    i{
      font-size: 12px;
      color: $c_error;
      margin-right: 4px;
    }
  }
}
.voice-code-ctn{
  position: relative;
  font-size: 12px;
}
.voice-code{
  position: absolute;
  right: 0;
  top: 12px;
  z-index: 1;
  color: $c_dark;
  .get-code{
    color: $c_highlight;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
}
.agreement-ctn{
  color: $_gray;
  font-size: 12px;
  text-align: center;
  .agreement{
    cursor: pointer;
    &:hover{
      color: $_text_green;
      text-decoration: underline;
    }
  }
}
.agreement-dialog{
  border-top: 1px solid $c_gray;
  padding: 20px;
  font-size: 14px;
  color: $c_black;
  .agreement-head, dl{
    border: 1px solid $c_gray;
    padding: 20px;
    margin-bottom: 20px;
  }
  dt{
    padding-bottom: 18px;
    border-bottom: 1px solid $c_gray;
    margin-bottom: 20px;
  }
  dd{
    font-size: 13px;
    color: $_gray;
  }
  p{
    margin-bottom: 20px;
  }
}
.agreement-dialog{
  overflow: visible;
}
</style>
