<template>
  <div class="selected-single-wrapper" v-if="show">
    <div class="selected-single-list">
      <div :class="{'active-list': currentValue === list.name}" v-for="list in singleLists"  @click="handleChangeValue(list)" :key="list.value">
        <span>{{list.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * selected-single
   * @description 单选下拉列表
   *
   * @param {Array} singleLists - 列表
   * @param {String} currentValue - 当前选中的值
   * @param {String} value - 是否展示
   *
   * @example
   *
   * <selected-single
   *  v-model="isSelectedStyle"
   *  :currentValue="name"
   *  :singleLists="styleFilterData"
   *  @handleChangeFiltrateStyle="handleChangeFiltrateStyle" />
   */
  export default {
    name: 'SeletedSingle',
    props: {
      singleLists: Array,
      currentValue: {
        type: String,
        default: '',
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        show: this.value,
      }
    },
    watch: {
      value(val) {
        this.show = val
      },
    },
    methods: {
      handleChangeValue(list) {
        this.$emit('input', false)
        this.$emit('handleChangeFiltrateStyle', list)
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .selected-single-wrapper {
    position: relative;
    width: 100%;
    background: #fff;
    overflow-y: hidden;
  }
  .selected-single-list {
    min-height: 200px;
    max-height: 300px;
    background: #fff;
    font-size: $f_16;
    color: $c_black;
    overflow-y: scroll;

    >div {
      height: 50px;
      line-height: 50px;
      text-align: center;

      &:hover,&.active-list{
        background: #FAFAFA;
        color: $c_highlight;
      }
    }
  }
</style>
