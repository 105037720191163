<template>
  <wzj-dialog :visible="visible" title="" :width="520" class="agreement-dialog" @update:visible="handleUpdateVisible">
    <iframe :src="url" frameborder="0"></iframe>
  </wzj-dialog>
</template>
<script>
export default {
  props: {
    visible: Boolean,
    url: String,
  },
  methods: {
    handleUpdateVisible(flag) {
      this.$emit('update:visible', flag)
    },
  },
}
</script>

<style lang="scss" scoped>
.agreement-dialog{
  overflow: visible;
}
.agreement-dialog{
  border-top: 1px solid $c_gray;
  padding: 20px;
  font-size: 14px;
  color: $c_black;

  iframe {
    width: 100%;
    height: 450px;
  }
}
</style>
