<template>
  <nuxt-link
    :to="`/homers/${id}`"
    target="_blank"
    class="homer-box"
    :class="{'waterfall-box': waterfall, 'block-box': !waterfall}"
    @mouseover.native="showDeleteBtn = true"
    @mouseleave.native="showDeleteBtn = false"
  >
    <collect-delete-btn @handleDeleteClick="handleDeleteClick" v-show="showDeleteBtn" v-if="isCollect"/>
    <div class="cover-box">
      <img class="lazyload" :data-src="cover | imageFormat" :alt="`${trimCityTail(homerCity)}${homerStyle}风格生活家 ${nickname}`">
      <div class="image-preview" v-if="preview" @click.stop.prevent="imagePreview">
        <div><i class="icon wzj-pc-img"></i>{{images.length}}</div>
        <p>查看全部</p>
      </div>
    </div>
    <div class="content-box">
      <h5 class="desc">{{description}}</h5>
      <div class="detail">
        <div class="avatar-box">
          <img class="avatar lazyload" :data-src="icon | imageFormat"  :alt="`${trimCityTail(homerCity)}${homerStyle}风格生活家 ${nickname}`">
        </div>
        <div class="intro-box">
          <div class="nickname-box">
            <span class="newest" v-if="newest && showMore">最新入驻</span>
            <span class="nickname">{{nickname}}</span>
          </div>
          <p :class="{address: true, 'collect-address': !hitsTips}" >{{distanceTips}}</p>
        </div>
        <div class="extra-box" v-if="hitsTips && showMore">
          <i class="icon wzj-pc-look"></i>{{hitsTips}}
        </div>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
  import CollectDeleteBtn from '@/components/Common/CollectDeleteBtn'
  import { trimCityTail } from '@/assets/lib/tool'
  /**
   * UI组件-生活家列表->生活家卡片
   * For Example
   * <homer-item
     cover="https://img.wzj.com/upload/goods/images/3ba16b823ec5b1363bf7d170d9ee8d70b4672c72.jpg"
     ...></homer-item>
   */
  export default {
    name: 'HomerItem',
    components: {
      CollectDeleteBtn,
    },
    props: {
      cover: String,
      id: { // id
        type: String,
        default: '',
      },
      images: { // 预览图片列表
        type: Array,
        default: () => [],
      },
      preview: { // 是否支持预览
        type: Boolean,
        default: true,
      },
      description: { // 描述、标题
        type: String,
        default: '',
      },
      icon: { // 头像
        type: String,
        default: '',
      },
      newest: { // 是否最新入住
        type: Boolean,
        default: false,
      },
      nickname: { // 昵称
        type: String,
        default: '',
      },
      distanceTips: { // 城市楼盘
        type: String,
        default: '',
      },
      hitsTips: { // 浏览人数
        type: String,
        default: '',
      },
      waterfall: { // 是否瀑布流展示
        type: Boolean,
        default: false,
      },
      imageHeight: { // 图片展示高度
        type: Number,
        default: 376,
      },
      showMore: {
        type: Boolean,
        default: true,
      },
      isCollect: {
        type: Boolean,
        default: false,
      },
      index: {
        type: Number,
        default: 0,
      },
      homerStyle: {
        type: String,
        default: '',
      },
      homerCity: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        showDeleteBtn: false,
      }
    },
    filters: {
      hitsFilter(hints) {
        return hints > 9999 ? `${(hints / 10000).toFixed(1)}万` : hints
      },
    },
    methods: {
      trimCityTail,
      imagePreview() {
        this.$preview({ items: this.images })
      },
      handleDeleteClick() {
        this.$emit('handleDeleteClick', { id: this.id, index: this.index })
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

  // 瀑布流布局 此方法兼容到ie10及其以上
  .waterfall-box{
    column-break-inside: avoid;
    & > .content-box{
      & > .desc{
        line-height: 20px;
      }
    }
  }

  .detail {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  // 等高方块布局
  .block-box{
    float: left;
    & > .content-box{
      & > .desc{
        @include line-height(18px);
        @include text-ellipsis();
      }
    }
  }

  .homer-box{
    width: 100%;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    position: relative;
    display: block;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(146,150,156,0.30);
    }
  }
  .cover-box{
    position: relative;
    font-size: 0;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    & > img{
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 1;
    }
    .image-preview {
      display: none;
      width: 68px;
      height: 44px;
      position: absolute;
      left: 10px;
      top: 10px;
      z-index: 2;
      cursor: pointer;
    }
    &:hover > .image-preview{
      display: block;
    }
    &:hover > img{
      transform-origin: center center;
      animation: imageScroll 4s infinite 0.1s linear alternate backwards;
    }
  }

  .image-preview {
    color: #fff;
    text-align: center;
    background-color: $c_highlight;
    border-radius: 4px;
    & > div{
      font-size: $f_14;
      margin-top: 6px;
      & > .icon{
        font-size: $f_14;
        margin-right: 4px;
      }
    }
    & > p{
      margin-top: 6px;
      font-size: $f_12;
      @include line-height(12px)
    }
  }

  .content-box{
    padding: 20px;
    background-color: $c_white;
    & > .desc{
      margin-bottom: 20px;
      color: $c_pitch_dark;
      font-size: $f_16;
      font-weight: bold;
    }
  }

  .avatar-box{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    & > img{
      width: 100%;
    }
  }

  .intro-box{
    flex: 1;
    height: 40px;
    & > .address{
      margin-top: 4px;
      font-size: $f_14;
      color: $c_dark;
      @include text-ellipsis();
      max-width: 142px;
    }
    & > .collect-address{
      max-width: 100%;
    }
  }

  .nickname-box{
    padding-top: 4px;
    font-size: 10px;
    .newest{
      padding: 2px 4px;
      color: $c_white;
      border-radius: 8px;
      background-color: $c_highlight;
      text-align: center;
      font-size: 10px;
      -webkit-text-size-adjust: none;
      margin-right: 4px;
    }
    .nickname{
      font-size: $f_14;
      color: $c_pitch_dark;
      @include line-height(14px)
    }
  }

  .extra-box{
    position:absolute;
    bottom: -3px;
    right: 0;
    z-index: 1;
    font-size: $f_12;
    color: $c_dark;
    margin-top: 24px;
    & > .icon{
      font-size: $f_12;
      margin-right: 2px;
    }
  }

  @keyframes imageScroll {
    0%{
      transform: translateX(0);
    }
    50%{
      transform: translateX(-33%);
    }
    100%{
      transform: translateX(0);
    }
  }
</style>
