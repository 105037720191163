/* eslint-disable standard/object-curly-even-spacing */
<template>
 <div class="container">
    <no-ssr>
      <breadcrumb :list="breadList"/>
    </no-ssr>

    <div class="product-info">
      <div class="img-gallery">
        <product-gallery
                :mainImg="mainImg"
                :imgList="productInfo.images"
                @changeMainImg="handleGalleryChange"
                :video="productInfo.videos && productInfo.videos[0]"
                :galleryAlt="galleryAlt"
        />
      </div>
      <div class="info-text">
        <div class="product-name">
          <span class="tag" v-for="tag in productInfo.tags" :key="tag.name" :style="{'color': tag.text_color, 'backgroundColor': tag.background_color}">{{tag.name}}</span>
          <h1 class="name">{{productInfo.name}}</h1>
          <span v-if="selectedSku.promotion_tag" class="promotion-tag">{{selectedSku.promotion_tag}}</span>
        </div>
        <div class="product-subtitle">{{productInfo.subtitle}}</div>
        <div class="price-area">
          <div class="info-title">价格</div>
          <div class="price">￥{{dealPrice}}</div>
          <div class="market-price" v-if="productInfo.market_price">{{productInfo.market_price.name}}:<span>{{productInfo.market_price.price_tips}}</span></div>
        </div>
        <div class="discount-area">
          <div class="coupon-area" v-if="productInfo.discount_detail && productInfo.discount_detail.coupons">
            <div class="info-title">领券</div>
            <div class="info-body">
              <div class="discount-line" v-for="item in couponList" :key="item.id">
                <div class="line-ctn">
                  <div class="discount-tag">{{item.tag}}</div>
                  <div class="discount-info">{{item.title}}</div>
                </div>
                <div class="look-up" @click="handleShowDialog('coupon')">查看</div>
              </div>
            </div>
          </div>
          <div class="activity-area" v-if="activityList.length">
            <div class="info-title">活动</div>
            <div class="info-body">
              <div class="discount-line" v-for="item in activityList" :key="item.title">
                <div class="line-ctn">
                  <div class="discount-tag">{{item.tag}}</div>
                  <div class="discount-info">{{item.title}}</div>
                </div>
                <div class="look-up" v-show="item.type === 'platform_discount' || item.type ==='platform_premiums'" @click="handleShowDialog(item.type)">查看</div>
              </div>
            </div>
          </div>
        </div>
        <div class="select-list" :key="selectItem.name" v-for="(selectItem, selectIndex) in selectList">
          <div class="select-title" :style="{'justify-content': selectItem.name==='选择规格' ? 'normal' : 'center'}">
            {{selectItem.name}}
            <div v-if="selectItem.name==='选择规格'" class="select-subtitle">mm 长*宽*高</div>
          </div>
          <div class="item-ctn">
            <a :class="{'line-item': true, active: item.active, disable: item.disable}" v-for="item in selectItem.list" :key="item.id" @click="handleItemChange(item, selectIndex, selectItem.type)">
              {{item.text}}
              <div v-show="item.active">
                <div class="triangle-area"></div>
                <i class="wzj-pc-xuanzhong"></i>
              </div>
            </a>
          </div>
        </div>
        <div class="select-list">
          <div class="select-title" style="">
            选择数量
          </div>
          <div class="item-ctn">
            <input-number-button :onChange="onNumberChange" :max="Number(selectedSku.stock)" v-model="buyNum"></input-number-button>
          </div>
        </div>
        <!-- 加购操作 -->
        <div class="operate-btn">
          <div :class="{disable: productInfo.state === 0, 'cart-btn': true}" @click="handleCartClick"><i class="wzj-icon-gouwuche"></i>加入购物车</div>
          <add-collect-btn :horizontal="false" :like="productInfo.like" @handleCollectClick="handleCollectClick"></add-collect-btn>
        </div>
      </div>
    </div>
    <div class="swiper-area">
      <div class="swiper-title-line">
        <div class="title">
          为你推荐
          <div class="subtitle"></div>
        </div>
        <div class="page">
          <div class="current-page">{{recommendPage + 1}}</div>/{{recommendSumPage}}
        </div>
      </div>
      <div class="swiper-main">
        <wzj-swiper v-model="recommendPage" :stepWidth="stepWidth" :arrow="true" :num="recommendSumPage">
          <wzj-swiper-item class="swiper-item" :width="stepWidth" v-for="(items, index) in recommendList" :key="items.id" v-if="index % recommendGroup === 0">
            <product-item size="small" class="swiper-product-item" v-for="item in recommendList.slice(index, index + recommendGroup)" :key="item.id" :product="item"/>
          </wzj-swiper-item>
        </wzj-swiper>
      </div>
    </div>
    <div class="swiper-area" v-if="homerList.length > 0">
      <div class="swiper-title-line">
        <div class="title">
          生活家体验
          <div class="subtitle">为您推荐有上述商品的生活家</div>
        </div>
        <nuxt-link :to="`/relevance/${id}`" class="see-more" target="_blank">
          查看更多<i class="wzj-pc-arrow-right"></i>
        </nuxt-link>
      </div>
      <div class="swiper-main">
        <wzj-swiper v-model="homerPage" :stepWidth="stepWidth" :arrow="true" :num="homerSumPage">
          <wzj-swiper-item class="swiper-item" :width="stepWidth" v-for="(items, index) in homerList" :key="items.id" v-if="index % homerGroup === 0">
            <div class="swiper-homer-item" v-for="item in homerList.slice(index, index + homerGroup)" :key="item.id">
              <homer-item
                :id="item.id"
                :cover="item.cover_url || item.cover[0].url"
                :images="item.images"
                :description="item.description"
                :icon="item.icon"
                :nickname="item.nickname"
                :newest="item.is_new === 'true'"
                :distanceTips="item.distance_tips"
                :hitsTips="item.hits_tips"
                :showMore="false"
                :homerStyle="item.style"
                :homerCity="item.city"
              />
            </div>
          </wzj-swiper-item>
        </wzj-swiper>
      </div>
    </div>
    <div class="product-detail">
      <div class="product-detail-left">
        <div class="brand">
          <div class="logo-line">
            <nuxt-link :to="`/brands/${productInfo.brand_id}`" target="_blank">
              <img class="logo" :src="productInfo.brand_logo | imageFormat" :alt="brandImageAlt"/>
            </nuxt-link>
          </div>
          <div class="brand-name">{{productInfo.brand}}</div>
          <div class="border-split"></div>
          <div class="brand-num">
            {{productInfo.brand_product_count}}件商品
          </div>
        </div>
        <div class="history" v-if="browserHistory.length > 0">
          <div class="history-head">
            <div class="mark-line"></div>
            <span class="title">我的足迹</span>
          </div>
          <product-item class="product-item" v-for="item in browserHistory" :product="item" :key="item.id" :subtitle="true"/>
        </div>
      </div>
      <div class="product-detail-right">
        <div class="tab">
          <div :class="{'tab-item': true, active: activeTab === 0}" @click="activeTab = 0">商品详情</div>
          <div :class="{'tab-item': true, active: activeTab === 1}" @click="activeTab = 1">用户评价<span class="num">({{productInfo.product_review_count}})</span></div>
        </div>
        <div v-show="activeTab === 0" class="tab-main">
          <div class="desc-list">
            <div class="list-item" v-for="item in productInfo.information" :key="item.key">
                <i class="grey-dot"></i>
                <span class="list-item-content">{{`${item.key}：${item.value}`}}</span>
              </div>
          </div>
          <div class="detail-list" v-for="(detail, index) in productInfo.details" :key="index">
            <div class="detail-content" v-if="detail.content">
              <div v-html="detail.content"></div>
            </div>
            <div class="detail-img" v-for="item in detail.images" :key="item.id">
              <img :src="item.url | imageFormat" :alt="detailImageAlt(item.url)"/>
            </div>
          </div>
        </div>
        <div v-show="activeTab === 1" class="tab-main">
          <div class="comment-list">
            <comment-item
              class="comment-item"
              v-for="item in commentList"
              :key="item.id"
              :data="item"
              :commentAvatarAlt="`${getCommentAvatarAlt(item)}`"
            />
            <div class="has-more-comment" v-if="commentHasMore && productInfo.product_review_count > 0" @click="getComment">
              点击查看更多
            </div>
            <div v-if="productInfo.product_review_count === 0" class="no-comment">
              <img :src="noComment | imageFormat" alt="我在家">
              <div class="text">暂时没有评论</div>
            </div>
          </div>
        </div>
        <div class="shopping-tip">
          <div class="title">
            <div class="subtitle"></div>
          </div>
          <div class="shopping-tip-main">
            <div class="tip-item">
              <i class="grey-dot"></i>
              <div class="tip-item-title">色差：</div>
              <div class="tip-item-info">所有产品均为实物拍摄。由于灯光、拍摄环境和显示器等影响，可能造成图片与实际产品之间存在色差，请以实物为准。</div>
            </div>
            <div class="tip-item">
              <i class="grey-dot"></i>
              <div class="tip-item-title">交货：</div>
              <div class="tip-item-info">高端家具存在一定的生产周期，不同商品的生产周期有所不同。商品信息中包含具体商品的交货期，一般在30~60天，详询客服。</div>
            </div>
            <div class="tip-item">
              <i class="grey-dot"></i>
              <div class="tip-item-title">配送：</div>
              <div class="tip-item-info">所有产品均提供送货入户安装服务。电梯送货或三楼以下（含三楼）可免费搬运；超过三楼则产生抬楼费用，具体费用可询客服。</div>
            </div>
            <div class="tip-item">
              <i class="grey-dot"></i>
              <div class="tip-item-title">售后：</div>
              <div class="tip-item-info">所有产品均享有3年质保服务。非定制产品可享受7天无理由退换货，其中产生的来回物流、短途搬运及二次包装费用需要用户自己承担。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <no-ssr>
      <wzj-dialog class="dialog" :visible.sync="visible" :title="dialogTitle" :width="dialogWidth" :showHeader="dialogShowHeader">
        <template v-if="dialogType === 'coupon'">
          <div class="dialog-coupon-item" v-for="item in productInfo.discount_detail.coupons.coupons" :key="item.id">
            <div class="discount">
              ￥{{item.price}}
              <div class="condition">{{item.condition_tip}}</div>
            </div>
            <div class="limit">{{ `${item.brand_limit}(${item.stackable_tip})` }}</div>
            <div class="date">使用日期： {{item.start_time | dateFormat}}-{{item.end_time | dateFormat}}</div>
          </div>
        </template>
        <template v-else-if="dialogType === 'discount'">
          <div class="dialog-discount" v-for="item in discountList" :key="item.title">
            {{item.title}}
          </div>
          <div class="dialog-btn">
            <div class="btn" @click="visible = false">确定</div>
          </div>
        </template>
        <template v-else-if="dialogType === 'gift'">
          <div class="dialog-tips">订单满足条件后，赠品会直接添加到订单中，无需其他操作</div>
          <div class="dialog-gift-item" v-for="item in productInfo.discount_detail.platform_premiums.products" :key="item.id">
            <div class="img-area">
              <img class="lazyload" :data-src="item.image_url | imageFormat(200)" alt="我在家" />
              <div class="img-tag">{{item.icon_tag}}</div>
            </div>
            <div class="text-area">
              <div class="name">
                <div class="gift-tag">赠品</div>
                {{item.title}}
              </div>
              <div class="desc">
                {{`${item.dimensions}；${item.material}；${item.color}`}}
              </div>
              <div class="price-area">
                <div class="price">
                  ￥0
                  <div class="old-price">￥{{item.price}}</div>
                </div>
                <div class="num">x1</div>
              </div>
            </div>
          </div>
        </template>
      </wzj-dialog>
    </no-ssr>
    <div v-if="fromRobot">
      <comment-item
        class="comment-item"
        v-for="item in commentListSeo"
        :key="item.id"
        :data="item"
        :isFromRobot="true"
        :commentAvatarAlt="`${getCommentAvatarAlt(item)}`"
      />
    </div>
   <div class="none">
     <a v-for="linkInfo in seoLinks" :href="linkInfo.link">{{linkInfo.text}}</a><br />
   </div>
 </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLocalStorage, getSessionStorage, setLocalStorage, fromRobot, isFurniture, styleCategorySeoLinks } from '@/assets/lib/tool'
import ProductGallery from '@/components/Common/ProductGallery'
import InputNumberButton from '@/components/Common/InputNumberButton'
import ProductItem from '@/components/Common/ProductItem'
import HomerItem from '@/components/Common/HomerItem'
import CommentItem from '@/components/Common/CommentItem'
import AddCollectBtn from '@/components/Common/AddCollectBtn'
import noComment from '@/assets/image/no_comment.png'
import qrCodeBg from '@/assets/image/qrCodeBg.png'

const RECOMMEND_GROUP = 6 // 多少一组
const HOMER_GROUP = 4
const MIN_WIDTH = 990
const MAX_WIDTH = 1190
const indexTable = {
  0: 'dimensions',
  1: 'material',
  2: 'color',
}

export default {
  name: 'Product',
  components: {
    ProductGallery,
    InputNumberButton,
    ProductItem,
    HomerItem,
    CommentItem,
    AddCollectBtn,
  },
  async asyncData({ axios, params, error, userAgent }) {
    const { id } = params
    if (!/^\d+$/.test(id)) {
      return error({ statusCode: 404, message: 'Not found' })
    }
    const data = {
      id,
      fromRobot: fromRobot(userAgent),
      commentListSeo: [],
    }
    const [productInfo, productAttr] = await Promise.all([
      axios.get(`/proxy/products/${id}`),
      axios.get(`/proxy/products/${id}/attributes`),
    ])
    if (productInfo && productAttr) {
      data.productInfo = productInfo.results[0]
      data.mainImg = data.productInfo.images[0] ? data.productInfo.images[0].url : ''
      data.productAttr = productAttr.results[0].attributes
    } else {
      return error({ statusCode: 404, message: 'Not found' })
    }
    if (data.fromRobot) {
      const commentLimit = 60
      const commentList = await axios.get(`/proxy/products/${id}/reviews?limit=${commentLimit}&offset=0`)
      if (commentList) {
        data.commentListSeo = commentList.results.filter(c => c.content && c.content.trim() && !c.content.includes('默默滴给个好评'))
      }
    }
    return data
  },
  data() {
    return {
      id: '',
      breadList: [],
      selectList: [],
      price: '-',
      couponList: [],
      activityList: [],
      discountList: [],
      buyNum: 1,
      recommendGroup: RECOMMEND_GROUP,
      recommendPage: 0,
      homerGroup: HOMER_GROUP,
      homerPage: 0,
      stepWidth: 1190,
      productInfo: {},
      browserHistory: [],
      activeTab: 0,
      loadingHomerList: false,
      loadingComment: false,
      noComment,
      visible: false,
      dialogTitle: '',
      dialogType: '',
      dialogWidth: 520,
      dialogShowHeader: true,
      qrCodeBg,
      commentList: [],
      commentHasMore: true,
      commentLimit: 5,
      commentOffset: 0,
      homerList: [],
      homerHasMore: true,
      homerLimit: 12,
      homerOffset: 0,
      collectHover: false,
    }
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
      screenWidth: state => state.common.screenWidth,
      loginDialogVisible: state => state.common.loginDialogVisible,
    }),
    selectIdList() {
      const array = new Array(3).fill('')
      if (this.selectList.length === 0) return array
      return array.map((idItem, index) => {
        const list = this.selectList[index].list
        const targetItem = list.find(item => item.active === true)
        return targetItem ? targetItem.id : ''
      })
    },
    selectedSku() {
      const { productAttr } = this
      const allSelected = this.selectIdList.every(item => Boolean(item))
      let res = {}
      if (allSelected) {
        res = productAttr.find(item => {
          return item.dimensions.id === this.selectIdList[0] && item.material.id === this.selectIdList[1] && item.color.id === this.selectIdList[2]
        })
        this.mainImg = res.icon
      }
      return res
    },
    recommendList() {
      const list = this.productInfo.modules.filter(item => {
        return item.template === 'products_hot_recommend'
      })
      if (list.length === 0) return []
      return list[0].products
    },
    recommendSumPage() {
      const { recommendList = [], recommendGroup } = this
      return Math.ceil(recommendList.length / recommendGroup)
    },
    // 控制是否展示右滑标志
    homerSumPage() {
      const { homerList = [], homerGroup } = this
      return Math.ceil(homerList.length / homerGroup)
    },
    dealPrice() {
      const { deal_of_the_day, weekly_recommend } = this.productInfo
      const { id, price } = this.selectedSku
      const discountList = deal_of_the_day || weekly_recommend || []
      let product = discountList[0] || {}
      if (id && discountList[0]) {
        product = discountList.find(item => item.sku_id === id) || {}
      }
      return product.discount_price || price || this.price
    },
    seoLinks() {
      const { name, style } = this.productInfo
      if (isFurniture(name)) {
        return styleCategorySeoLinks(style)
      }
      return []
    },
    brandImageAlt() {
      const { name, brand, style } = this.productInfo
      if (isFurniture(name)) {
        return `${brand}系列 ${style}风格家具`
      }
      return brand
    },
    galleryAlt() {
      const { name, brand, style } = this.productInfo
      if (isFurniture(name)) {
        return `${style}风格${name}`
      }
      return `${brand}系列${name}`
    },
  },
  watch: {
    scrollTop(val) {
      const isEndReached = window.innerHeight + val + 200 >= document.body.offsetHeight
      // 距底部200px时加载更多
      if (isEndReached && this.commentHasMore && this.activeTab === 1) {
        this.getComment()
      }
    },
    screenWidth(val) {
      if (val >= MIN_WIDTH && val <= MAX_WIDTH) {
        this.stepWidth = val
      } else if (val < MIN_WIDTH) {
        this.stepWidth = MIN_WIDTH
      } else if (val > MAX_WIDTH) {
        this.stepWidth = MAX_WIDTH
      }
    },
    homerPage(val) {
      const { homerHasMore, homerSumPage } = this
      // 倒数第二页加载后面的生活家
      if (homerHasMore && val > homerSumPage - 3) {
        this.getHomerList()
      }
    },
  },
  head() {
    const { title, keywords, description } = this.getPageInfo()
    return {
      title,
      titleTemplate: '%s 「我在家」一站式高品质新零售家居品牌',
      meta: [
        { hid: 'keywords', name: 'keywords', content: keywords },
        { hid: 'description', name: 'description', content: description },
      ],
    }
  },
  mounted() {
    this.getData()
    this.setBreadcrumb()
    this.setPrice()
    this.setSelectList()
    this.setDiscountList()
  },
  methods: {
    async getData() {
      const { commentLimit, commentOffset, homerLimit, homerOffset, id } = this
      const arr = getLocalStorage('browseHistory') || []
      const browserIdList = arr.filter(item => item && item !== id)
      this.setBrowseHistory()
      const [comment, homer, history] = await Promise.all([
        this.$http.get(`/proxy/products/${id}/reviews?limit=${commentLimit}&offset=${commentOffset}`),
        this.$http.get(`/proxy/homers?limit=${homerLimit}&offset=${homerOffset}&product_id=${id}`),
        this.$http.get(`/proxy/search/products?product_id=${browserIdList.join(',')}`),
      ])
      if (comment && homer && history) {
        this.commentList = comment.results
        this.commentHasMore = comment.has_more
        this.homerList = homer.results
        this.homerHasMore = homer.has_more
        if (browserIdList.length === 0) return
        this.browserHistory = history.results.map(item => ({
          id: item.id,
          attribute_infos: item.attribute_infos,
          tags: item.tags,
          image_url: item.image_url,
          name: item.name,
          min_price: item.min_price,
          max_price: item.max_price,
          subtitle: item.subtitle,
        }))
      }
    },
    setBreadcrumb() {
      const list = getSessionStorage('breadcrumb')
      let breadList = []
      if (list) {
        breadList = breadList.concat(list)
      } else {
        breadList.push({ name: '首页', to: '' })
      }
      breadList.push({ name: this.productInfo.name })
      this.breadList = breadList
    },
    setBrowseHistory() {
      const { id } = this
      const arr = getLocalStorage('browseHistory') || []
      const index = arr.findIndex(item => item === id)
      if (index !== -1) {
        // 如果浏览历史中已有当前商品，提取到最前
        arr.unshift(arr.splice(index, 1)[0])
        setLocalStorage('browseHistory', arr)
        return
      }
      arr.unshift(id)
      setLocalStorage('browseHistory', arr.slice(0, 8))
    },
    setPrice() {
      const { prices = [] } = this.productInfo
      this.price = this.formatPrice(prices)
    },
    formatPrice(price) {
      if (price.length === 0) return ''
      let minPrice = price[0].price
      let maxPrice = price[0].price
      price.forEach(item => {
        minPrice = item.price < minPrice ? item.price : minPrice
        maxPrice = item.price > maxPrice ? item.price : maxPrice
      })
      return minPrice === maxPrice ? minPrice : `${minPrice}-${maxPrice}`
    },
    setSelectList() {
      const { productInfo } = this
      const isExhausted = productInfo.state === 0
      const selectList = [
        { name: '选择规格', type: 'dimensions', list: [] },
        { name: '选择材质', type: 'material', list: [] },
        { name: '选择颜色', type: 'colors', list: [] },
      ]
      this.selectList = selectList.map(item => {
        let list = productInfo[item.type]
        list = list.map(infoItem => {
          return { ...infoItem, active: false, disable: isExhausted }
        })
        return { ...item, list }
      })
    },
    setDiscountList() {
      const { productInfo, couponList, activityList, discountList } = this
      productInfo.discount_list.forEach(item => {
        if (item.type === 'coupons') {
          couponList.push(item)
          return
        }
        if (item.type === 'platform_discount') {
          discountList.push(item)
        }
        activityList.push(item)
      })
    },
    async getComment() {
      const { loadingComment, commentOffset, commentLimit, id, commentList } = this
      if (loadingComment) return
      this.loadingComment = true
      const nextOffset = commentOffset + commentLimit
      const res = await this.$http.get(`/proxy/products/${id}/reviews?limit=${commentLimit}&offset=${nextOffset}`)
      if (res) {
        this.commentOffset = nextOffset
        this.commentList = commentList.concat(res.results)
        this.commentHasMore = res.has_more
      }
      this.loadingComment = false
    },
    handleGalleryChange(url) {
      this.mainImg = url
    },
    handleItemChange(item, selectIndex, type) {
      // 切换规格时路由添加标示，运营使用
      if (type === 'dimensions') {
        console.log(item)
        this.$router.replace({
          path: `${this.$route.path}#P${this.id}SP${item.origin_id || ''}`,
        })
      }

      if (item.disable) return
      const { selectList } = this
      selectList[selectIndex].list = selectList[selectIndex].list.map(detailItem => {
        if (detailItem.id === item.id) {
          if (detailItem.active) {
            return { ...detailItem, active: false }
          }
          return { ...detailItem, active: true }
        }
        return { ...detailItem, active: false }
      })
      if (selectIndex === 0) {
        this.handleDimensionChange()
      } else if (selectIndex === 1) {
        this.handleMaterialChange()
      } else {
        this.handleColorChange()
      }
    },
    handleDimensionChange() {
      // 设置材质颜色disable
      this.setDisable(1)
      this.setDisable(2)
    },
    handleMaterialChange() {
      // 清空已选择的颜色，设置规格颜色disable
      this.clearStatus(2, true)
      this.setDisable(0)
      this.setDisable(2)
    },
    handleColorChange() {
      // 如果已选择材质，只设置规格材质disable，否则增加更改材质操作：选择符合条件的第一个材质
      if (!this.selectIdList[1]) {
        // 更改材质
        const existArr = this.getExistArr()
        const materialList = this.selectList[1]
        let targetItem
        materialList.list.some(item => {
          const findIndex = existArr.findIndex(existItem => item.id === existItem[indexTable[1]].id)
          if (findIndex > -1) {
            targetItem = item
            return true
          }
        })
        targetItem.active = true
      }
      this.setDisable(0)
      this.setDisable(2)
    },
    onNumberChange(val) {
      if (!this.selectedSku.id) {
        this.$toast('请完成商品信息选择')
        this.buyNum = 1
        return
      }
      if (val > this.selectedSku.stock) {
        this.$toast('超出最大库存量')
        val = this.selectedSku.stock
      }
      this.buyNum = val
    },
    clearStatus(index, clearActive) {
      // 清除状态
      const colorList = this.selectList[index]
      colorList.list = colorList.list.map(item => {
        if (clearActive) {
          return { ...item, disable: false, active: false }
        }
        return { ...item, disable: false }
      })
    },
    setDisable(selectIndex) {
      const list = this.selectList[selectIndex]
      const existArr = this.getExistArr(selectIndex)
      list.list = list.list.map(item => {
        const index = existArr.findIndex(existItem => item.id === existItem[indexTable[selectIndex]].id)
        if (index > -1) {
          return { ...item, disable: false }
        }
        return { ...item, disable: true }
      })
    },
    getExistArr(currentIndex = -1) {
      let existArr = this.productAttr
      this.selectIdList.forEach((id, index) => {
        // 筛选已选择项，排除当前选择
        if (id && index !== currentIndex) {
          const type = indexTable[index]
          existArr = existArr.filter(item => item[type].id === id)
        }
      })
      return existArr
    },
    async getHomerList() {
      const { homerOffset, homerLimit, loadingHomerList, id, homerList } = this
      if (loadingHomerList) return
      this.loadingHomerList = true
      const nextOffset = homerOffset + homerLimit
      const res = await this.$http(`/proxy/homers?limit=${homerLimit}&offset=${nextOffset}&product_id=${id}`)
      if (res) {
        this.homerOffset = nextOffset
        this.homerHasMore = res.has_more
        this.homerList = homerList.concat(res.results)
      }
      this.loadingHomerList = false
    },
    handleShowDialog(type) {
      const typeList = [
        {
          originType: 'coupon',
          type: 'coupon',
          title: '领取优惠券',
        },
        {
          originType: 'platform_discount',
          type: 'discount',
          title: '平台满减',
        },
        {
          originType: 'platform_premiums',
          type: 'gift',
          title: '平台满赠',
        },
      ]
      const res = typeList.find(item => item.originType === type)
      if (res) {
        this.dialogWidth = 520
        this.dialogType = res.type
        this.dialogTitle = res.title
        this.dialogShowHeader = true
        this.visible = true
      }
    },
    async handleCartClick() {
      const paramRes = this.checkParam()
      if (!paramRes) return
      await this.checkLogin()
      const param = {
        cart_item_content: JSON.stringify([{
          sku_id: this.selectedSku.id,
          buy_num: this.buyNum,
        }]),
      }
      const res = await this.$http.post('/proxy/shopping-cart', param)
      if (res) {
        this.$toast('加入购物车成功')
        this.$store.dispatch('common/GET_USER_DATA')
      }
    },
    async handleCollectClick() {
      const { productInfo, id } = this
      await this.checkLogin()
      if (productInfo.like) {
        const res = await this.$http.delete(`/proxy/collection/goods/${id}`)
        if (res) {
          this.$toast('已取消收藏')
          this.productInfo.like = !productInfo.like
        }
        return
      }
      const res = await this.$http.post('/proxy/collection/goods', { id: this.id })
      if (res) {
        this.$toast('已收藏成功')
        this.productInfo.like = !productInfo.like
      }
    },
    checkParam() {
      const { selectedSku } = this
      if (!selectedSku.id) {
        if (!this.selectIdList[0]) {
          this.$toast('请选择商品规格')
        } else if (!this.selectIdList[1]) {
          this.$toast('请选择商品材质')
        } else {
          this.$toast('请选择商品颜色')
        }
        return false
      }
      return true
    },
    getPageInfo() {
      const { name, subtitle, style, main_materials = '', information = [], brand } = this.productInfo
      if (!isFurniture(name)) {
        return {
          title: `${brand}-${name}`,
          keywords: `家居,家具,${name},${brand}系列,我在家`,
          description: `${name}，${subtitle}，${brand}系列`,
        }
      }
      let materials = main_materials
      const element = information.find(function(element) {
        return (element.key === '面料' && /牛皮|真皮/.test(element.value)) ||
            (element.key === '木质材质' && element.value !== '其他')
      })
      if (element) {
        materials = element.value
      }
      const keywords = `家具,家居,${name},${materials}家具,${style}风格家具,${brand}系列,我在家`
      let description = `${name}，${subtitle}`
      if (materials && materials !== '其他') {
        description += `，${materials}材质`
      }
      if (style) {
        description += `，${style}风格`
      }
      if (description.length < 100) {
        description += `，${brand}系列`
      }
      const title = `${style}风格-${name}`
      return { title, keywords, description }
    },
    getCommentAvatarAlt(comment) {
      const { nickname } = comment
      const { name, brand, style } = this.productInfo
      if (isFurniture(name)) {
        return `购买了${style}风格${name}的${nickname}`
      }
      return `购买了${brand}系列${name}的${nickname}`
    },
    detailImageAlt(url) {
      const { name, brand, style, details_images } = this.productInfo
      if (this.fromRobot) {
        const image = details_images.find(i => i.url === url)
        if (image && image.source) {
          return image.source.replace(/\n/g, ' ')
        }
      }
      if (isFurniture(name)) {
        return `${style}风格${name}的家具详细介绍`
      }
      return `${brand}系列${name}的详细介绍`
    },
  },
}

</script>
<style lang='scss' scoped>
$indent: 20px;
.header {
  width: 100%;
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
}

.product-info{
  width: 100%;
  display: flex;
  .img-gallery{
    position: relative;
    width: 49.16%;
    min-width: 49.49%;
    margin-right: 40px;
  }
  @media (max-width: 1000px) {
    .img-gallery {
      margin-right: 20px;
    }
  }
  .info-text{
    width: auto;
    min-width: 480px;
    flex-grow: 1;
  }
  .product-name{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
    .tag{
      font-size: 16px;
      padding: 2px 6px;
      border-radius: 14px;
      margin-right: 10px;
    }
    .name{
      color: $_text_dark;
      font-size: 28px;
      font-weight: 500;
    }
    .promotion-tag {
      margin-left: 20px;
      font-size: 12px;
      padding: 1px 4px;
      border: 1px solid #ff5541;
      color: #ff5541;
    }
  }
  .product-subtitle{
    color: $c_dark;
    font-size: 14px;
    margin-bottom: 18px;
  }
  .price-area{
    background: #FFFAFA;
    height: 74px;
    padding-left: $indent;
    display: flex;
    align-items: center;
    white-space: nowrap;
    .price{
      font-size: 28px;
      color: $c_highlight;
      font-weight:bold;
      margin-right: 10px;
    }
    .market-price{
      font-size: 13px;
      color: $_text_dark;
      span{
        text-decoration: line-through;
      }
    }
  }
  .info-title{
    font-size: 13px;
    color: $_gray;
    width: 93px;
    min-width: 93px;
    white-space: nowrap;
  }
  .info-body{
    color: $_text_dark;
  }
  .discount-area{
    font-size: 13px;
    background: #FFF6F5;
    padding-left: $indent;
    margin-bottom: 20px;
    padding-bottom: 20px;
    .discount-tag{
      border: 1px solid $c_highlight;
      color: $c_highlight;
      font-size: 12px;
      padding: 0 4px;
      border-radius: 20px;
      margin-right: 4px;
      white-space: nowrap;
    }
  }
  .coupon-area{
    padding-top: 20px;
    display: flex;
    align-items: center;
  }
  .activity-area{
    display: flex;
    padding-top: 20px;
  }
  .discount-line{
    display: flex;
    margin-bottom: 10px;
    &:last-child{
      margin-bottom: 0;
    }
    .line-ctn{
      display: flex;
      margin-right: 20px;
      max-width: 380px;
    }
    .discount-info{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .look-up{
      cursor: pointer;
      color: $c_highlight;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
}
.select-list{
  font-size: 13px;
  display: flex;
  margin: 10px 0;
  position: relative;
  padding-left: $indent;
  .select-title{
    min-width: 75px;
    width: 75px;
    height: 34px;
    margin-right: 20px;
    color: #4F5156;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .select-subtitle{
    font-size: 12px;
    color: #92969C;
  }
  .line-item{
    position: relative;
    display: inline-block;
    color: $_text_dark;
    padding: 0 6px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    // user-select: none;
    border: 1px solid $_text_e6;
    white-space: nowrap;
    font-size: 12px;
    &:hover{
      border-color: $c_highlight;
    }
    &.active{
      color: $c_highlight;
      background: #FFEEEC;
      border-color: $c_highlight;
    }
    &.disable{
      border: 1px dashed #ccc;
      color: #999;
    }
    &:last-of-type{
      margin-right: 0;
    }
    .triangle-area{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 0;
      height: 0;
      border: 7.5px solid transparent;
      border-right-color: $c_highlight;
      border-bottom-color:  $c_highlight;
    }
    .wzj-pc-xuanzhong{
      position: absolute;
      right: -3px;
      bottom: -3px;
      z-index: 2;
      font-size: 12px;
      transform: scale(0.45);
      color: #fff;
      height: 15px;
      line-height: 15px;
    }
  }
}
.operate-btn{
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: $indent;
  .cart-btn{
    height: 48px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    background: $c_highlight;
    width: 188px;
    color: #fff;
    margin-right: 10px;
    &.disable{
      background: #92969C;
    }
    // &.grey{
    //   color: rgba($color: #fff, $alpha: .5)
    // }
    i{
      font-size: 16px;
      margin-right: 6px;
    }
  }

}
.swiper-area{
  margin-bottom: 20px;
}
.swiper-title-line{
  display: flex;
  margin-bottom: 20px;
  font-size: 20px;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  .title{
    color: $_text_dark;
    .subtitle{
      display: inline-block;
      color: $c_dark;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .page{
    font-size: 14px;
    color: $c_dark;
    .current-page{
      font-size: 20px;
      display: inline-block;
      color: $_text_dark;
    }
  }
  .see-more{
    display: flex;
    align-items: center;
    color: $c_dark;
    font-size: 14px;
    cursor: pointer;
    i{
      display: inline-block;
      margin-left: 4px;
      font-size: 12px;
      transform: scale(.8);
    }
  }
}
.swiper-item{
  display: flex;
  padding: 0 2px 10px 0;
}
.swiper-product-item{
  width: calc(16.66% - 8px);
  margin-right: 10px;
  &:nth-of-type(6n) {
    margin-right: 0;
  }
}
.swiper-homer-item{
  width: calc(25% - 7px);
  margin-right: 10px;
  float: left;
  &:nth-of-type(4n) {
    margin-right: 0;
  }
}
.product-detail{
  display: flex;
  .product-detail-left{
    width: 290px;
    min-width: 290px;
    margin-right: 20px;
  }
  .product-detail-right{
    flex-grow: 1;
  }
}
.brand{
  height: 237px;
  padding: 30px 0;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid $_text_e6;
  .logo-line{
    margin-bottom: 20px;
    height: 60px;
    .logo{
      width: 60px;
      height: 60px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .brand-name{
    color: $_text_dark;
    font-size: 16px;
    font-weight:500;
  }
  .border-split{
    height: 2px;
    width: 20px;
    background: $_text_dark;
    margin: 8px auto;
  }
  .brand-num{
    color: $_text_dark;
    font-weight: 500;
    font-size: 14px;
  }
}
.history{
  border: 1px solid $_text_e6;
  margin-bottom: 30px;
  padding-bottom: 20px;
  .product-item{
    margin: 0 20px;
    border: none;
    margin-bottom: 20px;
    &:last-child{
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
.history-head{
  height: 20px;
  line-height: 20px;
  color: $_text_dark;
  font-size: 16px;
  position: relative;
  margin: 20px 0;
  .mark-line{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 66.4%;
    background: $_text_e6;
    z-index: 1;
  }
  .title{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    background: #fff;
  }
}
.desc-list{
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $_text_e6;
  font-size: 13px;
  margin-bottom: 20px;
  .list-item{
    display: flex;
    color: $_text_dark;
    width: 25%;
    height: 17px;
    margin-bottom: 20px;
    align-items: center;
    padding-right: 10px;
    .list-item-content{
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.detail-list{
  padding: 0 20px;
  & > .detail-content{
    font-size: 14px;
    line-height: 20px;
  }
  & > .detail-img{
    font-size: 0;
    img{
      width: 100%;
    }
  }
}
.tab-main{
  border: 1px solid $_text_e6;
  border-top: 0;
  margin-bottom: 36px;
}
.tab{
  height: 40px;
  display: flex;
  font-size: 13px;
  line-height: 38px;
  border-bottom: 2px solid $c_highlight;
  .tab-item{
    color: $c_highlight;
    border: 2px solid $c_highlight;
    border-bottom: 0;
    padding: 0 20px;
    cursor: pointer;
    font-weight: 600;
    &.active{
      color: #fff;
      background: $c_highlight;
    }
    .num{
      font-size: 14px;
    }
  }
}
.shopping-tip{
  .title{
    margin-bottom: 10px;
    color: $_text_dark;
    font-size: 20px;
    .subtitle{
      margin-left: 10px;
      color: $c_dark;
      font-size: 14px;
    }
  }
}
.grey-dot{
  width: 4px;
  min-width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $c_dark;
  margin-right: 6px;
}
.shopping-tip-main{
  border: 1px solid $_text_e6;
  padding: 30px 20px;
  font-size: 13px;
  margin-bottom: 40px;
  .tip-item{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child{
      margin-bottom: 0;
    }
    .tip-item-title{
      color: $_text_dark;
    }
    .tip-item-info{
      color: $c_dark;
    }
  }
}
.comment-list{
  padding-top: 20px;
  .comment-item{
    margin-top: 20px;
    border-bottom: 1px solid $_text_e6;
    &:first-child{
      margin-top: 0;
    }
  }
  .has-more-comment {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size:14px;
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(80,125,175,1);
    cursor: pointer;
  }
}
.no-comment{
  margin: 130px 0;
  text-align: center;
  color: #2c3038;
  .text{
    margin-top: 15px;
    font-size: 16px;
  }
}
.dialog-coupon-item{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 20px 0;
  width: calc(100% - 40px);
  border-bottom: 1px solid $_text_e6;
  .discount{
    color: $c_highlight;
    font-size: 24px;
    margin-bottom: 8px;
    .condition{
      display: inline-block;
      color: $c_dark;
      font-size: 12px;
    }
  }
  .limit{
    font-size: 14px;
    color: $_text_dark;
    margin-bottom: 8px;
  }
  .date{
    font-size: 12px;
    color: $c_dark;
  }
}
.dialog-discount{
  color: $_gray;
  font-size: 14px;
  padding: 20px;
  border: 1px solid $_text_e6;
}
.dialog-btn{
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  .btn{
    width: 60px;
    height: 36px;
    line-height: 36px;
    background: $c_highlight;
    color: #fff;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
  }
}
.dialog-tips{
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  background: #ffeeec;
  color: $c_highlight;
  font-size: 12px;
  margin-bottom: 20px;
}
.dialog-gift-item{
  display: flex;
  height: 138px;
  margin-bottom: 20px;
  padding: 0 20px;
  .img-area{
    width: 120px;
    margin-right: 10px;
    position: relative;
    img{
      width: 120px;
      height: 120px;
    }
    .img-tag{
      position: absolute;
      bottom: 20px;
      left: 0;
      z-index: 1;
      height: 24px;
      line-height: 24px;
      width: 100%;
      text-align: center;
      background: rgba(0,0,0,.5);
      color: #fff;
      font-size: 12px;
    }
  }
  .text-area{
    height: 140px;
    padding-bottom: 20px;
    border-bottom: 1px dashed $_text_e6;
    flex-grow: 1;
    .name{
      color: $_text_dark;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      .gift-tag{
        background-color: $c_highlight;
        border-radius: 7.5px;
        padding: 0 4px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        margin-right: 3px;
      }
    }
    .desc{
      color: $c_dark;
      font-size: 12px;
    }
    .price-area{
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      .price{
        display: flex;
        align-items: center;
        color: $c_highlight;
        font-size: 18px;
        font-weight: 600;
      }
      .old-price{
        margin-left: 4px;
        color: $c_dark;
        font-size: 12px;
        text-decoration: line-through;
      }
      .num{
        color: $_text_dark;
        font-size: 12px;
      }
    }
  }
}
.see-in-app{
  position: relative;
  img{
    width: 100%;
  }
  .qrcode{
    position: absolute;
    left: 54px;
    top: 244px;
    z-index: 1;
  }
}
</style>
