<template>
  <div class="comment-item">
    <div class="avatar-area">
      <img :data-src="(data.icon || data.icon_url) | imageFormat(100)" :alt="commentAvatarAlt"  class="lazyload">
    </div>
    <div class="main-area">
      <div class="head">
        <div class="head-info">
          <div class="nickname">
            {{data.nickname || data.name}}
            <nuxt-link v-if="data.user_type === 'homer'" :to="`/homers/${data.homer_id}`" target="_blank">
              <img class="zone-img" :src="homerIcon" alt="生活家">
            </nuxt-link>
          </div>
          <div class="time">{{ (data.created_at || data.time) | dateFormat }}</div>
        </div>
        <div class="star" v-if="data.score">
          <i v-for="count in data.score" class="wzj-pc-star" :key="count + data.id" />
          <template v-if="data.score < 5">
            <i v-for="count in (5 - data.score)" class="default wzj-pc-star" :key="count" />
          </template>
        </div>
      </div>
      <div class="body">
        <p class="detail" v-if="data.content || data.comment">{{ data.content ||  data.comment }}</p>
        <div class="thumbs" v-if="data.images && data.images.length">
          <div 
            v-for="(image, index) in data.images"
            :key="index"
            class="image-box"
            @click.stop="showPhotoSwiper(data.images, index)"
          >
            <img class="lazyload" :data-src="image.url | imageFormat(200)" :alt="`${data.specification || (data.nickname || data.name) + '买家实拍'}`">
          </div>
        </div>
        <p class="spec">{{ data.specification }}</p>
        <p class="reply" v-if="data.reply">
          <span v-if="homer">我<i>[主人]</i>回复{{data.name}}：</span>
          <span v-else>平台回复：</span>
          <span>{{ data.reply }}</span>
        </p>
        <!--追评-->
        <template v-if="data.additional_reviews && data.additional_reviews.length">
          <div v-for="review in data.additional_reviews" :key="review.id" class="additional-reviews">
            <p class="detail" v-if="review.content">
              <span class="time" v-if="review.created_at">用户追评（{{review.created_at | dateFormat}}）</span>
              {{ review.content }}
            </p>
            <div class="thumbs" v-if="review.images && review.images.length">
              <div 
                v-for="(image, index) in review.images"
                :key="index"
                class="image-box"
                @click.stop="showPhotoSwiper(review.images, index)"
              >
                <img class="lazyload" :data-src="image.url | imageFormat(200)" :alt="data.specification">
              </div>
            </div>
            <p class="reply" v-if="review.reply">
              平台回复: <span>{{ review.reply }}</span>
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import homerIcon from '@/assets/image/homerIcon.png'
export default {
  name: 'CommentItem',
  props: {
    data: Object,
    homer: {
      type: Boolean,
      default: false,
    },
    isFromRobot: {
      type: Boolean,
      default: false,
    },
    commentAvatarAlt: {
      type: String,
      default: '我在家',
    },
  },
  data() {
    return {
      homerIcon,
    }
  },
  methods: {
    showPhotoSwiper(items, index) {
      this.$preview({ items, index })
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.comment-item{
  display: flex;
  .avatar-area{
    width: 80px;
    min-width: 80px;
    text-align: center;
    img{
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }
  .main-area{
    flex-grow: 1;
  }
}
.head{
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .head-info{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    .nickname{
      display: flex;
      align-items: center;
      color: $_text_dark;
      font-size: 16px;
      margin-bottom: 4px;
      img{
        cursor: pointer;
      }
    }
    .time{
      color: $c_dark;
      font-size: 12px;
    }
  }
}
.star{
  margin-right: 20px;
  color: $c_highlight;
  i{
    font-size: 12px;
    margin-right: 5px;
    transform: scale(.83);
    display: inline-block;
    &:last-child{
      margin-right: 0;
    }
    &.default{
      color: $_text_e6;
    }
  }
}
.body{
  margin-top: 5px;
  padding-bottom: 18px;
  font-size: 14px;
  color: $_gray;
  .detail{
    margin-bottom: 10px;
    padding-right: 20px;
  }
}
.thumbs{
  display: flex;
  .image-box{
    position: relative;
    width: 90px;
    height: 90px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;
    img{
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
      min-width: 100%;
      height: 100%;
    }
  }
}
.spec{
  color: $c_dark;
  font-size: 12px;
}
.reply{
  margin-top: 10px;
  color: $_gray;
  background-color: $_bg_f6;
  font-size: 14px;
  padding: 15px 10px;
  @include equilateral_triangle(10px, $_bg_f6, -5px, 10px);

  i {
    color: $c_highlight;
    font-style: normal;
  }
}
.additional-reviews{
  .deatil{
    margin-top: 20px;
    padding-top: 20px;
    border: 1px dashed $_text_e6;
  }
  .time{
    color: $c_highlight;
  }
}
.zone-img{
  width: 52px;
  height: 16px;
  margin-left: 4px;
}
</style>
