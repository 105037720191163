<template>
  <div class="input-number-button">
    <div @click.stop="handleMinus" class="btn" :class="{'disable': minusDisable || disabled}">-</div>
    <input-number ref="input" class="input" :onChange="onChange" :min="min" :max="max" :value="value" :disabled="disabled" @input="handleInput" />
    <div @click.stop="handleAdd" class="btn" :class="{'disable': addDisable || disabled}">+</div>
  </div>
</template>

<script>
  /**
   * input-number
   * @description 带加减按钮的数字输入框
   *
   * @param {number} v-model="value" - 数字值
   * @param {number} max - 最大值
   * @param {number} min - 最小值
   *
   * @example
   * <input-number :min=1 :max=5 v-model="number" />
   */
  import InputNumber from '@/components/Common/InputNumber'

  export default {
    components: {
      InputNumber,
    },
    props: {
      value: [String, Number],
      max: Number,
      min: {
        type: Number,
        default: 1,
      },
      onChange: Function,
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      addDisable() {
        return this.max !== undefined && this.value >= this.max
      },
      minusDisable() {
        return this.min !== undefined && this.value <= this.min
      },
    },
    methods: {
      handleMinus() {
        if (this.minusDisable || this.disabled) return
        const value = this.value - 1
        this.$emit('input', value)
        if (typeof this.onChange === 'function') this.onChange(value)
      },
      handleAdd() {
        if (this.addDisable || this.disabled) return
        const value = this.value + 1
        this.$emit('input', value)
        if (typeof this.onChange === 'function') this.onChange(value)
      },
      handleInput(value) {
        this.$emit('input', value)
        if (typeof this.onChange === 'function') this.onChange(value)
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.input-number-button {
   display: flex;
   flex-direction: row;
   align-items: center;

   .input {
     height: 34px;
     width: 66px;
     margin: 0 4px;
     border: 1px solid #E6E6E6;
   }
   >.btn {
    height: 34px;
    width: 34px;
    line-height: 30px;
    text-align: center;
    font-size: 24px;
    cursor: pointer;
    border: 1px solid $_text_e6;
    &:hover{
      color: $c_highlight;
    }
    &.disable {
      cursor: default;
      color: $_disable
    }
   }
}
</style>
