<template>
  <div class='go-top-container' v-if='isShow' @click="goTop">
    <i class='wzj-pc-top'></i>
    <p>回到顶部</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'GoTop',
  data() {
    return {
      isShow: false,
    }
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
    }),
  },
  watch: {
    scrollTop(val) {
      if (val > window.innerHeight) {
        this.isShow = true
        return
      }
      this.isShow = false
    },
  },
  methods: {
    goTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0)
      })
    },
  },
}

</script>
<style lang='scss' scoped>
  .go-top-container {
    position: fixed;
    right: 100px;
    bottom: 100px;
    z-index: 50;
    text-align: center;
    line-height: 18px;
    color: #92969c;
    border: 1px solid #e6e6e6;
    background: #fff;
    font-size: 12px;
    padding: 12px 3px 10px;
    cursor: pointer;
    &:hover {
      color: #ff5541;
    }
  }
</style>