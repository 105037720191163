<template>
  <nuxt-link :to="`/items/${productData.id}`" target="_blank">
  <div class="product-common-container">
    <div class="left-part">
      <img class='image lazyload' :data-src="productData.image_url | imageFormat" :alt="productImageAlt"/>
      <div class="with-video" v-if="productData.has_video"><img src="//img.wzj.com/h5-static/img/h5/play.png" alt="播放"/></div>
    </div>
    <div class="right-part">
      <object><nuxt-link :to="`/relevance/${productData.id}`" target="_blank"><i class='wzj-pc-similarity'></i></nuxt-link></object>
      <div class='block name-block'>
        <template v-if='productData.tags.length > 0'>
          <span class='tag' v-for='tag in productData.tags' :key='tag.name' :style="{'color': tag.text_color, 'backgroundColor': tag.background_color}">{{tag.name}}</span>
        </template>
        <span class='name'>{{productData.name}}</span>
      </div>
      <div class='block tag-block clearfix' v-if='productData.sku_info && productData.sku_info.specifications'>
        <span class='tag-des'>规格：</span>
        <div class='tag-container' ref='tagContainer'>
          <span class='sku-tag specification-tag' ref='specificationTag' v-for="specification in productData.sku_info.specifications" :key="specification">{{specification}}</span>
          <item-sku-bubble  v-if='isShowBubble' :skuAttrs='leftSkuTag'></item-sku-bubble>
        </div>
      </div>
      <div class='block tag-block clearfix' v-if='productData.sku_info && productData.sku_info.materials'>
        <span class='tag-des'>材质：</span>
        <div class='tag-container' ref='materialContainer'>
          <span class='sku-tag' v-for="material in productData.sku_info.materials" :key="material">{{material}}</span>
        </div>
      </div>
      <div class='block tag-block clearfix' v-if='productData.sku_info && productData.sku_info.colors'>
        <span class='tag-des'>颜色：</span>
        <div class='tag-container' ref='colorContainer'>
          <span class='sku-tag' v-for="color in productData.sku_info.colors" :key="color">{{color}}</span>
        </div>
      </div>
      <div class='block price-block'>
        <span class='price'>¥{{productData.min_price}}{{productData.max_price && productData.min_price !== productData.max_price ? `-${productData.max_price}` : ''}}</span>
        <template v-if='productData.promotion_tag'>
          <span class='tag promotion-tag'>{{productData.promotion_tag}}</span>
        </template>
      </div>  
      <div class='block star-block'>
        <star :commentNum='productData.review_count' :score='productData.review_score'></star>
      </div>
      <!--SEO-->
      <div class="none">{{productData.review_content || ''}}</div>
    </div>
  </div>
  </nuxt-link>
</template>

<script>
import Star from './CommentStar'
import ItemSkuBubble from '~/components/Common/ItemSkuBubble'
import { isFurniture } from '@/assets/lib/tool'

export default {
  name: 'ProductCommon',
  components: {
    Star,
    ItemSkuBubble,
  },
  props: {
    productData: Object,
    relatedKeyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowBubble: false,
      leftSkuTag: [],
    }
  },
  computed: {
    productImageAlt() {
      const { style = '', brand = '', name, sku_info = {}, materials = '' } = this.productData
      if (this.relatedKeyword) {
        return `和"${this.relatedKeyword}"相关的${name}`
      }
      if (style && brand) {
        if (isFurniture(name)) {
          return `"${style}风格的${name}`
        }
        return `"${brand} ${name}`
      }
      if (sku_info.materials) {
        return `${name} ${sku_info.materials.join(' ')}`
      }
      return `${name} ${materials}`
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setBubbleSkus()
    })
  },
  methods: {
    setBubbleSkus() {
      const tagList = this.$refs.specificationTag
      if (tagList && tagList.length) {
        const index = tagList.findIndex(item => { // 找到第一个出现在第三行的位置
          return item.offsetTop > 70
        })
        if (index > 0) {
          this.isShowBubble = true
          const length = this.productData.sku_info.specifications.length
          const tags = [].concat(this.productData.sku_info.specifications)
          this.leftSkuTag = tags.slice(index)
          this.productData.sku_info.specifications.splice(index, length - index)
        }
      }
    },
  },
}

</script>
<style lang='scss' scoped>
.tag-container {
  position: relative;
}
.product-common-container {
  width: 100%;
  margin: 0 auto 20px;
  display: flex;
  flex-direction: row;
  background: #fff;
  cursor: pointer;
  .image {
    width: 200px;
    height: 200px;
    border-radius: 4px;
    margin:0 20px;
  }
  .right-part {
    flex: 1 1 auto;
    position: relative;
    margin-top: -3px;
    padding-bottom: 20px;
    margin-right: 40px;
    border-bottom: 1px solid #e6e6e6;
    .wzj-pc-similarity {
      position: absolute;
      z-index: 10;
      font-size: 20px;
      color: #92969c;
      top: 100px;
      right: 0;
      cursor: pointer;
    }
    .block {
      position: relative;
      & > div, & > span {
        display: inline-block;
      }
    }
    .name-block {
      margin-top: -2px;
      margin-bottom: 10px;
      .tag {
        display: inline-block;
        position: relative;
        padding: 4px 5px;
        top: -2px;
      }
    }
    .price-block {
      margin-top: 10px;
    }
    .star-block {
      margin-top: -12px;
    }
    .tag-block {
      & > div {
        float: left;
        max-width: 90%;
        // max-height: 70px;
        // overflow: hidden;
      }
    }
    .name {
      width: 300px;
      display: inline-block;
      font-family:PingFangSC-Medium;
      font-size:18px;
      color:#1a1a1a;
      line-height:18px;
    }
    .tag {
      display: inline-block;
      margin-right: 5px;
      padding: 2px 3px;
      color:#ff5541;
      font-family:PingFangSC-Medium;
      font-size:11px;
      line-height: 11px;
      border-radius:10px;
      border:1px solid;
    }
    .price {
      margin-right: 5px;
      font-family:HelveticaNeue-Bold;
      font-size:14px;
      color:#ff5541;
      line-height:18px;
    }
    .sku-tag {
      display: inline-block;
      // float: left;
      padding: 2px 4px;
      margin-right: 4px;
      margin-bottom: 0;
      font-family:PingFangSC-Regular;
      font-size:12px;
      color:#4f5156;
      background:#f6f6f6;
    }
    .tag-des {
      float: left;
      position: relative;
      top: 15px;
      font-family:PingFangSC-Medium;
      font-size:12px;
      color:#4f5156;
      letter-spacing:0;
      line-height:12px;
    }
  }
}
.left-part{
  position: relative;
}
.with-video{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  z-index: 2;
  transform: translate(-50%, -50%);
  img{
    width: 100%;
    height: 100%;
  }
}
</style>