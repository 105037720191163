<template>
  <nuxt-link :to="`/brands/${this.item.id}`" class="brand-item" @mouseover.native="showDeleteBtn = true" @mouseleave.native="showDeleteBtn = false" >
    <collect-delete-btn @handleDeleteClick="handleDeleteClick" v-show="showDeleteBtn" v-if="isCollect"/>
    <div class="img-ctn" :style="{ 'background-image': image}"></div>
    <div class="text">
      <div class="name">{{ item.name }}<div class="border"></div></div>
      <div class="desc">{{ item.description }}</div>
    </div>
  </nuxt-link>
</template>

<script>
  import imgLoader from '@/assets/lib/imageFormat'
  import CollectDeleteBtn from '@/components/Common/CollectDeleteBtn'
  export default {
    props: {
      item: Object,
      index: Number,
      isCollect: Boolean,
    },
    components: {
      CollectDeleteBtn,
    },
    data() {
      return {
        showDeleteBtn: false,
      }
    },
    computed: {
      image() {
        const { item, isCollect } = this
        const { image_url, cover } = item
        const url = isCollect ? image_url : cover.url
        return `url(${imgLoader(url)})`
      },
    },
    methods: {
      focusNum(hits) {
        return (hits / 1000).toFixed(1)
      },
      handleDeleteClick() {
        this.$emit('handleDeleteClick', { id: this.item.id, index: this.index })
      },
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.brand-item{
  display: block;
  float: left;
  width: calc(25% - 8px);
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  overflow: hidden;
  &:hover{
    box-shadow:2px 4px 11px 0px rgba(195,195,195,0.5);
    .img-ctn .hover-area{
      visibility: visible;
    }
  }
  &:nth-child(4n) {
    margin-right: 0;
    // padding-right: 0;
  }
}
.img-ctn{
  font-size: 0;
  padding-top: 75%;
  position: relative;
  overflow: hidden;
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}
.main-img, .hover-area{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
  
.text{
  height: 100px;
  background: #fff;
  text-align: center;
}
.name{
  padding-top: 20px;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  font-weight:600;
  color: #1a1a1a;
}
.border{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20px;
  height: 2px;
  margin-top: 6px;
  background: $_text_dark;
}  
.desc{
  padding: 0 20px;
  margin-top: 19px;
  color: #92969C;
  font-size: 14px;
  @include text-ellipsis();
}
.hover-area{
  visibility: hidden;
  text-align: center;
  background: rgba(255, 255, 255, .8);
}
.hover-main{
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
}
.logo-ctn{
  display: inline-block;
  margin-bottom: 20px;
  width: 24.6%;
  padding-top: 24.6%;
  position: relative;
  .logo-img{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.num{
  font-size: 12px;
  color: #505257;
  .split{
    margin: 0 10px;
  }
}
</style>
