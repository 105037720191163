<template>
  <div class="pageContainer">
    <div class='fixed-container' ref='fixedContainer'>
      <!-- 主要商品 -->
      <product-main
        :data="productData"
        :prices='prices'
        v-show='isScrollTop'></product-main>
      <!-- tab组件模块 -->
      <item-tab :itemTabData='itemTabData' v-model="currentTab"></item-tab>
    </div>
    <!-- tab搜索结果模块 -->
    <div v-if="itemTabData.length"  class="resultcontainer">
      <div v-show="currentData.type === 'homer'" class='container'>
        <homer-item-wrap :list="homerList"></homer-item-wrap>
      </div>
      <div v-show="currentData.type === 'similar'" class="container productsContainer">
        <product-common :productData='product' v-for='product in similarList' :key='product.id' :relatedProduct="productData"></product-common>
      </div>
      <div v-show="currentData.type === 'combo'" class="container productsContainer">
        <product-common :productData='product' v-for='product in comboList' :key='product.id' :relatedProduct="productData"></product-common>
      </div>
    </div>
    <div v-else class='no-product-container'>
     <img :src="noProductBox | imageFormat" alt="我在家"/>
     <h3 >找不到相似的内容</h3>
   </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import productMain from '@/components/Common/ProductMain'
import productCommon from '@/components/Common/ProductCommon'
import ItemTab from '@/components/Common/ItemTab'
import HomerItemWrap from '@/components/Common/HomerItemWrap'
import noProductBox from '@/assets/image/no_product_box.png'

const network_type = 'pc'
export default {
  name: 'product-search-list',
  components: {
    productMain,
    ItemTab,
    productCommon,
    HomerItemWrap,
  },
  async asyncData({ axios, query, error, route }) {
    const productId = route.params.id || query.product_id // 新旧两种链接
    if (!/^\d+$/.test(productId)) {
      return error({ statusCode: 404, message: 'Not found' })
    }
    const [pageData, relative] = await Promise.all([
      axios.get(`/proxy/products/${productId}`, { params: {
        network_type,
      }}),
      axios.get(`/proxy/products/${productId}/relative`),
    ])
    if (pageData && relative) {
      const productData = pageData.results[0]
      const prices = []
      if (productData.prices && productData.prices.length) {
        productData.prices.forEach((item) => {
          prices.push(item.price)
          prices.sort((a, b) => a - b)
        })
      }

      const relativeData = relative.results[0]

      // tab分享信息拼接
      const itemTabData = []
      // 相似商品
      const { similar_products: similarProducts = [], recommend_products: recommendProducts = [] } = relativeData
      if (similarProducts.length > 0) {
        itemTabData.push({
          text: '相似商品',
          type: 'similar',
          data: similarProducts,
        })
      }
      let homerList = []
      let hasMoreHomer = false
      if (relativeData.has_homers) {
        itemTabData.push({
          text: '关联生活家',
          data: [],
          type: 'homer',
        })
        const homerListRes = await axios.get(`/proxy/homers`, { params: {
          limit: 15,
          offset: 0,
          product_id: productId,
        }})
        homerList = homerListRes.results
        hasMoreHomer = homerListRes.has_more
      }

      // 组合搭配
      if (recommendProducts.length > 0) {
        itemTabData.push({
          text: '组合搭配',
          type: 'combo',
          data: recommendProducts,
        })
      }
      return { productData, prices, itemTabData, productId, homerList, hasMoreHomer }
    } else {
      return error({ statusCode: 404, message: 'Not found' })
    }
  },
  data() {
    return {
      currentTab: Number(this.$route.query.currentTab) || 0,
      currentData: {},
      hasMoreHomer: false,
      homerList: [],
      isScrollTop: true,
      isGoTopShow: false,
      noProductBox,
    }
  },
  watch: {
    $route() {
      this.currentData = this.itemTabData[this.currentTab]
      this.pageGoTop()
    },
    // 切换tab
    currentTab(val) {
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          currentTab: val,
        },
      })
    },
    scrollTop(val) {
      const isEndReached = window.innerHeight + val + 40 >= document.body.offsetHeight
      if (val > 1000) {
        this.isGoTopShow = true
      } else {
        this.isGoTopShow = false
      }
      if (isEndReached && this.hasMoreHomer && this.currentData.type === 'homer') {
        this.getMoreTabLists()
      }
    },
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
      screenWidth: state => state.common.screenWidth, // 可根据屏幕宽度变化，做适配
    }),
    similarList() {
      const res = this.itemTabData.find(item => item.type === 'similar')
      return res ? res.data : []
    },
    comboList() {
      const res = this.itemTabData.find(item => item.type === 'combo')
      return res ? res.data : []
    },
  },
  head() {
    const { title, keywords, description } = this.getPageInfo()
    return {
      title,
      titleTemplate: '%s 「我在家」一站式高品质新零售家居品牌',
      meta: [
        { hid: 'keywords', name: 'keywords', content: keywords },
        { hid: 'description', name: 'description', content: description },
      ],
    }
  },
  mounted() {
    this.currentData = this.itemTabData[this.currentTab] || {}
  },
  methods: {
    async getMoreTabLists() {
      if (this.getMoreTabLists.doing) return
      this.getMoreTabLists.doing = true
      const res = await this.$http.get('/proxy/homers', { params: {
        limit: 15,
        offset: this.homerList.length,
        product_id: this.productId,
      }})
      this.getMoreTabLists.doing = false
      if (res) {
        this.hasMoreHomer = res.has_more
        this.homerList = this.homerList.concat(res.results)
      }
    },
    pageGoTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0)
      })
    },
    getPageInfo() {
      const { name, style, main_materials = '', material = [] } = this.productData
      const materials = (main_materials === '实木' && material.length > 0) ? material[0].text : main_materials
      const keywords = `家具,家居,${name},${materials}家具,${style}风格家具,我在家`
      const description = `看看${style}风格的${name}有哪些相似款式，应该如何组合搭配，更有生活家真实场景体验`
      const title = `${style}风格${name}的相关推荐`
      return { title, keywords, description }
    },
  },
}

</script>
<style lang='scss' scoped>
.header {
  width: 100%;
  position: fixed;
  z-index: 13;
  top: 0;
  left: 0;
}
  .pageContainer {
    min-height: 100vh;
    width: 100%;
    background: #f8f8f8;
    overflow-y: auto;
  }
  .fixed-container {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e6e6e6;
  }
  .resultcontainer {
     padding: 20px 0;
    .productsContainer {
      margin: 0 auto;
      padding: 20px 0;
      border: 1px solid #e6e6e6;
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
    }
  }
 .no-product-container {
   margin-top: 140px;
   text-align: center;
   h3 {
     margin: 15px 0 5px;
     font-size: 16px;
     font-family: PingFangSC-Medium;
     color: #2C3038;
   }
 }
</style>
