<template>
  <div class="page-container">
    <div class="homer-header">
      <!-- <div class="homer-bg" v-if="homerInfo.cover">
        <img class="lazyload" :data-src="homerInfo.cover[0].url | imageFormat(1600)" alt="我在家">
      </div> -->
      <div class="homer-bg" :style="{backgroundImage:`url(${backgroundImg})`}"></div>
      <h1 class="none">{{homerInfo.nickname}}</h1>
    </div>
    <div class="container homer-container">
      <div class="left-content">
        <!-- 简介 -->
        <div class="homer-intro">
          <img class="lazyload" :data-src="homerInfo.icon | imageFormat(200)" :alt="`${trimCityTail(homerInfo.city)}${homerInfo.style}风格生活家 ${homerInfo.nickname}`">
          <h4><span>{{homerInfo.nickname}}</span> <img :src="'//img.wzj.com/upload/web/images/homer-logo.jpg' | imageFormat" alt="我在家生活家"></h4>
          <div class="homer-desc">“{{homerInfo.homer_desc}}”</div>
          <div class="user_desc">{{homerInfo.user_desc}}</div>
          <add-collect-btn class="collect-btn" :like="homerInfo.like" @handleCollectClick="handleCollectClick"></add-collect-btn>
        </div>
        <!-- 房屋信息 -->
        <div class="house-info">
          <div class="house-info-title"><i class="icon wzj-pc-home"></i>房屋信息</div>
          <no-ssr>
            <baidu-map class="b-map" :center="center" :zoom="15" @click="handleToBaiduMap">
              <bm-marker :position="{lng: center.lng, lat: center.lat}"></bm-marker>
              <!-- 覆盖百度地图logo -->
              <div class="cover-logo"></div>
            </baidu-map>
          </no-ssr>
          <p><span>所处位置</span>{{homerInfo.city}}·{{homerInfo.estate}}</p>
        </div>
        <!-- 体验评分 -->
        <div class="experience-grade clearfix" v-if="homerReviewLists.length">
          <div class="experience-grade-left">
            <div class="experience-title">体验评分</div>
            <div class="score">{{homerReview.score.avg}}.0</div>
          </div>
          <div class="experience-grade-right">
            <div>服务质量 <progress-bar class="experience-progress" :height="8" :value="homerReview.score.serve_score"></progress-bar></div>
            <div>房屋颜值 <progress-bar class="experience-progress" :height="8" :value="homerReview.score.house_score"></progress-bar></div>
            <div>风格喜欢 <progress-bar class="experience-progress" :height="8" :value="homerReview.score.product_score"></progress-bar></div>
          </div>
        </div>
        <!-- 体验评价 -->
        <div class="experience-evaluate" ref="evaluate" v-if="homerReviewLists.length">
          <h4><i class="icon wzj-pc-evaluate"></i>体验评价({{homerReview.count}})</h4>
          <div class="evaluate-wrapper" :style="{'maxHeight': evaluateHeight +'px'}">
            <ul ref="evaluateLists">
              <li v-for="review in homerReviewLists" :key="review.id">
                <comment-item :homer="true" :data="review" :commentAvatarAlt="`${getCommentAvatarAlt(review)}`" />
              </li>
            </ul>
          </div>
          <a class="spread-more" @click.prevent="handleShowMoreReview" v-if="hasMoreData">点击查看更多</a>
        </div>
      </div>
      <div class="right-content">
        <!-- 搭配方案 -->
        <div class="match-scheme">
          <h4>我的搭配方案</h4>
          <div class="match-products">
            <ul class="clearfix">
              <li class="product-list" v-for="product in homerProducts.products" :key="product.sku_id + product.dimensions">
                <homer-product-item :product="product" :city="homerInfo.city"/>
              </li>
            </ul>
            <div class="product-total">
              <span>{{homerProducts.count}}件商品 共：<i>{{homerProducts.total_price}}元</i></span>
            </div>
          </div>
        </div>
        <div class="homer-image">
          <div class="img-list" v-for="img in homerInfo.images" :key="img.id">
            <img class="lazyload" :data-src="img.url | imageFormat(800)" :alt="`${getDetailImageAlt(img)}`">
            <div v-for="hotspot in img.hotspots" :key="hotspot.id" class="tag" :style="{ left: hotspot.x_axis + '%', top: hotspot.y_axis + '%' }">
              <nuxt-link :to="`/items/${hotspot.product_id}`" target="_blank">
                <em :class="{ right: hotspot.x_axis > 50 }" style="opacity: 1;">{{ hotspot.content }}</em>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="none">
      <a v-for="linkInfo in seoLinks" :href="linkInfo.link">{{linkInfo.text}}</a><br />
    </div>
  </div>
</template>

<script>
  import ProgressBar from '@/components/Common/ProgressBar'
  import HomerProductItem from '@/components/Common/HomerProductItem'
  import CommentItem from '@/components/Common/CommentItem'
  import AddCollectBtn from '@/components/Common/AddCollectBtn'
  import imgLoader from '~/assets/lib/imageFormat'
  import { fromRobot, trimCityTail, styleCategorySeoLinks } from '@/assets/lib/tool'

  const MIN_HEIGHT = 1600
  const LIMIT = 10
  export default {
    components: {
      ProgressBar,
      HomerProductItem,
      CommentItem,
      AddCollectBtn,
    },
    head() {
      const { title, keywords, description } = this.getPageInfo()
      return {
        title,
        titleTemplate: '%s 「我在家」一站式高品质新零售家居品牌',
        meta: [
          { hid: 'keywords', name: 'keywords', content: keywords },
          { hid: 'description', name: 'description', content: description },
        ],
      }
    },
    async asyncData({ params, axios, error, userAgent }) {
      const homerId = params.id
      if (!/^\d+$/.test(homerId)) {
        return error({ statusCode: 404, message: 'Not found' })
      }
      const data = {
        homerInfo: {},
        homerReview: {},
        homerReviewLists: [],
        homerProducts: {},
        offset: 0,
        homerId,
        hasMoreData: false,
        center: { lng: 0, lat: 0 },
      }
      const [info, products, review] = await Promise.all([
        axios.get(`/proxy/homer-space-detail/${homerId}`),
        axios.get(`/proxy/homer-products/${homerId}`),
        axios.get(`/proxy/homer-review/${homerId}`, {
          params: {
            limit: fromRobot(userAgent) ? 100 : LIMIT,
            offset: 0,
          },
        }),
      ])
      if (info && products && review) {
        return Object.assign({}, data, {
          homerInfo: info.results[0],
          homerReview: review.results[0],
          homerReviewLists: review.results[0].list,
          homerProducts: products.results[0],
          hasMoreData: review.has_more,
          center: {
            lng: info.results[0].longitude,
            lat: info.results[0].latitude,
          },
        })
      } else {
        return error({ statusCode: 404, message: 'Not found' })
      }
    },
    data() {
      return {
        searchImg: false,
        evaluateHeight: MIN_HEIGHT,
        isLoading: false,
      }
    },
    computed: {
      backgroundImg() {
        return this.homerInfo.cover ? imgLoader(this.homerInfo.cover[0].url, 1000) : ''
      },
      seoLinks() {
        const { style = '' } = this.homerInfo
        return styleCategorySeoLinks(style)
      },
    },
    methods: {
      trimCityTail,
      async handleShowMoreReview() {
        if (this.isLoading) return
        this.isLoading = true
        const res = await this.$http.get(`/proxy/homer-review/${this.homerId}`, {
          params: {
            limit: LIMIT,
            offset: this.homerReviewLists.length,
          },
        })
        if (res) {
          this.isLoading = false
          this.getEvaluateHeight()
          this.homerReviewLists = this.homerReviewLists.concat(res.results[0].list)
          this.hasMoreData = res.has_more
        }
      },
      handleToBaiduMap() {
        const content = JSON.stringify(`${this.homerInfo.city}-${this.homerInfo.estate}`)
        window.open(`https://map.baidu.com/?latlng=${this.center.lat},${this.center.lng}&title=%E7%94%9F%E6%B4%BB%E5%AE%B6%E4%BD%8D%E7%BD%AE&content=${content}&autoOpen=true&l`, '_blank')
      },
      getEvaluateHeight() {
        const evaluateOffsetTop = this.$refs.evaluate.offsetTop
        const bodyHeight = document.body.offsetHeight
        this.evaluateHeight = bodyHeight - evaluateOffsetTop
      },
      async handleCollectClick() {
        const { homerInfo } = this
        await this.checkLogin()
        if (homerInfo.like) {
          const res = await this.$http.delete(`/proxy/collection/homers/${homerInfo.id}`)
          if (res) {
            this.$toast('已取消收藏')
            this.homerInfo.like = !homerInfo.like
          }
          return
        }
        const res = await this.$http.post('/proxy/collection/homers', { id: homerInfo.id })
        if (res) {
          this.$toast('已收藏成功')
          this.homerInfo.like = !homerInfo.like
        }
      },
      getPageInfo() {
        const { nickname, estate, city, homer_desc = '', style = '' } = this.homerInfo
        const { products = [] } = this.homerProducts
        const homerDesc = homer_desc.replace(/[\r\n]+/g, ' ')
        const cityName = trimCityTail(city)
        const productList = products.map(function(element) {
          return element.title.trim()
        }).join('、')
        const title = `${cityName}${style}风格生活家${nickname}`
        const keywords = `${cityName}${style},${cityName}家具,${cityName}家居,${cityName}${estate},${cityName}我在家,${cityName}生活家,${nickname}`
        const description = `${cityName}${style}风格生活家${nickname}，${homerDesc} 位于${estate}的家中搭配了${productList}...`
        return { title, keywords, description }
      },
      getDetailImageAlt(img) {
        const hotspotsContent = img.hotspots.map(h => h.content).join('、')
        const { city, nickname, style = '' } = this.homerInfo
        return `${trimCityTail(city)}${style}风格生活家 ${nickname} 家中的${hotspotsContent || '家具'}`
      },
      getCommentAvatarAlt(comment) {
        const { name } = comment
        const { nickname, city, style } = this.homerInfo
        const cityName = trimCityTail(city)
        return `拜访过${cityName}${style}风格生活家 ${nickname} 的 ${name}`
      },
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
$border: 1px solid $_text_e6;

.page-container {
  background: #fafafa;
}
.homer-header {
  position: relative;
  width: 100%;
  // padding-top: function-screen-percent(480);
  padding-top: 360px;
  // max-height: 480px;
  overflow: hidden;

  .homer-bg {
    @include absolute-full(1);
    background-size: cover;
    background-position: center center;
  }
}
.homer-container  {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  background: #f9f9f9;
}
.left-content {
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 390px;
  min-width: 330px;
  overflow: hidden;
  background: #f9f9f9;;
}
.homer-intro {
  padding: 30px 40px;
  text-align: center;
  border: $border;
  background: #fff;

  >img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
  }
  >h4 {
    margin-top: 14px;
    margin-bottom: 10px;
    font-size: 20px;
    color: $_text_dark;

    img, span {
      vertical-align: middle;
    }
  }
  >.homer-desc {
    font-size: 14px;
    color: $_disable;
  }
  >.user_desc {
    margin-top: 10px;
    font-size: 14px;
    color: $_gray;
  }
}
.house-info {
  position: relative;
  padding: 30px 20px 0;
  border-bottom: $border;
  border-left: $border;
  border-right: $border;
  background: #fff;

  &-title {
    font-size: 16px;
  }
  .b-map {
    width: 350px;
    height: 250px;
    margin-top: 20px;
    position: relative;

    .cover-logo {
      position: absolute;
      bottom: 0;
      height: 50px;
      width: 100%;
      background: #fff;
    }
  }
  >p {
    position: absolute;
    bottom: 15px;
    width: 100%;
    font-size: 13px;

    >span {
      color: $_disable;
      margin-right: 20px;
    }
  }
}
.experience-grade {
  margin-top: 20px;
  padding: 30px 20px 20px;
  border: $border;
  background: #fff;

  >div {
    float: left;
    box-sizing: border-box;

    &:first-child {
      width: 40%;
    }

    &:last-child {
      width: 60%;
      border-left: $border;
    }
  }

  &-left {
    >.experience-title {
      font-size: 16px;
    }
    >.score {
      font-size: 80px;
      color:$c_highlight;
    }
  }
  &-right {
    >div {
      line-height: 34px;
      margin-left: 20px;
      font-size: 14px;
    }
    .experience-progress {
      display: inline-block;
      width: 53%;
      margin-left: 10px;
    }
  }
}
.experience-evaluate {
  position: relative;
  padding: 20px 0 0 20px;
  border-left: $border;
  border-right: $border;
  border-bottom: $border;
  background: #fff;

  >.evaluate-wrapper {
    overflow: auto;

    li:not(:last-child) {
      margin-bottom: 20px;
      padding-right: 20px;
      border-bottom: 1px solid #e6e6e6;
    }
  }
  h4 {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .spread-more {
    display: block;
    padding: 20px 0;
    margin-right: 20px;
    font-size: 14px;
    color: #507DAF;
    border-top: $border;
    text-align: center;
    cursor: pointer;
  }
}
.right-content {
  max-width: 780px;
  min-width: 660px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin-left: 20px;
  background: #f9f9f9;
}

.match-scheme {
  padding-top: 20px;
  border: $border;
  background: #fff;

  ul {
    padding: 0 20px;
  }
  .product-list {
    width: 25%;
    padding: 10px 5px;
    float: left;
    box-sizing: border-box;
  }

  h4 {
    margin-left: 20px;
    font-size: 20px;
  }
}

.product-total {
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-top: $border;

  >span {
    float: right;
    margin-right: 20px;
    font-size: 14px;
  }
  i {
    color: $c_highlight;
    font-style: normal;
  }
}

.homer-image {
  margin-top: 20px;
  background: #fff;
}
.img-list {
  text-align: center;
  position: relative;

  img {
    width: 100%;
  }

  a {
    cursor: pointer;
  }
}
@-webkit-keyframes mint-fading-circle {
    0%, 39%, 100% {
        opacity: 0
    }
    40% {
        opacity: 1
    }
}
@keyframes mint-fading-circle {
    0%, 39%, 100% {
        opacity: 0
    }
    40% {
        opacity: 1
    }
}
.tag {
  position: absolute;

  span {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 9px;
    height: 9px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    animation: mint-fading-circle 1.5s infinite ease-in-out;
    -webkit-animation: mint-fading-circle 1.5s infinite ease-in-out;
  }

  em {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 5px;
    position: absolute;
    top: -20px;
    white-space: nowrap;
    font-style: normal;
    color: #fff;
    font-size: 10px;
    line-height: 20px;
    border-radius: 5px 5px 5px 0;
    margin-left: 5px;
    opacity: 0;
  }

  .right {
    border-radius: 5px 5px 0;
    margin-left: 0;
    position: absolute;
    right: 0;
  }

  span:after {
    content: "";
    width: 3px;
    height: 3px;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    margin: -1.5px 0 0 -1.5px;
  }
}
.icon {
  margin-right: 4px;
  color: #92969c;
}
.collect-btn{
  margin: 30px auto 0;
}
</style>
