<template>
    <div>
        <style v-html="style">
        </style>
        <div v-html="body">
        </div>
    </div>
</template>
<script>
  export default {
    layout: 'empty',
    async asyncData({ route, axios }) {
      const url = `https://bdbsassets.oss-cn-shanghai-internal.aliyuncs.com/site${route.path}.html`
      const page = await axios.get(url)
      const style = /(<style\s?.*>)([\s\S]*)(<\/style>)/.exec(page)[2]
      const body = /(<body\s?.*>)([\s\S]*)(<\/body>)/.exec(page)[2]
      return { style: style, body: body }
    },
  }
</script>
