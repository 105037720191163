<template>
<div class="page-container">
  <!-- 面包屑 -->
  <div class="bread-container">
  <ul class="bread-crumb container">
    <li class='bread-home'>
      <nuxt-link to="/">首页 <i class="wzj-pc-arrow-right"></i></nuxt-link>
    </li>
    <li>
      {{keyword}}
    </li>
    <li v-show="referrer_page !== 'imageSearch'" v-for="param in selectedQueryParams" :key="param.name">
      <div class="sort-item">
        {{param.name}} <i @click="delQueryParam(param)">x</i>
      </div>
    </li>
  </ul>
  </div>
  <!-- 筛选条件 -->
  <div class="container">
  <div class='sort-container'>
    <div class='sort-item' v-show='categoryList.length'>
      <span class='sort-des'>品类：</span>
      <div>
        <template v-if="referrer_page !== 'imageSearch'">
          <span class='sort-name' v-for="(category, index) in categoryList" :key="index" :class="{active: category.id == sortQuery.category_id}" @click="updateQueryParams(category.id, 'category_id', category.name)">{{category.name}}</span>
        </template>
        <template v-else>
          <span class='sort-type' v-for="(category, index) in categoryList" :key="index" :class="{active: index == indexImg}" @click="doImgSearch(index, category)">
            <div :style="{backgroundImage:`url(${category.url})`}"></div>
            <span class='tip'>{{category.name}}</span>
          </span>
        </template>
      </div>
    </div>
    <div class='sort-item' v-if='sortInitDatas.styles && sortInitDatas.styles.length'>
      <span class='sort-des'>风格：</span>
      <div>
        <span class='sort-name' v-for='style in sortInitDatas.styles' :key='style.id'  @click="updateQueryParams(style.id, 'style_id', style.name)" :class="{active: style.id == sortQuery.style_id}">{{style.name}}</span>
      </div>
    </div>
    <template v-if="referrer_page !== 'imageSearch'">
      <div class='sort-item' v-show="referrer_page !== 'imageSearch'">
        <span class='sort-des'>价格：</span>
        <div>
          <span class='sort-name ' v-for="sort in priceSorts" :key="sort.id" :class="{active: sort.id == sortQuery.sort}" @click="updateQueryParams(sort.id, 'sort', sort.name)">{{sort.name}}</span>
        </div>
      </div>
      <div class='sort-item' v-if="materialDatas.length">
        <span class='sort-des'>材质：</span>
        <div>
          <span class='sort-name' v-for="material in materialDatas" :key="material.name" :class="{active: material.id == sortQuery.material_id}" @click="updateQueryParams(material.id, 'material_id', material.name)">{{material.name}}</span>
        </div>
      </div>
      <div class='sort-item'>
        <span class='sort-des'>价格区间：</span>
        <div class='input-container'>
          <input placeholder='请输入最低价' v-model="sortQuery.min_price"/>
          <span> - </span>
          <input placeholder='请输入最高价' v-model="sortQuery.max_price"/>
          <span class='btn-sure' :class="{disabled: !canSearchPrice}" @click="priceSearch">确定</span>
        </div>
      </div>
    </template>  
  </div>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLocalStorage, setLocalStorage } from '@/assets/lib/tool'

export default {
  components: {},
  props: {
    sortInitDatas: Object,
    initMaterial: Array,
  },
  data() {
    const query = this.$route.query
    return {
      indexImg: query.indexImg || 0,
      referrer_page: query.referrer_page,
      keyword: query.keyword,
      sortQuery: {
        sort: query.sort || 'popularity_desc',
        style_id: Number(query.style_id) || 0,
        category_id: query.category_id || 0,
        material_id: query.material_id || '',
        max_price: '',
        min_price: '',
      }, // 搜索条件汇总
      materialDatas: this.initMaterial,
      priceSorts: [
        { id: 'popularity_desc', name: '全部' },
        { id: 'price_asc', name: '从低到高' },
        { id: 'price_desc', name: '从高到低' },
      ],
      moreTop: true,
    }
  },
  watch: {
    initMaterial(val) {
      this.materialDatas = val
    },
    $route() {
      const query = this.$route.query
      this.keyword = query.keyword
      this.sortQuery = {
        style_id: Number(query.style_id) || 0,
        category_id: query.category_id || 0,
        material_id: query.material_id || '',
        sort: query.sort || 'popularity_desc',
      }
    },
    scrollTop(val) {
      if (val > 15) {
        this.moreTop = false
        return
      }
      this.moreTop = true
    },
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
    }),
    selectedQueryParams() {
      if (Number(this.$route.query.canReload) === 2) {
        return getLocalStorage('selectedQueryParams') || []
      }
      return []
    },
    categoryList() {
      if (this.referrer_page === 'imageSearch') {
        return getLocalStorage('wzjImageSearchData') || []
      } else {
        return this.sortInitDatas.categories || []
      }
    },
    canSearchPrice() {
      return this.sortQuery.min_price && this.sortQuery.max_price && this.sortQuery.min_price > 0 && this.sortQuery.max_price > 0 && Number(this.sortQuery.max_price) > Number(this.sortQuery.min_price)
    },
  },
  methods: {
    doImgSearch(index, data) {
      this.indexImg = index
      this.keyword = data.name
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          indexImg: index,
          keyword: data.name,
          ids: data.ids,
        },
      })
    },
    delQueryParam(param) { // 删除面包屑的选项
      const paramsIndex = this.selectedQueryParams.findIndex(item => {
        return item.type === param.type
      })
      this.selectedQueryParams.splice(paramsIndex, 1)
      this.sortQuery[param.type] = '0'
      if (param.type === 'sort') {
        this.sortQuery[param.type] = 'popularity_desc'
      } else if (param.type === 'material_id') {
        this.sortQuery[param.type] = ''
      }
      setLocalStorage('selectedQueryParams', this.selectedQueryParams)
      this.doSearch()
    },
    updateQueryParams(value, name, title) {
      this.sortQuery[name] = value
      if (!this.canSearchPrice) {
        this.sortQuery.min_price = ''
        this.sortQuery.max_price = ''
      }
      if (name === 'category_id' || name === 'style_id') { // 切换品类或者风格时，材质选项需要重新置为全部
        const paramsIndex = this.selectedQueryParams.findIndex(item => {
          return item.type === 'material_id'
        })
        this.selectedQueryParams.splice(paramsIndex, 1)
        this.sortQuery.material_id = ''
      }
      if (title) { // 面包屑搜索条件
        const param = {
          name: title,
          type: name,
          value: value,
        }
        const hasParamsIndex = this.selectedQueryParams.findIndex(item => {
          return item.type === name
        })
        if (Number(value) === 0 || value === 'popularity_desc') { // 选择全部的时候，删除选项
          if (hasParamsIndex !== -1) {
            this.selectedQueryParams.splice(hasParamsIndex, 1)
          }
        } else {
          if (hasParamsIndex === -1) {
            this.selectedQueryParams.push(param)
          } else {
            this.selectedQueryParams[hasParamsIndex] = param
          }
        }
      }
      setLocalStorage('selectedQueryParams', this.selectedQueryParams)
      this.doSearch()
    },
    priceSearch() {
      if (this.canSearchPrice) {
        // this.canSearchPrice = false
        this.doSearch()
      }
    },
    doSearch() {
      this.$router.replace({
        ...this.$route,
        name: 'search',
        query: {
          ...this.$route.query,
          sort: this.sortQuery.sort,
          style_id: this.sortQuery.style_id,
          category_id: this.sortQuery.category_id,
          material_id: this.sortQuery.material_id,
          max_price: this.sortQuery.max_price,
          min_price: this.sortQuery.min_price,
          canReload: 2,
        },
      })
    },
  },
}

</script>
<style lang='scss' scoped>
.page-container,.bread-container {
  width: 100%;
}
.bread-container {
  position: fixed;
  left: 20px;
  height: 40px;
  top: 64px;
  background: #f8f8f8;
  z-index: 12;
}
.bread-home {
  cursor: pointer;
}
.bread-crumb {
    & > li {
      display: inline-block;
      margin-right: 3px;
      font-family:PingFangSC-Medium;
      font-size:12px;
      color:#4f5156;
      i {
        font-size: 12px;
      }
      a {
        font-size:12px;
        color:#4f5156;
      }
    }
    .sort-item {
      background: rgba(0,0,0,.5);
      border-radius: 12px;
      padding: 0px 10px 2px 8px;
      color: #fff;
      font-size: 12px;
      font-family: PingFangSC-Regular;
      margin-left: 4px;
      i {
        font-size: 15px;
        font-style: normal;
        cursor: pointer;
        &:hover {
          color: #ff5541;
        }
      }
    }
  }
  .sort-container {
    margin-bottom: 20px;
    padding: 20px 20px 0;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e6e6e6;
    .sort-item {
      margin-bottom: 15px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      .sort-type {
        position: relative;
        width: 60px;
        height: 60px;
        display: inline-block;
        margin-right: 10px;
        border: 3px solid #fff;
        background: #000;
        &.active {
          border-color: #ff5541;
        }
        > div {
          height: 100%;
          width: 100%;
          background-color: #000;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        img {
          position: absolute;
          width: 100%;
        }
        .tip {
          position: absolute;
          top: 0;
          left: 0;
          padding: 1px 0px;
          font-family: PingFangSC-Medium;
          font-size: 10px;
          color: #fff;
          background: #ff5541;
          border-radius: 0px 0px 10px 0px;
          text-align: center;
          @include text-ellipsis()
        }
      }
      .sort-des {
        margin-right: 40px;
        vertical-align: top;
        color:#4f5156;
      }
      & > span, & > div {
        display: inline-block;
      }
      & > div {
        max-width: 85%;
        & > .sort-name {
          display: inline-block;
          margin-bottom: 10px;
          margin-right: 16px;
          color:#92969c;
          padding: 2px 8px;
          cursor: pointer;
          &:hover {
            border-radius:15px;
            color:#ff5541;
          }
          &.active {
            background:#ffeeec;
            border-radius:15px;
            color:#ff5541;
          }
        }
        input {
          width: 138px;
          height: 24px;
          background: #f6f6f6;
          border: 1px solid #e6e6e6;
          border-radius: 15px;
          text-align: center;
        }
      }
      .input-container {
        margin-left: -25px;
      }
      .btn-sure {
        padding: 2px 15px;
        margin-left: 10px;
        color:#ffffff;
        background:#ff5541;
        border-radius:12px;
        cursor: pointer;
        &.disabled {
          color: rgba(255,255,255,.5);
        }
      }
    }
  }
</style>