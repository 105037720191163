<template>
  <nuxt-link
    :to="`/items/${product.id}`"
    target="_blank"
    class="product-item-container"
    @mouseover.native="showDeleteBtn = true" @mouseleave.native="showDeleteBtn = false"
  >
    <collect-delete-btn @handleDeleteClick="handleDeleteClick" v-show="showDeleteBtn"/>
    <div class="img-ctn">
      <div class="with-video" v-if="product.has_video"><img src="//img.wzj.com/h5-static/img/h5/play.png" alt="播放"/></div>
      <img class="main-img lazyload" :data-src="product.image_url | imageFormat" :alt="productImageAlt">
      <div class="attribute-info" v-if="product.attribute_infos">
        <div class="attribute-item" v-for="item in product.attribute_infos" :key="item">{{item}}</div>
        <!--SEO-->
        <div class="none">{{product.review_content || ''}}</div>
      </div>
    </div>
    <div :class="{'desc-container': true, small: size === 'small'}">
      <div class="name-line">
        <span class="tag" v-for="tag in product.tags" :key="tag.name" :style="{'color': tag.text_color, 'backgroundColor': tag.background_color}">{{tag.name}}</span>
        <div class="name">{{ product.name }}</div>
      </div>
      <div v-if="subtitle" class="character">{{ product.subtitle }}</div>
      <div class="price">￥{{ product.min_price === product.max_price ? product.min_price : `${product.min_price}-${product.max_price}` }}
        <span class="tag" v-if="product.promotion_tag">{{product.promotion_tag}}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import CollectDeleteBtn from '@/components/Common/CollectDeleteBtn'

export default {
  name: 'ProductItem',
  components: {
    CollectDeleteBtn,
  },
  props: {
    product: Object,
    isCollect: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
    index: Number,
    relatedBrand: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showDeleteBtn: false,
    }
  },
  computed: {
    productImageAlt() {
      const { name, materials } = this.product
      if (this.relatedBrand) {
        const { name: brandName = '' } = this.relatedBrand
        return `${brandName}系列下的${name}`
      }
      return `${name} ${materials || ''}`
    },
  },
  methods: {
    handleDeleteClick() {
      this.$emit('handleDeleteClick', { id: this.product.id, index: this.index })
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.product-item-container{
  cursor: pointer;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  display: block;
  .main-img {
    transition: All .5s ease-in-out;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(146,150,156,0.30);

    .main-img {
      transform: scale(1.2)
    }
  }
  .img-ctn{
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    .main-img{
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
}
.attribute-info{
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 2;
  display: flex;
  .attribute-item{
    background: #fff;
    padding: 0 4px;
    color: $_gray;
    font-size: 12px;
    margin-right: 10px;
    border-radius: 10px;
  }
}
.desc-container{
  padding: 20px 0;
  text-align: center;
  background: #fff;
  &.small{
    padding: 10px 0;
    .name-line{
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .name-line{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  .name{
    color: #1A1A1A;
    font-weight: bolder;
  }
  .character{
    height: 17px;
    font-size: 12px;
    color: #92969C;
    margin: 10px 0;
  }
  .price{
    font-size: 18px;
    color: $c_highlight;

    .tag{
      vertical-align: 2px;;
      border: 1px solid $c_highlight;
    }
  }
}
.tag{
  font-size: 12px;
  padding: 1px 4px;
  border-radius: 10px;
  margin-right: 4px;
  transform: scale(.93, .84);
}
.with-video{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 32px;
  height: 32px;
  z-index: 2;
  transform: translate(-50%, -50%);
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
