<template>
  <div class="home-wrapper">
    <!-- 头部轮播图 -->
    <div class="header-swiper" :style="`width: ${screenWidth}px`">
      <wzj-swiper :autoplayTimeout="5000" :indicator="headerSwiperImages.length > 1" v-model="headerSwiperIndex" :stepWidth="screenWidth" :arrow="true" :num="headerSwiperImages.length">
        <wzj-swiper-item :width="screenWidth" v-for="item in headerSwiperImages" :key="item">
          <div class="swiper-img" @click="handleToRedirect(item.redirect)" :style="{backgroundImage:`url(${item.img_url})`}"></div>
        </wzj-swiper-item>
      </wzj-swiper>
    </div>
    <!-- 快速筛选 -->
    <fast-search class="container" :categoryList="categoryList" :styleList="styleList" :spaceList="spaceList" />
    <!-- 热卖风格, 全部系列 -->
    <div class="hot-sale-ctn">
      <hot-sale-style class="container" v-if="tabList.length > 0" :tabList="tabList" :defaultList="seriesList"/>
    </div>
    <!-- 生活家轮播图 -->
    <div class="homer-swiper" :style="`width: ${screenWidth}px`">
      <wzj-swiper :autoplayTimeout="5000" :indicator="homerSwiperImages.length > 1" v-model="homerSwiperIndex" :stepWidth="screenWidth" :arrow="true" :num="homerSwiperImages.length">
        <wzj-swiper-item :width="screenWidth" v-for="item in homerSwiperImages" :key="item">
          <div class="swiper-img" :style="{backgroundImage:`url(${item})`}"></div>
        </wzj-swiper-item>
      </wzj-swiper>
    </div>
    <!-- 蒙层 -->
    <div class="model" v-if="showModel" @click="hanleHideModel"></div>
    <div ref="homersTab" style="height: 1px"></div>
    <div class="homers-selected-bar-wrapper" :class="{'homers-selected-top': homersTop}">
      <div class="container">
        <!--城市选择tab-->
        <div class="selected-bar-city" @click="handleSelectedCity">
          <selected-tab
            :selected="isSelectedCity"
            :value="selectedCity && selectedCity.slice(-1)[0] ? selectedCity.slice(-1)[0].name: undefined"
            title="不限城市">
          </selected-tab>
        </div>
        <!--风格选择tab-->
        <div class="selected-bar-style" @click="handleSelectedStyle">
          <selected-tab
            :selected="isSelectedStyle"
            :value="selectedStyle&&selectedStyle.name"
            title="不限风格">
          </selected-tab>
        </div>
      </div>
      <!--城市/风格选择-->
      <div class="filter-box">
        <selected-city v-model="isSelectedCity" @handleChangeFiltrateArea="handleChangeFiltrate"></selected-city>
        <selected-single v-model="isSelectedStyle" :currentValue="selectedStyle&&selectedStyle.name" :singleLists="styleFilterData" @handleChangeFiltrateStyle="handleChangeFiltrate"></selected-single>
      </div>
    </div>
    <div class="container homers-container">
      <div class="pt20" :class="{pt80: homersTop}"></div>
      <homer-item-wrap v-if="homersListData.length" :list="homersListData"></homer-item-wrap>
      <div class="load-more" v-if="hasMoreData" @click="getHomerList(true)">
        <span>点击加载更多</span>
      </div>
      <div class="no-homers" v-if="!homersListData.length && !loadingMore">
        <img :src="'//img.wzj.com/upload/web/images/no-homer-img.png' | imageFormat" alt="我在家">
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import HomerItemWrap from '@/components/Common/HomerItemWrap'
  import ClassifyList from '@/components/Common/ClassifyList'
  import SelectedTab from '@/components/Common/SelectedTab'
  import SelectedCity from '@/components/Common/SelectedCity'
  import SelectedSingle from '@/components/Common/SelectedSingle'
  import FastSearch from '@/components/Common/FastSearch'
  import HotSaleStyle from '@/components/Common/HotSaleStyle'

  const LIMIT = 10
  export default {
    name: 'Index',
    components: {
      HomerItemWrap,
      ClassifyList,
      SelectedTab,
      SelectedCity,
      SelectedSingle,
      FastSearch,
      HotSaleStyle,
    },
    async asyncData({ axios }) {
      const data = {
        styleFilterData: [], // 获取筛选选项数据
        categoryList: [],
        styleList: [],
        spaceList: [],
        homersListData: [], // 生活家列表
        hasMoreData: false,
        tabList: [], // 全部系列分类
        seriesList: [],
      }
      const homerLimit = 12
      const [filters, classification, homersList, tabList = { results: [] }] = await Promise.all([
        axios.get('/proxy/homer/filters', { cache: true }),
        axios.get('/proxy/classification', { cache: true }),
        axios.get('/proxy/homers', {
          params: {
            limit: homerLimit,
            offset: 0,
          },
        }),
        axios.get('/proxy/series-catalog/list'),
      ])
      if (filters && classification && homersList && tabList) {
        // 分类列表
        const { categories = [], spaces = [], styles = [] } = classification.results[0]
        const seriesList = await axios.get(`/proxy/series-catalog/${tabList.results[0].id}/series?limit=99&offset=0`)
        return Object.assign({}, data, {
          styleFilterData: filters.results[0].style.list,
          categoryList: categories,
          styleList: styles,
          spaceList: spaces,
          homersListData: homersList.results,
          hasMoreData: homersList.has_more,
          tabList: tabList.results,
          seriesList: seriesList ? seriesList.results : [],
        })
      }

      return data
    },
    data() {
      return {
        isSelectedCity: false,
        selectedCity: undefined,
        isSelectedStyle: false,
        selectedStyle: undefined,
        loadingMore: false,
        homersTabOffsetTop: 0,
        showModel: false,
        homersTab: false,
        headerSwiperIndex: 0,
        homerSwiperIndex: 0,
        headerSwiperImages: [
          {
            img_url: 'https://img.wzj.com/upload/web/images/index_banner_1_5.jpg',
          },
          {
            redirect: '/introduce',
            img_url: 'https://img.wzj.com/upload/web/images/index_banner_2_5.jpg',
          },
        ],
        homerSwiperImages: [
          'https://img.wzj.com/upload/web/images/homer_banner_1.jpg',
        ],
      }
    },
    computed: {
      ...mapState({
        scrollTop: state => state.common.pageScrollTop,
        screenWidth: state => state.common.screenWidth - 0,
      }),
      homersTop() {
        return this.isSelectedCity || this.isSelectedStyle || this.homersTab
      },
      searchParams() {
        const { value: styleId = 0 } = this.selectedStyle || {}
        const [{ id: cityId = '' } = {}, { id: districtId = '' } = {}, { id: estateId = '' } = {}] = this.selectedCity || []
        return {
          limit: LIMIT,
          offset: this.homersListData.length,
          style_id: styleId,
          city_id: cityId,
          district_id: districtId,
          estate_id: estateId,
        }
      },
    },
    watch: {
      scrollTop(val) {
        const homeRef = this.$refs.homersTab
        if (homeRef) {
          this.homersTabOffsetTop = homeRef.offsetTop
          this.homersTab = val > this.homersTabOffsetTop - 60
        }
      },
    },
    methods: {
      getHomerList(isLoadMore = false) { // 获取生活家列表
        this.loadingMore = isLoadMore
        if (!isLoadMore) {
          this.loadingMore = true
          this.homersListData = []
        }
        const params = { ...this.searchParams, limit: 12 }
        this.$http.get('/proxy/homers', {
          params,
        }).then((res) => {
          this.hasMoreData = res.has_more
          this.loadingMore = false
          if (isLoadMore) {
            this.homersListData = this.homersListData.concat(res.results)
          } else {
            this.homersListData = res.results
            this.handleWindowScroll(this.homersTabOffsetTop)
          }
        })
      },
      handleSelectedCity() {
        this.isSelectedCity = !this.isSelectedCity
        this.handleHomersTab(this.isSelectedCity)
        this.isSelectedStyle = false
      },
      handleSelectedStyle() {
        this.isSelectedStyle = !this.isSelectedStyle
        this.handleHomersTab(this.isSelectedStyle)
        this.isSelectedCity = false
      },
      handleHomersTab(isShow) {
        this.showModel = isShow
        this.handleBodyOverflow(isShow)
        this.handleWindowScroll(this.homersTabOffsetTop)
      },
      handleBodyOverflow(isShow) {
        document.querySelector('body').setAttribute('style', `overflow:${isShow ? 'hidden' : 'auto'}`)
      },
      hanleHideModel() {
        this.handleBodyOverflow(false)
        this.showModel = false
        this.isSelectedStyle = false
        this.isSelectedCity = false
      },
      handleWindowScroll(target = 0) {
        this.$nextTick(() => {
          window.scrollTo(0, target)
        })
      },
      // 确定地址选择 || 风格选择
      handleChangeFiltrate(opts) {
        if (opts.filterData) {
          this.selectedCity = opts.filterData
        } else {
          this.selectedStyle = opts
        }
        this.getHomerList()
        this.hanleHideModel()
      },
      handleToRedirect(redirect) {
        if (!redirect) return
        this.$router.push(redirect)
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .home-wrapper {
    height: 100%;
    padding-bottom: 20px;
  }

  .header-swiper, .homer-swiper {
    position: relative;
    overflow: hidden;
    background-color: #f6f6f6;
    .swiper-img {
      max-width: 1920px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  .header-swiper .swiper-img{
    height: 540px;
  }
  .homer-swiper .swiper-img{
    height: 420px;
  }
  .container {
    position: relative;
  }
  .search-img-box{
    width: 790px;
    height: 178px;
    margin: 0 auto;
  }
  .keyword-box, .search-img-box {
    margin-top: 40px;
  }
  .search-box, .classify-box {
    width: 790px;
    margin: 0 auto;
  }
  .classify-box {
    height: 100%;
    padding: 20px 0 0 20px;
    margin-top: 40px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .5)
  }
  .homers-selected-bar-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid $c_gray;
    font-size: $f_16;
    background: #fff;

    .selected-bar-city {
      min-width: 100px;
      position: absolute;
      left: 38%;
      z-index: 1;
    }
    .selected-bar-style {
      min-width: 100px;
      position: absolute;
      right: 38%;
      z-index: 1;
    }
  }
  .model {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 15;
    background: rgba(0, 0, 0, .2);
  }
  .homers-selected-top {
    position: fixed;
    top: 64px;
    z-index: 20;
  }
  .filter-box {
    width: 100%;
    position: absolute;
    top: 61px;
    z-index: 19;
  }
  .pt20 {
    padding-top: 20px;
  }
  .pt80 {
    padding-top: 80px;
  }
  .no-homers {
    height: 160px;
    margin-top: 150px;
    text-align: center;

    >img {
      height: 100%;
    }
  }
  .homers-container {
    min-height: 100vh;
  }
  .hot-sale-ctn{
    background: $_bg_f9;
  }
  .search-history{
    margin-top: 18px;
  }

  .load-more {
    margin: 40px auto 20px;
    width: 380px;
    padding: 11px 0;
    background-color: #F6F6F6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & > span {
      font-size: 14px;
      font-weight: 500;
      color: $_gray;
      line-height: 18px;
    }
  }
</style>
