<template>
  <div class="progress">
    <span :style="{'backgroundColor': bg, 'width': percentage * 100+'%', 'height': height + 'px', 'borderRadius': height + 'px'}"></span>
  </div>
</template>
<script>
  export default {
    props: {
      percentage: {
        type: Number,
        default: 1,
      },
      bg: {
        type: String,
        default: '#ff5541',
      },
      height: Number,
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.progress {
  width: 100%;
  height: 100%;

  span {
    display: inline-block;
  }
}
</style>