<template>
  <div class="pop-container" v-if="isVisible">
    <div class="pop-layer" @click="cancel"></div>
    <div class="pop-box">
      <div class="pop-header">
        {{title}}
      </div>
      <div class="pop-content">
        <div v-html="content"></div>
      </div>
      <div class="pop-footer">
        <span @click="cancelHanlder">{{cancelText}}</span>
        <span class="active" @click="confirmHanlder">{{confirmText}}</span>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Confirm',
    data() {
      return {
        isVisible: false,
      }
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '温馨提示',
      },
      content: {
        type: String,
        default: '',
      },
      confirmText: {
        type: String,
        default: '确认',
      },
      cancelText: {
        type: String,
        default: '取消',
      },
    },
    watch: {
      isVisible(newVal) {
        this.$emit('toggle', newVal)
      },
    },
    mounted() {
      this.$watch('visible', (newVal) => {
        if (newVal) {
          this.show()
        } else {
          this.hide()
        }
      }, {
        immediate: true,
      })
    },
    methods: {
      show() {
        this.isVisible = true
      },
      hide() {
        this.isVisible = false
      },
      confirmHanlder(e) {
        this.hide()
        this.confirm(e)
      },
      cancelHanlder(e) {
        this.hide()
        this.cancel(e)
      },
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
  .pop-container {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pop-layer{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
  }

  .pop-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 69.33333%;
    border-radius: 20px;
    background-color: #fff;
    z-index: 3;

    .pop-header{
      padding: 30px 40px;
      line-height: 1;
      text-align: center;
      font-size: 34px;
      color: #1a1a1a;
    }

    .pop-content{
      padding: 0 30px 40px;
      text-align: center;
      font-size: 28px;
      color: #333;
      background-color: #fff;
      .strong{
        font-weight: bold;
      }
    }

    .pop-footer{
      display: flex;
      border-top: 1px solid #E6E6E6;
      & > span{
        font-size: 32px;
        color: #92969c;
        flex-grow: 2;
        height: 72px;
        line-height: 72px;
        text-align: center;
      }
      & > span:not(:last-child){
        border-right: 1px solid #e6e6e6;
      }
      & > .active{
        color: #fd5440;
      }
    }
  }
</style>
