<template>
  <div class="app">
    <app-header />
    <div class="app-content">
      <nuxt/>
      <div class="none">
        <a href="/homers">更多生活家，欢迎实地体验沙发、餐桌、双人床等家具</a><br />
        <a href="/articles">更多家居生活资讯，为您推荐北欧风格、美式风格、现代风格等等各式风格的家具搭配</a><br />
      </div>
    </div>
    <go-top />
    <consult />
    <div class="app-footer">
      <div class="container">
        <!-- <img :src="appFooter" alt=""> -->
        <div class="footer">
          <div class="info">
            <div class="location">
              <i class="wzj-pc-location"></i>
              杭州市余杭区文一西路1218号34幢
            </div>
            <div class="phone">
              <i class="wzj-pc-phone"></i>
              0571-28854557
            </div>
          </div>
          <div class="copyright">Copyright - © 2018 www.wzj.com <a href="/about-us">杭州货客蜂科技有限公司</a> 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备19047275号-10</a></div>
        </div>
      </div>
    </div>
    <login-dialog />
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex'
  import Cookies from 'js-cookie'
  import { SCREEN_WIDTH, PAGE_SCROLL_TOP, APP_CONFIG } from '~/store/mutation-types'
  import goTop from '@/components/Common/GoTop'
  import Consult from '@/components/Common/Consult'
  import AppHeader from '@/components/Common/AppHeader'
  import LoginDialog from '@/components/Common/LoginDialog'
  import appFooter from '@/assets/image/footer.png'
  import { debounce } from '@/assets/lib/tool'

  export default {
    components: {
      goTop,
      AppHeader,
      LoginDialog,
      Consult,
    },
    data() {
      return {
        appFooter,
      }
    },
    computed: {
      ...mapState({
        username: state => state.common.username,
        token: state => state.common.username,
      }),
    },
    async mounted() {
      this.getAppConfig()
      window.addEventListener('scroll', this.handlePageScroll)
      this.screenWidth(document.body.clientWidth)
      // todo... 监听屏幕变化放在需要用的地方
      window.onresize = debounce(() => {
        this.screenWidth(document.body.clientWidth)
      }, 250)
      if (this.token || Cookies.get('token')) {
        this.$store.dispatch('common/GET_USER_DATA')
      }
    },
    methods: {
      ...mapMutations({
        pageScrollTop: `common/${PAGE_SCROLL_TOP}`,
        screenWidth: `common/${SCREEN_WIDTH}`,
        appConfig: `common/${APP_CONFIG}`,
      }),
      getAppConfig() {
        this.$http.get('/proxy/app-config').then((res) => {
          if (res) {
            this.appConfig(res.results[0])
          }
        })
      },
      handlePageScroll() {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        this.pageScrollTop(scrollTop)
      },
    },
  }
</script>

<style lang="scss">
  .app {
    display: flex;
    flex-direction: column;;
    width: auto;
  }
  .app-content {
    min-height: 100vh;
  }
  .container {
    margin: 0 auto;
    min-width: 990px;
    max-width: 1190px;
  }
  .app-footer {
    width: 100%;
    height: 110px;
    text-align: center;
    border-top: 1px solid #E6E6E6;
    background: #F5F5F5;

    img {
      width: 638px;
      margin: 30px auto;
    }
  }
  .footer{
    width: 638px;
    margin: 30px auto;
  }
  .info{
    display: flex;
    margin-bottom: 12px;
    font-size: 14px;
    color: #5A5A5A;
    justify-content: space-between;
    i{
      color: #7D7D7D;
      margin-right: 5px;
    }
    // .location{
    //   margin-right: 20px;
    // }
    .location, .phone{
      display: flex;
      align-items: center;
    }
    .wzj-pc-phone{
      font-size: 22px;
    }
  }
  .copyright{
    color: #7D7D7D;
    font-size: 12px;
    & > a{
      color: #7D7D7D;
      font-size: 12px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>

