<template>
  <nuxt-link class="container product-main-container clearfix"  :to="`/items/${productData.id}`" target="_blank">
    <div class="left-part">
      <img class='image lazyload' :data-src="productData.images[0].url | imageFormat" alt="我在家"/>
    </div>
    <div class="right-part">
      <div class='block'>
        <div class='name-block'>
          <template v-if='tags.length > 0'>
            <span class='tag' v-for='tag in tags' :key='tag.name' :style="{'color': tag.text_color, 'backgroundColor': tag.background_color}">{{tag.name}}</span>
          </template>
          <span class='name'>{{productData.name}}</span>
        </div>
        <div class='price-block'>
          <span class='price'>¥{{prices[0]}}{{prices.length > 0 && prices[prices.length - 1] !== prices[0] ? `-${prices[prices.length - 1]}` : ''}}</span>
          <template v-if="productData.discount_list.length">
            <span v-for="(list, index) in productData.discount_list" :key="index"  class='tag promotion-tag'>{{list.tag}}</span>
          </template>
        </div>
        <star :commentNum='productData.product_review_count' :score='productData.review_score'></star>
      </div>
      <div class='block' v-if='productData.dimensions'>
        <span class='sku-tag' ref="specificationTag" v-for="dimension in productData.dimensions" :key="dimension.id">{{dimension.text}}</span>
        <item-sku-bubble  v-if='isShowBubble' :skuAttrs='leftSkuTag'></item-sku-bubble>
      </div>
      <div class='block' v-if="productData.material">
        <span class='sku-tag' v-for="mat in productData.material" :key="mat.id">{{mat.text}}</span>
      </div>
      <div class='block' v-if="productData.colors">
        <span class='sku-tag' v-for="color in productData.colors" :key="color.id">{{color.text}}</span>
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import Star from './CommentStar'
import ItemSkuBubble from '~/components/Common/ItemSkuBubble'
export default {
  name: 'ProductMain',
  components: {
    Star,
    ItemSkuBubble,
  },
  props: {
    data: Object,
    prices: Array,
  },
  data() {
    return {
      productData: this.data,
      tags: this.data.tags || [],
      isShowBubble: false,
      leftSkuTag: [],
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      this.setDimensionsUI()
    })
  },
  methods: {
    setDimensionsUI() {
      const tagList = this.$refs.specificationTag
      if (tagList && tagList.length) {
        const index = tagList.findIndex(item => { // 找到第一个出现在第三行的位置
          return item.offsetTop > 70
        })
        if (index > 0) {
          this.isShowBubble = true
          const tags = [].concat(this.productData.dimensions)
          const Tags = tags.slice(index)
          this.leftSkuTag = Tags.map(item => item.text)
          this.productData.dimensions.splice(index, this.productData.dimensions.length - index)
        }
      }
    },
  },
}

</script>
<style lang='scss' scoped>

.product-main-container {
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  text-align: center;
  > .left-part {
    width: 166px;
    min-width: 166px;
  }
  .image {
    width: 126px;
    height: 126px;
    border-radius: 4px;
    margin:0 20px;
  }
  .right-part {
    text-align: left;
    margin-top: -3px;
    .block {
      position: relative;
      margin-top: -2px;
      & > div, & > span {
        display: inline-block;
      }
    }
    .name-block {
      margin-bottom: 5px;
      .tag {
        position: relative;
        top: -2px;
      }
    }
    .price-block {
      margin-right: 200px;
    }
    .name {
      width: 300px;
      display: inline-block;
      font-family:PingFangSC-Medium;
      font-size:18px;
      color:#1a1a1a;
      line-height:18px;
    }
    .tag {
      display: inline-block;
      margin-right: 5px;
      padding: 2px 3px;
      color:#ff5541;
      font-family:PingFangSC-Medium;
      font-size:11px;
      line-height: 11px;
      border-radius:10px;
      border:1px solid;
    }
    .price {
      margin-right: 5px;
      font-family:HelveticaNeue-Bold;
      font-size:14px;
      color:#ff5541;
      line-height:18px;
    }
    .sku-tag {
      // display: inline-block;
      padding: 2px 4px;
      margin-right: 4px;
      margin-bottom: 0;
      font-family:PingFangSC-Regular;
      font-size:12px;
      color:#4f5156;
      background:#f6f6f6;
    }
  }
}
</style>