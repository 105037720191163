<template>
  <div class="swiper-wrapper">
    <div class="swiper" ref="swiper">
      <div class="swiper-list clearfix" ref="swipeView" :style="{ width: `${4000 * num}px` }">
        <slot />
      </div>
      <!-- 指示器 -->
      <div v-if="indicator" class="anchor-indicator">
        <span v-for="(item, index) in num" :class="{active: index === value}"></span>
      </div>
    </div>
    <!-- 左右按钮 -->
    <template v-if="arrow && num > 1">
      <div class="arrow arrow-left" :style="{ left: arrowOffset}" @click="handleLeft">
        <i class="wzj-pc-arrow-left"></i>
      </div>
      <div class="arrow arrow-right" :style="{ right: arrowOffset}" @click="handleRight">
        <i class="wzj-pc-arrow-right"></i>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'WzjSwiper',
    props: {
      indicator: {
        type: Boolean,
        default: false,
      },
      arrow: {
        type: Boolean,
        default: false,
      },
      stepWidth: Number, // 滚动的宽度
      value: {
        type: Number,
        default: 0,
      },
      num: { // 轮播图个数
        type: Number,
        default: 0,
      },
      autoplayTimeout: Number,
    },
    data() {
      return {
        offsetX: 0,
        maxContainerWidth: 1190,
        timer: null,
      }
    },
    computed: {
      arrowOffset() {
        return this.stepWidth > this.maxContainerWidth ? `${(this.stepWidth - this.maxContainerWidth) / 2}px` : '2px'
      },
    },
    watch: {
      value(val) {
        this.offsetX = -(val * this.stepWidth)
        this.handleSwiperView()
      },
    },
    mounted() {
      this.offsetX = -(this.value * this.stepWidth)
      this.startAutoScroll()
    },
    methods: {
      handleSwiperView() {
        const swipeViewStyle = this.$refs.swipeView.style
        // swipeViewStyle.transition = 'transform .4s'
        swipeViewStyle.transform = `translate(${this.offsetX}px, 0px) translateZ(0px)`
        // swipeViewStyle.transitionTimingFunction = 'cubic-bezier(0.165, 0.84, 0.44, 1)'
      },
      handleLeft() {
        this.$emit('input', this.value === 0 ? this.num - 1 : this.value - 1)
        this.startAutoScroll()
      },
      handleRight() {
        this.$emit('input', this.value === this.num - 1 ? 0 : this.value + 1)
        this.startAutoScroll()
      },
      startAutoScroll() {
        if (this.autoplayTimeout && this.num > 1) {
          this.$nextTick(() => {
            if (this.timer) {
              this.clear()
            }
            this.autoScroll()
          })
        }
      },
      autoScroll() {
        this.timer = setInterval(() => {
          const swiperView = this.$refs.swipeView
          if (!swiperView) {
            return
          }
          if (this.value === this.num - 1) {
            this.$emit('input', 0)
          } else {
            this.handleRight()
          }
        }, this.autoplayTimeout)
      },
      clear() {
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
      },
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.absoluteCenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.swiper-wrapper {
  position: relative;
}
.swiper {
  position: relative;
  overflow: hidden;

  &-list {
    width: 9000px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    overflow-y: hidden;
  }
}
.arrow {
  width: 30px;
  height: 48px;
  background: rgba(0, 0, 0, .2);
  @extend .absoluteCenter;
  cursor: pointer;

  >i {
    @extend .absoluteCenter;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 700;
    color: #fff;
  }

  &:hover {
    background: rgba(0, 0, 0, .5);
  }

  &-left {
    left: 0;
  }
  &-right {
    right: 2px;
  }
}
.anchor-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;

  span {
    height: 4px;
    width: 12px;
    border-radius: 3px;
    background: #fff;
    opacity: 0.5;
    margin: 0 6px;

    &.active {
      opacity: 1;
    }
  }
}
</style>