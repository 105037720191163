<template>
  <div class="pageContainer">
    <div class="brand-banner" v-if="brandInfo.images">
      <div class="brand-bg" :style="{backgroundImage:`url(${backgroundImg})`}"></div>
      <div class="brand-bg-filter"></div>
      <div class="center-area">
        <div class="logo">
          <img class="lazyload" :data-src="brandInfo.logo | imageFormat"  :alt="`${brandInfo.name} ${brandInfo.description}`">
        </div>
        <h1 class="name">{{ brandInfo.name }}</h1>
        <div class="desc" v-html="brandInfo.detail_info"></div>
        <div class="num">
          {{brandInfo.collects}}人收藏
        </div>
        <add-collect-btn :like="brandInfo.like" @handleCollectClick="handleCollectClick" :isWhite="true"></add-collect-btn>
      </div>
    </div>
    <div class="product-wrapper" ref="productWrapper">
      <div :class="{'fixed-tab': tabFixed}" class="brand-tab">
        <item-tab :itemTabData="brandSpaces"  v-model="activeIndex" />
      </div>
      <ul class="container clearfix">
        <li class="product-list" v-for="product in brandProducts" :key="product.id">
          <product-item :subtitle="true" :product="product" :relatedBrand="brandInfo"/>
        </li>
      </ul>
    </div>
    <div class="none">
      <a v-for="linkInfo in seoLinks" :href="linkInfo.link" :key="linkInfo.link">{{linkInfo.text}}</a><br />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ItemTab from '@/components/Common/ItemTab'
import ProductItem from '@/components/Common/ProductItem'
import AddCollectBtn from '@/components/Common/AddCollectBtn'
import imgLoader from '~/assets/lib/imageFormat'
import { isFurniture, styleCategorySeoLinks } from '~/assets/lib/tool'

const WEB_HEADER = 62
export default {
  name: 'Brand',
  components: {
    ItemTab,
    ProductItem,
    AddCollectBtn,
  },
  head() {
    const { title, keywords, description } = this.getPageInfo()
    return {
      title,
      titleTemplate: '%s 「我在家」一站式高品质新零售家居品牌',
      meta: [
        { hid: 'keywords', name: 'keywords', content: keywords },
        { hid: 'description', name: 'description', content: description },
      ],
    }
  },
  async asyncData({ params, axios, error }) {
    const brandId = params.id
    if (!/^\d+$/.test(brandId)) {
      return error({ statusCode: 404, message: 'Not found' })
    }
    const data = {
      brandInfo: {},
      brandSpaces: [],
      brandProducts: [],
      offset: 0,
      brandId,
      hasMoreData: false,
      tabId: '',
    }
    const res = await axios.get(`/proxy/series/${brandId}/detail`)
    if (res) {
      const [detail = {}] = res.results
      const { tabs = [{}] } = detail
      const { products = [], has_more = false, id = brandId } = tabs[0]
      return Object.assign({}, data, {
        brandInfo: detail,
        brandSpaces: tabs,
        brandProducts: products,
        hasMoreData: has_more,
        tabId: id,
      })
    } else {
      return error({ statusCode: 404, message: 'Not found' })
    }
  },
  data() {
    return {
      id: '',
      activeIndex: 0,
      searchImg: false,
      loadingMore: false,
      tabFixed: false,
    }
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
    }),
    backgroundImg() {
      const { images = [] } = this.brandInfo
      const { url = '' } = images[0] || {}
      return imgLoader(url, 1000)
    },
    seoLinks() {
      if (this.brandProducts.length === 0 || !isFurniture(this.brandProducts[0].name)) {
        return []
      }
      const { style } = this.brandInfo
      return styleCategorySeoLinks(style)
    },
  },
  watch: {
    scrollTop(val) {
      // todo...监听屏幕变化
      const { productWrapper } = this.$refs // fix 解析报错
      if (productWrapper && val > productWrapper.offsetTop - WEB_HEADER) {
        this.tabFixed = true
      } else {
        this.tabFixed = false
      }
      const isEndReached = window.innerHeight + val + 40 >= document.body.offsetHeight
      // 距底部40px 时加载更多
      if (isEndReached && !this.loadingMore && this.hasMoreData) {
        this.fetchData()
      }
    },
    activeIndex(index) {
      this.activeIndex = index
      this.brandProducts = []
      this.tabId = this.brandSpaces[index].id
      this.fetchData()
    },
  },
  methods: {
    async fetchData() {
      if (this.loadingMore) return
      this.loadingMore = true
      const res = await this.$http.get(`/proxy/series/${this.brandId}/products`,
        {
          params: {
            limit: 20,
            offset: this.brandProducts.length,
            tab_id: this.tabId,
          },
        })
      if (res) {
        this.loadingMore = false
        this.hasMoreData = res.has_more
        this.brandProducts = this.brandProducts.concat(res.results)
      }
    },
    async handleCollectClick() {
      const { brandInfo } = this
      await this.checkLogin()
      if (brandInfo.like) {
        const res = await this.$http.post(`/proxy/collection/series/${brandInfo.id}/unlike`)
        if (res) {
          this.$toast('已取消收藏')
          this.brandInfo.like = !brandInfo.like
          this.brandInfo.collects = brandInfo.collects - 1
        }
        return
      }
      const res = await this.$http.post(`/proxy/collection/series/${brandInfo.id}/like`)
      if (res) {
        this.$toast('已收藏成功')
        this.brandInfo.like = !brandInfo.like
        this.brandInfo.collects = brandInfo.collects + 1
      }
    },
    getPageInfo() {
      const { name, series_catalog_name, description: brandDescription = '', detail_info = '' } = this.brandInfo
      const { brandProducts = [] } = this
      const brandDetail = detail_info.replace(/[\r\n]+/g, ' ').trim()
      const productList = brandProducts.map(function(element) {
        return element.name.trim()
      })
      if (brandProducts.length > 0 && isFurniture(brandProducts[0].name)) {
        return {
          title: `${series_catalog_name}风格系列-${name}`,
          keywords: `${name},家具,家居,${series_catalog_name}风格,客厅家具,餐厅家具,书房家具,厨房家具,儿童房家具,我在家`,
          description: `${name} ${series_catalog_name}风格家具/家居，${brandDetail} 该系列有${productList.join('、')}...`,
        }
      }
      return {
        title: `${name} ${brandDescription}`,
        keywords: `${name},家居,${productList.join(',')},我在家`,
        description: `${name} ${brandDetail} 该系列有${productList.join('、')}...`,
      }
    },
  },
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>

.fixed-tab {
  padding-top: 10px;
  position: fixed;
  top: 62px;
  width: 100%;
  z-index: 9;
  background: #fff;
}

.brand-banner{
  position: relative;
  width: 100%;
  padding-top: 360px;
  max-height: 480px;
  overflow: hidden;

  .brand-bg {
    @include absolute-full(1);
    background-size: cover;
    background-position: center center;
    filter: blur(10px);
    transform: scale(1.03)
  }
  .brand-bg-filter {
    @include absolute-full(2);
    background: rgba(0,0,0,.5);
  }
  .center-area{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    width: 400px;
    text-align: center;
    color: #fff;
    .logo{
      img{
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
    .name{
      font-size: 20px;
      margin-bottom: 10px;
      font-weight:600;
    }
    .desc{
      font-size: 14px;
      max-height: 100px;
      overflow: hidden;
      margin-bottom: 30px;
    }
    .num{
      font-size: 14px;
      font-weight: 500;
      .split{
        margin: 0 10px;
      }
    }
    .collect-btn{
      margin: 44px auto 0;
    }
  }
}
.product-wrapper {
  margin-top: 20px;
  padding-bottom: 100px;
}
.brand-tab {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.product-list {
  width: 25%;
  padding: 10px 5px;
  float: left;
  box-sizing: border-box;
}
</style>
