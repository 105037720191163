<template>
  <div class="collect-ctn">
    <div class="tab-ctn">
      <div class="tab-main">
        <div
          v-for="(item, index) in tabTypeList"
          :class="{'tab-item': true, active: index === tabIndex}"
          :key="index"
          @click="handleChangeTab(index)"
        >
          {{item}}
        </div>
      </div>
    </div>
    <div class="list-ctn clearfix" ref="listCtn">
      <template v-if="tabIndex === 0">
        <div class="product-item" v-for="(product, index) in collectList" :key="product.id">
          <product-item :subtitle="true" :product="product" @handleDeleteClick="handleDeleteClick" :index="index"/>
        </div>
      </template>
      <template v-else-if="tabIndex === 1">
        <brand-item
          v-for="(brand, index) in collectList"
          :key="brand.id"
          :item="brand"
          :index="index"
          class="brand-item"
          @handleDeleteClick="handleDeleteClick"
          :isCollect="true"
        />
      </template>
      <template v-else>
        <homer-item
          class="homer-item"
          v-for="(item, index) in collectList"
          :key="item.id"
          :index="index"
          :id="item.id.toString()"
          :cover="item.images[0].url"
          :images="item.images"
          :description="item.description"
          :icon="item.icon || item.icon_url"
          :nickname="item.nickname || item.name"
          :newest="item.is_new === 'true'"
          :distanceTips="`${item.city}·${item.district}${item.estate}`"
          :hitsTips="item.hits_tips"
          :isCollect="true"
          :preview="false"
          @handleDeleteClick="handleDeleteClick"
        />
      </template>

    </div>
    <div class="empty-collect" v-if="collectList && collectList.length === 0 && !loading">
      <img :src="emptyCollect | imageFormat" alt="我在家">
      <div>
        {{`还没有收藏的${tabTypeList[tabIndex]}哦~`}}
      </div>
    </div>
  </div>


</template>

<script>
// todo deletebtn插槽，
import { mapState } from 'vuex'
import ProductItem from '@/components/Common/ProductItem'
import BrandItem from '@/components/Common/BrandItem'
import HomerItem from '@/components/Common/HomerItem'
import emptyCollect from '@/assets/image/empty-collect.png'

export default {
  name: 'Collect',
  components: {
    ProductItem,
    BrandItem,
    HomerItem,
  },
  data() {
    const { tabIndex = 0 } = this.$route.query
    return {
      searchImg: false,
      tabIndex: parseInt(tabIndex),
      tabTypeList: ['商品', '系列', '生活家'],
      limit: 20,
      offset: 0,
      collectList: [],
      hasMore: true,
      loading: true,
      emptyCollect,
    }
  },
  computed: {
    ...mapState({
      scrollTop: state => state.common.pageScrollTop,
    }),
    apiUrl() {
      const apiMap = {
        0: 'goods',
        1: 'series',
        2: 'homers',
      }
      return apiMap[this.tabIndex]
    },
  },
  watch: {
    scrollTop(val) {
      if (val + window.innerHeight + 100 > this.$refs.listCtn.offsetHeight) {
        this.handleloadMore()
      }
    },
    $route() {
      const { tabIndex = 0 } = this.$route.query
      this.handleChangeTab(parseInt(tabIndex))
    },
  },
  mounted() {
    this.getData()
  },

  methods: {
    async getData(loadMore) {
      const { limit, offset, apiUrl } = this
      this.loading = true
      if (this.checkLogin) {
        await this.checkLogin()
      }
      const res = await this.$http.get(`/proxy/collection/${apiUrl}?limit=${limit}&offset=${offset}`)
      if (res) {
        this.loading = false
        this.hasMore = res.has_more
        if (loadMore) {
          this.collectList = this.collectList.concat(res.results)
          return
        }
        this.collectList = res.results
      }
    },
    handleChangeTab(index) {
      this.collectList = []
      this.tabIndex = index
      this.offset = 0
      this.$router.replace({
        query: {
          tabIndex: index,
        },
      })
      this.getData()
    },
    handleloadMore() {
      if (this.loading || !this.hasMore) return
      this.offset += this.limit
      this.getData(true)
    },
    async handleDeleteClick(param) {
      const { apiUrl } = this
      const { id, index } = param
      let res
      // 系列取消收藏接口更换
      if (apiUrl === 'series') {
        res = await this.$http.post(`/proxy/collection/series/${id}/unlike`)
      } else {
        res = await this.$http.delete(`/proxy/collection/${apiUrl}/${id}`)
      }
      if (res) {
        this.$toast('删除成功')
        this.collectList.splice(index, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.tab-ctn{
  height: 60px;
  font-size: 16px;
  color: $c_black;
  border-bottom: 1px solid $c_gray;
  .tab-main{
    width: 272px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
}
.tab-item{
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  cursor: pointer;
  &:hover{
    color: $c_highlight;
  }
  &.active{
    color: $c_highlight;
    &::after{
      content: '';
      width: 100%;
      height: 3px;
      background: $c_highlight;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}
.list-ctn{
  margin: 20px auto;
  min-width: 990px;
  max-width: 1190px;
  .homer-item {
    width: calc(33.33% - 14px);
    margin-bottom: 20px;
    margin-right: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.product-item{
  width: calc(25% - 8px);
  margin-bottom: 20px;
  margin-right: 10px;
  float: left;
  box-sizing: border-box;
  position: relative;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.brand-item{
  margin-bottom: 20px;
}
.empty-collect{
  margin: 150px auto;
  color: $_text_grey;
  font-size: 16px;
  text-align: center;
  img{
    width: 155px;
    height: 100px;
    margin-bottom: 20px;
  }
}

</style>


