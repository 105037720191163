<template>
  <div>
    
  </div>
</template>

<script>
export default {
  mounted() {
    const path = this.$route.query.path
    const loadDateTime = new Date()
    setTimeout(() => {
      const timeOutDateTime = new Date()
      if (timeOutDateTime - loadDateTime < 1000) {
        window.location.href = 'https://m.wzj.com/app'
      }
    }, 40)
    window.location.href = path
  },
}
</script>
