<template>
  <div class="ctn">
    <div class="header">
      <div class="logo-ctn">
        <img :src="'//img.wzj.com/upload/web/images/logo_black.png' | imageFormat"/>
      </div>
    </div>
    <div class="main">
      <div class="hint">
        <div class="error-img">
          <img :src="`${prefix}error-img.png` | imageFormat" />
        </div>
        <div class="error-text">
          您当前的
          <div class="big bold">
            浏览器版本过低！
          </div>
          存在安全风险，<i class="bold">建议升级浏览器！</i>
        </div>
      </div>
      <div class="pc">
        <div class="square pc-square">
          <img :src="`${prefix}pc.png` | imageFormat" class="img"><br/>
          浏览器升级推荐
        </div>
        <a class="square browser-square" v-for="item in browserList" :key="item.img" :href="item.href" target="_blank">
          <img :src="`${prefix + item.img}.png` | imageFormat" class="img" /><br/>
          <div class="text-square" :style="{background: item.bg}">
            {{item.name}}
            <img :src="`${prefix}arrow.png` | imageFormat" class="arrow-img">
          </div>
        </a>
      </div>
      <div class="mobile">
        <div class="square mobile-square">
          <img :src="`${prefix}mobile.png` | imageFormat" class="img mobile-img"><br/>
          手机扫码浏览
        </div>
        <div class="square large-square">
          <img :src="`${prefix}app-download.png` | imageFormat" class="img"><br/>
          <div class="text-square mobile-shop">
            app下载
            <img :src="`${prefix}arrow.png` | imageFormat" class="arrow-img">
          </div>
        </div>
        <div class="square large-square">
          <img :src="`${prefix}miniprogram.png` | imageFormat" class="img"><br/>
          <div class="text-square mobile-shop">
            商城小程序
            <img :src="`${prefix}arrow.png` | imageFormat" class="arrow-img">
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import { getIEVersion } from '~/assets/lib/tool'

export default {
  name: 'UpgradeHint',
  layout: 'empty',
  data() {
    return {
      showUrl: false,
      prefix: '//img.wzj.com/upload/pc_upgrade/',
      browserList: [
        { name: '谷歌浏览器', img: 'chrome', bg: '#81C32E', href: 'https://www.google.cn/intl/zh-CN/chrome' },
        { name: '火狐浏览器', img: 'firefox', bg: '#FE8B2A', href: 'http://www.firefox.com.cn' },
        { name: 'IE浏览器', img: 'ie', bg: '#007EF6', href: 'https://support.microsoft.com/zh-cn/help/17621/internet-explorer-downloads' },
      ],
      mUrl: '',
    }
  },
  mounted() {
    const version = getIEVersion(navigator.userAgent)
    if (!version || version > 10) {
      this.$router.push('/')
    }
  },
}
</script>

<style lang="scss" scoped>
.ctn{
  width: 100%;
  background: #fff;
  font-size: 14px;
}
.header{
  width: 100%;
  padding-left: 19%;
  background: #fff;
  height: 60px;
  box-shadow:0px 2px 4px 0px rgba(26,26,26,0.1);
  .logo-ctn{
    margin-top: 10px;
    width: 122px;
    height: 40px;
    display: inline-block;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.default-logo{
  height: 100%;
  background: rgb(174, 79, 61);
  line-height: 40px;
  text-align: center;
  padding: 0 10px;
  border-radius: 10px;
  letter-spacing: 2px;
  font-size: 16px;
  &.small{
    font-size: 12px;
    width: 162px;
  }
}
.main{
  width: 800px;
  margin: 60px auto;
}
.hint{
  margin-bottom: 80px;
}
.error-img{
  display: inline-block;
  width: 400px;
  height: 245px;
  margin-right: 60px;
  img{
    width: 100%;
    height: 100%;
  }
}
.error-text{
  display: inline-block;
  color: #222121;
  vertical-align: 76px;
  .big{
    font-size: 36px;
    margin: 10px 0;
  }
  .bold{
    color: #BE4C4B
  }
}
.pc, .mobile{
  overflow: hidden;
  margin-bottom: 10px;
}
.square{
  text-align: center;
  float: left;
  width: 190px;
  height: 200px;
  background: #F6F6F6;
  color: #fff;
  margin-right: 10px;
  border-radius: 10px;
  font-size: 14px;
  .img{
    width: 100px;
    height: 100px;
    margin: 30px 0 26px;
  }
  .mobile-img{
    width: 71px;
  }
}
.pc-square{
  background: #EA8C40;
}
.mobile-square{
  background: #EA7B40;
}
.text-square{
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 23px;
  padding: 0 20px 0 10px;
  .arrow-img{
    position: absolute;
    right: 10px;
    top: 7px;
    z-index: 1;
    width: 6px;
    height: 10px;
  }
}
.browser-square{
  cursor: pointer;
}
.mobile-shop{
  background: #BE4C4B;
}
.large-square{
  width: 290px;
}
</style>



