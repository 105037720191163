<template>
  <div
    :class="btnClass"
    @click="handleCollectClick"
  >
    <template v-if="like">
      <i class="wzj-pc-collect"></i>已收藏
    </template>
    <template v-else>
      <i class="wzj-pc-not-collect"></i>收藏
    </template>
  </div>
</template>
<script>
export default {
  name: 'AddCollectBtn',
  props: {
    like: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      const { horizontal, like, isWhite } = this
      return ['collect-btn', horizontal ? 'collect-horizontal' : 'collect-column', like ? 'collect-like' : '', isWhite ? 'collect-white' : '']
    },
  },
  methods: {
    handleCollectClick() {
      this.$emit('handleCollectClick')
    },
  },
}
</script>
<style lang="scss" scoped>
.collect-btn{
  cursor: pointer;
  border: 1px solid $c_gray;
  color: $c_dark;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  &.collect-horizontal{
    width: 100px;
    line-height: 32px;
    
    i{
      font-size: 15px;
      margin-right: 6px;
    }
    &:hover{
      color: $c_highlight;
      border-color: $c_highlight;
    }
  }
  &.collect-column{
    width: 72px;
    flex-direction: column;
    &:hover{
      color: $c_highlight;
    }
  }
  &.collect-white{
    color: #fff;
    border: 1px solid #fff;
  }
  &.collect-like{
    color: $c_highlight;
    border-color: $c_highlight;
  }
}
</style>


