import { render, staticRenderFns } from "./HomerItem.vue?vue&type=template&id=27744097&scoped=true&"
import script from "./HomerItem.vue?vue&type=script&lang=js&"
export * from "./HomerItem.vue?vue&type=script&lang=js&"
import style0 from "./HomerItem.vue?vue&type=style&index=0&id=27744097&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27744097",
  null
  
)

export default component.exports