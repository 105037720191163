<template>
  <nuxt-link :to="`/items/${product.id}`" target="_blank" class="homer-product">
    <div class="img-box">
      <img class="cover lazyload" :data-src="product.image_url | imageFormat(400)" :alt="productImageAlt">
    </div>
    <h4 class="title">{{product.title}}</h4>
    <div class="desc">
      {{product.dimensions}}；{{product.material}}；{{product.color}}
      <span></span>
    </div>
    <div class="num">x{{product.num}}</div>
    <div v-if="!product.status" class="status">已下架</div>
  </nuxt-link>
</template>

<script>
  import { trimCityTail, isFurniture } from '@/assets/lib/tool'

  export default {
    props: {
      product: Object,
      city: {
        type: String,
        default: '',
      },
    },
    computed: {
      productImageAlt() {
        const { style, category, title, material } = this.product
        if (isFurniture(title)) {
          return `${style}风格、${material}材质的${category} ${title} `
        }
        return `${material}材质的${category} ${title}`
      },
    },
    methods: {
      trimCityTail,
    },
  }
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.homer-product {
  position: relative;
  width: 100%;
  cursor: pointer;
  display: block;
}
.img-box {
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  font-size: 0;
}
.cover {
  width: 100%;
}
.title {
  margin-top: 8px;
  margin-bottom: 10px;
  font-size: 16px;
  @include text-ellipsis();
}
.desc {
  height: 34px;
  margin-bottom: 10px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  font-size: 12px;
  color: $_disable;
}
.num {
  font-size: 12px;
  color: $_disable;
}
.status {
  position: absolute;
  bottom: -8px;
  right: 0;
  z-index: 1;
  background: #92969C;
  padding: 2px 8px;
  border-radius: 18px;
  color: #fff;
  font-size: 20px;
  transform: scale(0.5);
}
</style>