<template>
  <div class='com-bubble-more'>
    <span class='ellipsis' @click.stop="change(true)"   @mouseout="change(false)">...</span>
    <div class='tip-bubble' v-if='showTip'>
      点击查看更多
    </div>
    <div class='bubble' v-if='isShowBubble' ref='bubble' :style="{left: leftWidth, top: topWidth}">
      <span v-for='sku in skuAttrs' :key='sku'>{{sku}}</span>
    </div>
  </div>
</template>

<script>
import { setLocalStorage } from '@/assets/lib/tool'

export default {
  name: '',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    skuAttrs: Array,
  },
  data() {
    return {
      isShowBubble: false,
      leftWidth: '',
      topWidth: '',
      showTip: window.localStorage.getItem('showBubbleTip') === null,
    }
  },
  watch: {
    $route() {
      if (this.$route.query.showTip) {
        this.showTip = false
      }
    },
    isShowBubble(val) {
      if (val) {
        this.$nextTick(() => {
          this.leftWidth = `-${this.$refs.bubble.clientWidth / 2 - 10}px`
          this.topWidth = `-${this.$refs.bubble.clientHeight}px`
        })
      }
    },
  },
  computed: {},
  mounted() {
    // this.leftWidth = `-${this.$refs.bubble.clientWidth / 2 - 10}px`
    // this.topWidth = `-${this.$refs.bubble.clientHeight}px`
  },
  methods: {
    change(val) {
      this.isShowBubble = val
      this.showTip = false
      setLocalStorage('showBubbleTip', false)
      this.$router.replace({
        ...this.$route,
        query: {
          ...this.$route.query,
          showTip: true,
        },
      })
    },
  },
}

</script>
<style lang='scss' scoped>
.com-bubble-more {
  position: relative;
  display: inline-block;
  height: 20px;
  .ellipsis {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #4f5156;
    background: #f6f6f6;
    text-align: center;
    cursor: pointer;
  }
  .bubble, .tip-bubble {
    position:absolute;
    top: -55px;
    padding: 10px;
    font-family:PingFangSC-Regular;
    font-size:12px;
    white-space: nowrap;
    color:#4f5156;
    line-height:12px;
    background:#ffffff;
    border:1px solid #e6e6e6;
    box-shadow:0 4px 10px 0 rgba(26,26,26,0.10);
    > span {
      display: block;
      line-height: 16px;
    }
    &:after {
      position: absolute;
      bottom: -5px;
      left: 47%;
      width: 0;
      height: 0;
      content: '';
      border-top: 5px solid #fff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
  .tip-bubble {
    top: -30px;
    left: -40px;
    background: #ff5541;
    color: #fff;
    &:after {
      border-top: 5px solid #ff5541;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}
</style>