<template>
  <div class="photo-swiper-wrapper" v-if="show">
    <div class="upper-img-wrapper clearfix" :style="{height: `${spaceHeight + imgHeight}px`}">
      <div class="model-left">
        <i  @click="handleLeft" class="icon wzj-pc-arrow-left" :style="{bottom: `${imgHeight/2}px`}"></i>
      </div>
      <div class="big-img-box">
        <div class="count">{{currentIndex + 1}}/{{items.length}}</div>
        <div class="big-img-item" :style="{height: `${imgHeight}px`,width: `${ImgWidth}px`,backgroundImage:`url(${currentBigImg})`}"></div>
      </div>
      <div class="model-right">
        <i @click="handleRight" class="icon wzj-pc-arrow-right" :style="{bottom: `${imgHeight/2}px`}"></i>
      </div>
    </div>
    <div class="under-img-wrapper">
      <div class="small-img-box">
        <ul class="small-img-lists" :style="{width: `${smallImgBox}px`}" ref="smallImgBox">
          <li v-for="(item, index) in items" :key="index" @click="handleCurrentSmallImg(index)" :class="{'small-img-active': currentIndex === index}">
            <img class="lazyload" :data-src="item.url | imageFormat(200)" alt="我在家">
          </li>
        </ul>
      </div>
    </div>
    <div class="close" @click="handleClose">
      <i class="icon wzj-pc-close-model"></i>
    </div>
  </div>
</template>

<script>
  import imgLoader from '@/assets/lib/imageFormat'

  export default {
    name: 'PhotoSwiper',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      index: {
        type: Number,
        default: 0,
      },
      items: Array,
    },
    data() {
      return {
        show: false,
        height: 0,
        ImgWidth: 1000,
        imgHeight: 600,
        currentIndex: this.index || 0,
        smallImgBox: 9000,
        len: 0,
        smallImgBoxDom: null,
      }
    },
    watch: {
      value(val) {
        this.show = val
        if (val) {
          this.handleCurrentSmallImg(this.index)
          this.len = this.items.length
          this.smallImgBox = this.len * 70
          // 小图片的数量大于10个
          if (this.len > 10) {
            this.smallImgBoxInit()
          }
        }
      },
      currentIndex(val) {
        if (!this.smallImgBoxDom) {
          this.smallImgBoxInit()
        } else {
          const smallImgBox = this.smallImgBoxDom.style
          if (val > 5 && (this.len - val > 4)) {
            smallImgBox.transform = `translate(-${(val - 5) * 70}px, 0px) translateZ(0px)`
            smallImgBox.transitionDuration = '0ms'
            smallImgBox.transitionTimingFunction = 'cubic-bezier(0.165, 0.84, 0.44, 1)'
          } else if (val === 0) {
            smallImgBox.transform = `translate(0px, 0px) translateZ(0px)`
            smallImgBox.transitionDuration = '0ms'
            smallImgBox.transitionTimingFunction = 'cubic-bezier(0.165, 0.84, 0.44, 1)'
          }
        }
      },
    },
    computed: {
      currentBigImg() {
        return imgLoader(this.items[this.currentIndex].url)
      },
    },
    mounted() {
      this.height = window.innerHeight
      this.imgHeight = this.height * 0.6
      this.spaceHeight = (this.height - this.imgHeight) / 2
    },
    methods: {
      handleLeft() {
        this.currentIndex = this.currentIndex === 0 ? this.currentIndex = this.len - 1 : this.currentIndex - 1
      },
      handleRight() {
        this.currentIndex = this.currentIndex === this.len - 1 ? 0 : this.currentIndex + 1
      },
      smallImgBoxInit() {
        this.$nextTick(() => {
          this.smallImgBoxDom = this.$refs.smallImgBox
        })
      },
      handleCurrentSmallImg(index) {
        this.currentIndex = index
      },
      handleClose() {
        this.value = false
      },
    },
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  .photo-swiper-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, .9);
  }
  .upper-img-wrapper {
    position: relative;
  }
  .model-left,  .model-right {
    position: absolute;
    width: 40%;
    height: 100%;
    z-index: 10;
    background: transparent;

    .icon {
      position: absolute;
      color: #fff;
      font-size: 30px;
      display: none;
      cursor: pointer;
    }

    &:hover {
      .icon {
        display: block;
      }
    }
  }
  .model-left {
    left: 0;

    .icon {
      left: 40px;
    }
  }
  .model-right {
    right: 0;

    .icon {
      right: 40px;
    }
  }
  .big-img-box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .count {
    margin-bottom: 20px;
    font-size: 14px;
    color: #fff;
    text-align: center;
  }
  .big-img-item {
    width: 1000px;
    height: 600px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .under-img-wrapper {
    margin-top: 50px;
  }
  .small-img-box {
    position: relative;
    width: 690px;
    height: 60px;
    margin: 0 auto;
    overflow: hidden;
  }
  .small-img-lists {
    width: 9000px;
    position: absolute;
    white-space:nowrap;

    >li {
      box-sizing: border-box;
      display: inline-block;
      height: 60px;
      width: 60px;
      margin-right: 10px;
      border: 1px solid transparent;
      cursor: pointer;

      >img {
        width: 100%;
        height: 100%;
      }
    }
    .small-img-active {
      border: 1px solid #ff5541;
    }
  }
  .close {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    z-index: 19;

    >.icon {
      color: #fff;
      font-size: 16px;
    }
  }
</style>